<template>
  <div class="sidebar-wrapper">
    <a
      href="#contactPersonSidebar"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right contact-persons"
    >
      {{ $t('navigation.persons') }}
    </a>
    <BaseSidebar
      id="contactPersonSidebar"
      backdrop-disabled
    >
      <ContactPersonCard
        v-if="!_.isEmpty(props.record)"
        :initial-record="props.record"
        :company-id="props.record.id"
        @save="handleEmitSave"
        @before-mount="handleEmitBeforeMount"
      />
    </BaseSidebar>
  </div>
</template>

<script setup>
import ContactPersonCard from './ContactPersonCard.vue';
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';
import _ from 'lodash';

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save', 'showConfirmModal', 'beforeMount']);

function handleEmitSave(data) {
  emit('save', data);
}

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
}
</script>
