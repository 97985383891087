import api from '@/services/ApiClient';
import BaseStore from '@/store/BaseStore';

let store = new BaseStore(['certainty_rule'], ['certainty_rule']);

const ContractCertaintyActsStore = {
  namespaced: store.namespaced,
  state: { ...store.state },
  mutations: { ...store.mutations },
  actions: {
    ...store.actions,
    async patchCertaintyAct(context, data) {
      if (!data) {
        return;
      }

      const payload = {
        action: data.action,
        data: data.payload,
      };

      await api.request(
        'patch',
        `/api/ifapi?p=/clients/${data.client_id}/contracts/${data.contract_id}/certaintyacts/${data.certainty_act_id}`,
        null,
        payload
      );
    },
  },
};

export { ContractCertaintyActsStore };
