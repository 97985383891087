<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <OfferRequiredDocumentsList
    v-else-if="record?.data"
    :initial-record="record"
    @save="updateLogbookAndPreview"
  />
</template>

<script setup>
import OfferRequiredDocumentsList from '@/lists/sales/OfferRequiredDocumentsList.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import {
  useGetCompany,
  useGetOfferPreview,
  useSetLogbookItems,
  useSetOfferPreview,
} from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const companyRecord = ref(null);
const loading = ref(false);

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'offer') {
      getAndSetOfferPreview();
    }
    if (to.meta.preview !== 'offer') {
      useSetOfferPreview('');
    }
  }
);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/required-documents/available`
    );

    companyRecord.value = useGetCompany();
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_required_documents') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching offer required documents: ', error);
  } finally {
    loading.value = false;
  }

  if (!useGetOfferPreview()) {
    await getAndSetOfferPreview();
  }
});

async function getAndSetOfferPreview() {
  try {
    const offerPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/preview`
    );
    useSetOfferPreview(offerPreviewRecord);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_offer_preview') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching offer preview: ', error);
  }
}

function updateLogbookAndPreview(object) {
  useSetLogbookItems(object.customerLogs);
  if (object?.offerPreview) {
    useSetOfferPreview(object.offerPreview);
  }
}
</script>
