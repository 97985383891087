import api from '@/services/ApiClient';

export default class {
  constructor() {
    this.namespaced = true;
    this.state = {
      data: {},
    };
    (this.mutations = {
      SET_DATA(state, payload) {
        state.data = payload;
      },
    }),
      (this.actions = {
        async fetch({ commit }, enums) {
          const data = await api.request('get', '/ifapi/enums', {
            enums: enums,
          });
          commit('SET_DATA', data);
        },
        reset({ commit }) {
          commit('SET_DATA', {});
        },
      });
  }
}
