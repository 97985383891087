import BaseStore from '@/store/BaseStore';
import api from '@/services/ApiClient';

const store = new BaseStore(
  ['company', 'account_employee', 'debtor_employee'],
  ['company.address', 'company.contact_detail', 'account_employee', 'debtor_employee', 'advance_payment_bank_account']
);

const ClientsStore = {
  namespaced: store.namespaced,
  state: { ...store.state },
  mutations: { ...store.mutations },
  actions: {
    ...store.actions,
    async setEmployees(context, client) {
      const payload = {
        action: 'set_employees',
        data: {
          account_employee_id: client.account_employee_id,
          debtor_employee_id: client.debtor_employee_id,
        },
      };
      await api.request('patch', '/api/ifapi?p=/clients/' + client.id, null, payload);
    },
  },
};

export { ClientsStore };
