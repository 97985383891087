import BaseStore from '@/store/BaseStore';
import router from '@/router';
import api from '@/services/ApiClient';

const store = new BaseStore(['address', 'competence_persons'], []);

const CompaniesStore = {
  namespaced: store.namespaced,
  state: {
    ...store.state,
    directors: [],
    shareholders: {},
    newCompany: {},
  },
  mutations: {
    ...store.mutations,
    SET_DIRECTORS(state, payload) {
      state.directors = payload;
    },
    SET_SHAREHOLDERS(state, payload) {
      state.shareholders = payload;
    },
    SET_NEW_COMPANY(state, payload) {
      state.newCompany = payload;
    },
  },
  actions: {
    ...store.actions,
    async fetchDirectingCompanies({ commit }, params = {}) {
      const url = `/ifapi/clients/${router.currentRoute.value.params.client_id}/companies`;
      params.include = ['address', 'competence_persons'];
      const data = await api.request('get', url, params);
      commit('SET_LIST', data);
    },
    async fetchDirectors({ commit }, url) {
      const data = await api.request('get', url, {
        include: ['competence_persons', 'directors'],
      });
      commit('SET_DIRECTORS', data);
    },
    async fetchShareholders({ commit }, url) {
      const data = await api.request('get', url, { include: ['address'] });
      commit('SET_SHAREHOLDERS', data);
    },
    async fetchNewCompany({ commit }) {
      const data = await api.request('get', '/ifapi/companies/new', {
        include: ['address', 'competence_persons'],
      });
      commit('SET_NEW_COMPANY', data);
    },
    async resetDirectors({ commit }, data) {
      commit('SET_DIRECTORS', data);
    },
    async resetShareholders({ commit }, data) {
      commit('SET_SHAREHOLDERS', data);
    },
  },
  getters: {
    ...store.getters,
    getDirectingCompanies(state) {
      if (state.list.data === undefined) {
        return {};
      }

      let directingCompanies = {};
      state.list.data.forEach((company) => {
        directingCompanies[company.id] = { ...company };
      });

      return directingCompanies;
    },
  },
};

export { CompaniesStore };
