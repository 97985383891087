import { number } from '@/modules/validators';

/**
 * Format list with JS native Intl.ListFormat
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat
 * @param {array} list array to format
 * @param {string|array} locale string or array with locale(s)
 * @param {{}} options object with optional options style (long|short|narrow), type (conjunction|disjunction|unit) and/or localeMatcher (best fit|lookup)
 * @returns a language-specific formatted string representing the elements of the list
 */
export function formatList(list, options = {}, locale = 'nl-NL') {
  return new Intl.ListFormat(locale, options).format(list);
}

export function formatNumber(value, options) {
  value = value.replaceAll('.', '').replace(',', '.');

  if (number(value).$valid) {
    return new Intl.NumberFormat('nl-NL', options).format(value);
  }
}

export function bytesToReadableFormat() {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
    compactDisplay: 'short',
  });
}

export function toLocaleDateString(options) {
  options['timeZone'] = 'Europe/Amsterdam';
  return Intl.DateTimeFormat('nl-NL', options);
}
