import BaseStore from '@/store/BaseStore';

let store = new BaseStore();

const InsurersStore = {
  namespaced: store.namespaced,
  state: { ...store.state },
  mutations: { ...store.mutations },
  actions: { ...store.actions },
};

export { InsurersStore };
