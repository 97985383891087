/**
 ** @deprecated This list store is deprecated in favor of indexed search engine like MeiliSearch
 * which will return (filtered, paginated, etc) list data
 */
import apiurls from '@/modules/apiurls';
import apiClient from '@/services/ApiClient';
import { useApplicationStore } from '@/stores/application';
import { validateFields } from '@/modules/storeValidator';
import { onBeforeUnmount, ref } from 'vue';
import { defineStore } from 'pinia';

export const useEntitieslistStore = defineStore('entitieslist', () => {
  const lists = ref({});
  const configs = ref({});
  const errors = ref({});
  let originals = {};

  onBeforeUnmount(() => {
    errors.value = {};
  });

  /**
   * Get an entity from the store. If not present then fetch it first from the server
   * @param name
   * @param includes
   * @returns {Promise<*>}
   */
  async function list(config) {
    try {
      config.url = apiurls.getUrl(config.name, 'index');
    } catch (error) {
      useApplicationStore().warning(error);
    }

    this.$patch({
      ['configs']: {
        [config.name]: config,
      },
    });
    this.$patch({
      ['errors']: {
        [config.name]: {},
      },
    });

    const entities = await this.fetchEntitiesFromServer(config.name);

    if (entities && config.name) {
      const parsedData = JSON.parse(JSON.stringify(entities));
      this.$patch((state) => {
        state.lists[config.name] = parsedData;
      });
      originals[config.name] = JSON.parse(JSON.stringify(parsedData));
    }
  }

  function setListsEntity(data, entityName) {
    if (data && entityName) {
      const parsedData = JSON.parse(JSON.stringify(data));
      this.$patch({
        lists: {
          [entityName]: parsedData,
        },
      });
      originals[entityName] = JSON.parse(JSON.stringify(parsedData));
    }
  }
  function setErrorEntity(data, entityName) {
    if (data && entityName) {
      const parsedData = JSON.parse(JSON.stringify(data));
      this.$patch({
        errors: {
          [entityName]: parsedData,
        },
      });
    }
  }
  function setConfigEntity(data, entityName) {
    if (data && entityName) {
      const parsedData = JSON.parse(JSON.stringify(data));
      this.$patch({
        configs: {
          [entityName]: parsedData,
        },
      });
    }
  }

  async function fetchEntitiesFromServer(entityName) {
    return await apiClient.request('get', this.configs[entityName].url, {
      include: this.configs[entityName].include,
    });
  }

  /**
   * Remove an entity from the list, also from the backend
   * @param config
   * @param id
   * @returns {Promise<void>}
   */
  async function remove(config, id, index) {
    if (id !== null) {
      const apiUrl = apiurls.getUrl(config.name, 'entity', id);
      await apiClient.request('delete', apiUrl);
    }

    lists.value[config.name].data.splice(index, 1);
  }

  /**
   * Add new entity on top of the list. Also fetch an empty entity fro backend.
   * @param config
   * @returns {Promise<void>}
   */
  async function add(config) {
    const apiUrl = apiurls.getUrl(config.name, 'new');
    const entity = await apiClient.request('get', apiUrl, {
      include: this.configs[config.name].include,
    });

    lists.value[config.name].data.splice(0, 0, entity);
  }

  /**
   * Commit the entity to the backend
   * @param config
   * @param index
   * @param apiUrl
   * @returns {Promise<boolean>}
   */
  async function commit(config, index, apiUrl = null) {
    try {
      const entity = lists.value[config.name].data[index];
      const method = entity.id === null ? 'post' : 'put';
      if (!apiUrl) {
        apiUrl =
          entity.id === null ? apiurls.getUrl(config.name, 'index') : apiurls.getUrl(config.name, 'entity', entity.id);
      }
      const savedEntity = await apiClient.request(method, apiUrl, { include: config.include }, entity);
      if (savedEntity) {
        lists.value[config.name].data[index] = savedEntity;
        originals[config.name][index] = savedEntity;
      }

      return true;
    } catch (response) {
      this.$patch((state) => {
        state.errors[config.name][index] = response.errors;
      });

      return false;
    }
  }

  /**
   * Cancel changes and set state back to the original
   */
  function cancel(config, index) {
    this.$patch((state) => {
      state.errors[config.name][index] = {};
    });
    const original = originals[config.name][index];
    const copy = JSON.parse(JSON.stringify(original));

    this.$patch((state) => {
      state.lists[config.name]['data'][index] = copy;
    });
  }

  /**
   * Validate the state
   * @param config
   * @param rules
   * @param index
   * @returns {Promise<boolean|*>}
   */
  async function validate(config, rules, index) {
    const state = lists.value[config.name].data[index];
    const validation = await validateFields(rules, state);

    this.$patch((state) => {
      state.errors[config.name][index] = validation.inputErrors;
    });

    return validation.valid;
  }

  /**
   * @param config
   * @param rules
   * @param index
   * @returns {Promise<void>}
   */
  async function validateAndSetListData(config, rules, index) {
    const state = lists.value[config.name].data[index];
    const validation = await validateFields(rules, state);

    const data = lists.value[config.name].data[index];
    const copy = JSON.parse(JSON.stringify(data));

    originals[config.name][index] = copy;

    if (validation.inputErrors) {
      this.$patch((state) => {
        state.errors[config.name][index] = validation.inputErrors;
      });
    }

    return validation.valid;
  }

  return {
    lists,
    configs,
    list,
    setListsEntity,
    setErrorEntity,
    setConfigEntity,
    fetchEntitiesFromServer,
    remove,
    add,
    commit,
    validate,
    cancel,
    errors,
    validateAndSetListData,
  };
});
