<template>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="sm"
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    <template v-if="useGetProspectCreditProposalStatus() !== APPROVED">
      {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
    </template>
    <template v-else>
      {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_accepted_without_signed_contract') }}
    </template>
  </BaseModal>
  <AmountInput
    v-model="record.max_financing_limit_amount"
    :label="$t('offer.maximum_purchase_price')"
    :inner-label="record.sales_status.currency"
    :errors="errorMessages['max_financing_limit_amount']"
    @enter="handleSave"
  />
  <PercentageInput
    v-model="record.financing_rate_percentage"
    :label="$t('offer.maximum_advance')"
    :errors="errorMessages['financing_rate_percentage']"
    :decimals="2"
    @enter="handleSave"
  />
  <PercentageInput
    v-model="record.factor_commission_percentage"
    :label="$t('offer.factoring_fee')"
    :errors="errorMessages['factor_commission_percentage']"
    :decimals="4"
    @enter="handleSave"
  />
  <LabelCheckbox
    v-model="addFactoringFeeAbroadIsInOfferPreview"
    :label-content="$t('offer.add_factoring_fee_abroad')"
    inline-row
  />
  <PercentageInput
    v-if="addFactoringFeeAbroadIsInOfferPreview"
    v-model="record.factoring_fee_abroad_percentage"
    :label="$t('offer.factoring_fee_abroad')"
    :errors="errorMessages['factoring_fee_abroad_percentage']"
    :decimals="4"
    @enter="handleSave"
  />
  <PercentageInput
    v-model="record.raise_advance_interest_rate_percentage"
    :label="$t('offer.raise_advance_interest_rate')"
    :errors="errorMessages['raise_advance_interest_rate_percentage']"
    :decimals="3"
    wrapper-class="mt-4"
    @enter="handleSave"
  />
  <LabelSelect
    v-model="record.euribor_interest_term_months"
    :options="euriborInterestTermMonths"
    field-name="label"
    :label-content="$t('offer.euribor_interest_term')"
    :errors="errorMessages['euribor_interest_term_months']"
  />
  <PercentageInput
    v-model="record.credit_provision_percentage"
    :label="$t('offer.credit_provision')"
    :errors="errorMessages['credit_provision_percentage']"
    :decimals="5"
    wrapper-class="impact-default-group-margin"
    @enter="handleSave"
  />
  <PercentageInput
    v-model="record.credit_insurance_percentage"
    :label="$t('offer.credit_insurance')"
    :errors="errorMessages['credit_insurance_percentage']"
    :decimals="3"
    @enter="handleSave"
  />
  <AmountInput
    v-model="record.setup_fee_amount"
    :label="$t('offer.setup_fee')"
    :inner-label="record.sales_status.currency"
    :errors="errorMessages['setup_fee_amount']"
    @enter="handleSave"
  />
  <AmountInput
    v-model="record.own_risk_amount"
    :label="$t('offer.own_risk_credit_insurance')"
    :inner-label="record.sales_status.currency"
    :errors="errorMessages['own_risk_amount']"
    @enter="handleSave"
  />
  <AmountInput
    v-model="record.minimum_factor_commission_amount"
    :label="$t('offer.minimum_factoring_fee_per_contract_year')"
    :inner-label="record.sales_status.currency"
    :errors="errorMessages['minimum_factor_commission_amount']"
    wrapper-class="impact-default-group-margin"
    @enter="handleSave"
  />
  <LabelSelect
    v-model="record.duration_months"
    :options="durationMonths"
    field-name="label"
    :label-content="$t('offer.duration')"
    :errors="errorMessages['duration_months']"
  />
  <LabelSelect
    v-model="record.notice_period_months"
    :options="noticePeriodMonths"
    field-name="label"
    :label-content="$t('offer.notice_period')"
    :errors="errorMessages['notice_period_months']"
  />
  <PercentageInput
    v-model="record.scoring_chance_percentage"
    :label="$t('offer.scoring_change')"
    :errors="errorMessages['scoring_chance_percentage']"
    wrapper-class="impact-default-group-margin"
    @enter="handleSave"
  />
  <LabelCheckbox
    v-model="transactionFinancingIsInOfferPreview"
    :label-content="$t('offer.transaction_financing')"
    inline-row
    class="mb-3"
  />
  <div v-show="transactionFinancingIsInOfferPreview">
    <PercentageInput
      v-model="record.transaction_financing.maximum_annual_revenue_percentage"
      :label="$t('offer.maximum_percentage_annual_revenue')"
      :errors="errorMessages['transaction_financing.maximum_annual_revenue_percentage']"
      :decimals="2"
      @enter="handleSave"
    />
    <PercentageInput
      v-model="record.transaction_financing.maximum_purchase_orders_revenue_percentage"
      :label="$t('offer.maximum_purchase_orders_revenue')"
      :errors="errorMessages['transaction_financing.maximum_purchase_orders_revenue_percentage']"
      @enter="handleSave"
    />
    <AmountInput
      v-model="record.transaction_financing.transaction_limit_amount"
      :label="$t('offer.maximum_financing_limit')"
      :inner-label="record.sales_status.currency"
      :errors="errorMessages['transaction_financing.transaction_limit_amount']"
      @enter="handleSave"
    />
    <AmountInput
      v-model="record.transaction_financing.setup_fee_amount"
      :label="$t('offer.setup_fee')"
      :inner-label="record.sales_status.currency"
      :errors="errorMessages['transaction_financing.setup_fee_amount']"
      @enter="handleSave"
    />
    <PercentageInput
      v-model="record.transaction_financing.monthly_transaction_interest_rate_percentage"
      :label="$t('offer.monthly_interest_rate')"
      :tooltip-content="$t('offer.based_on_the_daily_interest_calculation')"
      :errors="errorMessages['transaction_financing.monthly_transaction_interest_rate_percentage']"
      :decimals="3"
      @enter="handleSave"
    />
    <NumberInput
      v-model="record.transaction_financing.maximum_monthly_duration_individual_transaction"
      :label-content="$t('offer.maximum_monthly_duration')"
      :tooltip-content="$t('offer.in_months_of_every_single_transaction')"
      :errors="errorMessages['transaction_financing.maximum_monthly_duration_individual_transaction']"
      @enter="handleSave"
    />
  </div>
  <FormFooter
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    class="mt-auto"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import PercentageInput from '@/components/form-controls/PercentageInput.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import LabelCheckbox from '@/components/form/LabelCheckbox.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import {
  useAmountSuffixConvertCentsToAmount,
  useAmountSuffixConvertAmountToCents,
} from '@/composables/UseNumberManipulation';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import BaseModal from '@/components/BaseModal.vue';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import { nanoid } from 'nanoid';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const { t } = useI18n();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  (useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner()) ||
  useGetProspectCreditProposalStatus() === APPROVED;
let originalCreditProposalObject = {};

const noticePeriodMonths = [
  {
    value: 3,
    label: `${t('ui.time.month', 3)}`,
  },
  {
    value: 6,
    label: `${t('ui.time.month', 6)}`,
  },
];
const durationMonths = [
  {
    value: 12,
    label: `${t('ui.time.month', 12)}`,
  },
  {
    value: 24,
    label: `${t('ui.time.month', 24)}`,
  },
  {
    value: 36,
    label: `${t('ui.time.month', 36)}`,
  },
  {
    value: 48,
    label: `${t('ui.time.month', 48)}`,
  },
];
const euriborInterestTermMonths = [
  {
    value: 1,
    label: `${t('ui.time.month', 1)}`,
  },
  {
    value: 3,
    label: `${t('ui.time.month', 3)}`,
  },
  {
    value: 6,
    label: `${t('ui.time.month', 6)}`,
  },
];

const addFactoringFeeAbroadIsInOfferPreview = computed({
  get() {
    return !!record.value.add_factoring_fee_abroad;
  },
  set(value) {
    record.value.add_factoring_fee_abroad = value;
  },
});

const transactionFinancingIsInOfferPreview = computed({
  get() {
    return !!record.value.transaction_financing.is_in_offer_preview;
  },
  set(value) {
    record.value.transaction_financing.is_in_offer_preview = value;
  },
});

onBeforeMount(async () => {
  useBeforeRouteLeave();

  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (creditProposalHasDataDifference) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;

    const recordCopy = structuredClone(record.value);

    if (!record.value.add_factoring_fee_abroad) {
      recordCopy.factoring_fee_abroad_percentage = null;
    }

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${record.value.sales_status.id}/offer`,
      null,
      useAmountSuffixConvertAmountToCents(structuredClone(recordCopy))
    );
    useSetToast('success', t('toast.success.offer_details_successfully_updated'));
    emit('save', { customerLogs: response.customer_log, offerPreview: response.offer_preview });

    record.value = useSetRecord(useAmountSuffixConvertCentsToAmount(response.offer_details), formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    useSetToast('error', t('toast.error.updating_offer_details') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating offer details: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}

function getCreditProposalObject() {
  const creditProposalData = structuredClone(record.value.sales_status);
  creditProposalData.offer = structuredClone(record.value);

  return { sales_status: creditProposalData };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}
</script>
