import AnnualFiguresView from '@/views/Sales/SalesStatus/AnnualFigures/AnnualFiguresView.vue';

export default {
  path: 'annual-figures',
  name: 'annual figures',
  component: AnnualFiguresView,
  meta: {
    breadCrumbLabel: 'annual_figures',
    permission: 'todo',
  },
};
