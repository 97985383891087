<template>
  <CreateCustomerDataForm :initial-record="record" />
</template>

<script setup>
import CreateCustomerDataForm from '@/forms/sales/sales-status/create/customer/CreateCustomerDataForm.vue';
import { nl_NL } from '@/configs/constants/LocaleCodes';
import { PROSPECT } from '@/configs/constants/CompanyTypes';
import { ref } from 'vue';

const record = ref({
  customer_data: {
    account_manager_employee_id: null,
    branch: null,
    lead_channel: null,
    credit_need_amount: '',
    annual_revenue_amount: '',
    forecast_annual_revenue_amount: '',
    debtor_balance_amount: '',
    debtors_count: null,
    invoices_count: null,
    average_invoice_amount: '',
    default_payment_term_days: null,
    average_payment_term_days: null,
    intermediary: {
      id: null,
      name: null,
    },
    accounting_software: {
      id: null,
      name: null,
    },
    customer_data_financial_facilities: {
      data: [
        {
          id: null,
          facility_amount: '',
          facility_types: [],
          financial_facility: {
            id: null,
            name: null,
          },
        },
      ],
    },
  },
  sales_status: {
    currency: 'EUR',
    correspondence_language: nl_NL,
    company: {
      type: PROSPECT,
      name: null,
      legal_form: {
        id: null,
        name: null,
      },
      registered_office: null,
      phone: null,
      website: null,
      company_registration_number: null,
      vat_number: null,
      address: {
        address: null,
        postal_code: null,
        city: null,
        country_id: null,
      },
    },
  },
});
</script>
