import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/lang/i18n-config';
import '@/assets/scss/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
// import { checkPermission } from '@/services/AppServices';
import { sanitizeHtml, hideNonEditableElement, disableNonEditableInputs } from '@/modules/directives';
// import { useAuthorizationStore } from '@/stores/authorization';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import vClickOutside from 'click-outside-vue3';

const app = createApp(App);
const pinia = createPinia();

app.use(store);
app.use(pinia);
app.use(router);
app.use(vClickOutside);
app.use(i18n);

/*
 * Fetch user's profile from server and check permission
 */
// (async () => {
// await useAuthorizationStore().fetchProfile();
// checkPermission();

// app.mount('#app');
// })();
app.mount('#app');

/** Hide dom elements when user has no permissions to use or see it */
app.directive('can', hideNonEditableElement);

/** Make inputs readonly when user has no permissions to edit this fields */
app.directive('enabled', disableNonEditableInputs);

/**
 * Sanitize raw HTML before injecting it into the DOM using DOMPurify
 */
app.directive('safe-html', sanitizeHtml);
