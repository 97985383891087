<template>
  <div class="sidebar-wrapper">
    <a
      href="#offcanvasPreview"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right offer-preview"
    >
      {{ $t('ui.preview') }}
    </a>
    <BaseSidebar id="offcanvasPreview">
      <BaseCard class="preview">
        <template #body>
          <div v-safe-html="props.offerPreviewRecord" />
        </template>
      </BaseCard>
      <div class="my-3">
        <SecondaryButton
          :loading="loadingSaveAsDocument"
          @click="saveAsDocument"
        >
          {{ t('offer.preview.save_as_document') }}
        </SecondaryButton>
        <SecondaryButton
          class="ms-2"
          @click="download"
        >
          {{ t('ui.download_pdf') }}
        </SecondaryButton>
        <PrimaryButton
          class="ms-2"
          @click="sendEmail"
        >
          {{ t('ui.send_email') }}
        </PrimaryButton>
      </div>
    </BaseSidebar>
  </div>
</template>

<script setup>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import { BaseCard } from '@impact-factoring/impact-branding';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const props = defineProps({
  offerPreviewRecord: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['save', 'download', 'sendEmail']);

const router = useRouter();
const { t } = useI18n();
const loadingSaveAsDocument = ref(false);

async function download() {
  emit('download');
}
async function sendEmail() {
  emit('sendEmail');
}
async function saveAsDocument() {
  try {
    loadingSaveAsDocument.value = true;
    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/document/pdf`,
      null
    );
    useSetToast('success', t('toast.success.offer_document_successfully_saved'));
    emit('save', response.sales_status.customer_log);
  } catch (error) {
    useSetToast('error', t('toast.error.updating_offer_document') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating offer document: ', error);
  } finally {
    loadingSaveAsDocument.value = false;
  }
}
</script>
