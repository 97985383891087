import apiClient from '@/services/ApiClient';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthorizationStore = defineStore('authorization', () => {
  const profile = ref({});
  const employee = ref({});
  const noRouteAccess = ref(false);
  const email = ref();

  async function login(username, password) {
    await apiClient.post('/login', {
      email: username,
      password: password,
    });
    // await fetchProfile();
  }

  async function logout() {
    await apiClient.post('/logout');
    profile.value = {};
  }

  async function setCurrentEmployee(employeeId) {
    const currentEmployee = await apiClient.request('get', `/ifapi/employee/${employeeId}`);
    employee.value = currentEmployee[0];
  }

  function can(permission) {
    if (permission === 'todo') {
      return true;
    }
    return profile.value && profile.value.permissions !== undefined && profile.value.permissions.includes(permission);
  }

  async function fetchProfile() {
    profile.value = await apiClient.request('get', '/api/profile');
  }

  return { profile, employee, noRouteAccess, email, login, logout, setCurrentEmployee, can, fetchProfile };
});
