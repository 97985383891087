import dateFormats from '@/modules/dateFormats';
import dayjs from 'dayjs';

export function isMac() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}

export function generateFileName(id, name) {
  const currentTime = dayjs().format(dateFormats.dateTimeFileName);

  if (!id) {
    return `${name}-${currentTime}.pdf`;
  }

  return `${name}-${id}-${currentTime}.pdf`;
}
