<template>
  <ul
    v-if="list.length"
    class="list-inline me-0"
  >
    <li
      v-for="(item, index) in list"
      :key="index"
      class="list-inline-item"
    >
      <template v-if="typeof item === 'object'">
        {{ item[attribute] }}
      </template>
      <template v-else>
        {{ item }}
      </template>
      <template v-if="index + 1 !== list.length">
        {{ separator }}
      </template>
    </li>
    <li
      v-if="captionEdit"
      class="list-inline-item"
    >
      <a
        class="cursor-pointer link-primary"
        @click.prevent="$emit('addEdit')"
      >
        {{ captionEdit }}
      </a>
    </li>
  </ul>
  <ul
    v-else
    class="list-inline me-0"
  >
    <li class="list-inline-item">
      <i>{{ empty }}</i>
    </li>
    <li
      v-if="captionAdd"
      class="list-inline-item"
    >
      <a
        class="cursor-pointer link-primary"
        @click.prevent="$emit('addEdit')"
      >
        {{ captionAdd }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ConcattedList',
  props: {
    list: {
      type: Array,
      default: null,
    },
    attribute: {
      type: String,
      default: 'name',
    },
    separator: {
      type: String,
      default: ', ',
    },
    empty: {
      type: String,
      default: '-',
    },
    captionEdit: {
      type: String,
      default: '',
    },
    captionAdd: {
      type: String,
      default: '',
    },
  },
  emits: ['addEdit'],
};
</script>
