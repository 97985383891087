<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <DocumentFormatForm
    v-else-if="record"
    :initial-record="record"
    :contact-persons="companyRecord.contact_persons"
    @save="setLogbookAndPreview"
  />
</template>

<script setup>
import DocumentFormatForm from '@/forms/sales/sales-status/offer/DocumentFormatForm.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import {
  useGetCompany,
  useGetOfferPreview,
  useSetLogbookItems,
  useSetOfferPreview,
} from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const record = ref(null);
const companyRecord = ref(null);
const loading = ref(false);

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'offer') {
      getAndSetOfferPreview();
    }
    if (to.meta.preview !== 'offer') {
      useSetOfferPreview('');
    }
  }
);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/document`,
      {
        include: ['recipient', 'signer'],
      }
    );

    setRecordEmptyValues();
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    companyRecord.value = useGetCompany();
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_offer_document_format') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching offer document format: ', error);
  } finally {
    loading.value = false;
  }

  if (!useGetOfferPreview()) {
    await getAndSetOfferPreview();
  }
});

async function getAndSetOfferPreview() {
  try {
    const offerPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/preview`
    );
    useSetOfferPreview(offerPreviewRecord);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_offer_preview') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching offer preview: ', error);
  }
}

function setRecordEmptyValues() {
  if (!record.value.use_informal_salutation) {
    record.value['use_informal_salutation'] = false;
  }
  if (!record.value.personalized_intro) {
    record.value['personalized_intro'] = null;
  }
  if (!record.value.different_signer) {
    record.value['different_signer'] = false;
  }
  if (!record.value.recipient.name) {
    record.value.recipient.name = null;
  }
  if (!record.value.signer.name) {
    record.value.signer.name = null;
  }
}

function setLogbookAndPreview(object) {
  useSetLogbookItems(object.customerLogs);
  if (object?.offerPreview) {
    useSetOfferPreview(object.offerPreview);
  }
}
</script>
