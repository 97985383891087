import CustomerDataView from '@/views/Sales/SalesStatus/CustomerData/CustomerDataView.vue';

export default {
  path: 'data',
  name: 'data',
  component: CustomerDataView,
  meta: {
    breadCrumbLabel: 'master_data',
    permission: 'todo',
  },
};
