import api from '@/services/ApiClient';
import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

export async function useDownload(url, fileName) {
  let file = await api.download(url);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([file]));
  link.download = fileName ?? `${t('ui.file')}.pdf`;
  link.click();
}

export async function useOpenBlobInNewWindow(url) {
  const file = await api.download(url);
  const blob = new Blob([file], { type: 'application/pdf' });
  const objectUrl = URL.createObjectURL(blob);
  window.open(objectUrl, '_blank', 'noopener');
}
