<template>
  <BaseRow class="mt-3">
    <BaseColumn class="col-md-4 col-12">
      <LabelInput
        v-model="record.sales_status.company.name"
        :errors="errorMessages['sales_status.company.name']"
        :label-content="$t('general.company_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <SearchInput
        v-model="record.sales_status.company.legal_form.name"
        :entities="computedLegalForms"
        :errors="errorMessages['sales_status.company.legal_form.name']"
        :disallow-custom-entry="false"
        :label-content="$t('company.legal_form')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.sales_status.correspondence_language"
        :label="$t('customer_data.correspondence_language')"
        :options="getLanguages"
        :errors="errorMessages['customer_data.correspondence_language']"
        :initial-placeholder="false"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.sales_status.currency"
        :label="$t('customer_data.currency')"
        :errors="errorMessages['customer_data.currency']"
        enum-name="currencies"
        :initial-placeholder="false"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <EnumSelect
        v-model="record.customer_data.account_manager_employee_id"
        :label="$t('sales.list.account_manager')"
        :errors="errorMessages['customer_data.account_manager_employee_id']"
        enum-name="employees"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelSelect
        v-model="record.customer_data.lead_channel"
        :errors="errorMessages['customer_data.lead_channel']"
        :label-content="$t('customer_data.lead_channel')"
        :options="leadChannels"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <SearchInput
        v-if="leadChannels[record.customer_data.lead_channel] === leadChannels.INTERMEDIAIR"
        v-model="record.customer_data.intermediary_id"
        field-name="id"
        :entities="enums.mapIntermediaries(enums.data.intermediaries)"
        :errors="errorMessages['customer_data.intermediary_id']"
        :label-content="$t('customer_data.intermediary')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <SearchInput
        v-if="
          leadChannels[record.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
          record.customer_data.intermediary_id
        "
        v-model="record.customer_data.intermediary_company_id"
        field-name="id"
        :entities="computedCompaniesKeyValue"
        :errors="errorMessages['customer_data.intermediary_company_id']"
        :label-content="$t('customer_data.intermediary_company')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <LabelInput
        v-model="record.sales_status.company.registered_office"
        :label-content="$t('address.registered_office')"
        :errors="errorMessages['sales_status.company.registered_office']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="mt-4"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.address"
        :label-content="$t('address.address_information')"
        :errors="errorMessages['sales_status.company.address.address']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.postal_code"
        :label-content="$t('address.postal_code')"
        :errors="errorMessages['sales_status.company.address.postal_code']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.city"
        :label-content="$t('address.city')"
        :errors="errorMessages['sales_status.company.address.city']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <SearchInput
        v-model="record.sales_status.company.address.country_id"
        field-name="id"
        :entities="getCountries"
        :errors="errorMessages['sales_status.company.address.country_id']"
        :label-content="$t('address.country')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelInput
        v-model="record.sales_status.company.phone"
        :label-content="$t('general.phone')"
        :errors="errorMessages['sales_status.company.phone']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        text-position="start"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.website"
        :label-content="$t('general.website')"
        :errors="errorMessages['sales_status.company.website']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <CompanyRegistrationNumberNumberInput
        v-model="record.sales_status.company.company_registration_number"
        :label-content="$t('general.company_registration_number')"
        :errors="errorMessages['sales_status.company.company_registration_number']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <VatNumberInput
        v-model="record.sales_status.company.vat_number"
        :label-content="$t('general.vat_number')"
        :errors="errorMessages['sales_status.company.vat_number']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
    </BaseColumn>
    <BaseColumn class="col-md-4 col-12 second-form-column">
      <div class="position-relative">
        <SearchInput
          v-model="record.customer_data.branch"
          :entities="branchEntities"
          :label-content="$t('client.branch')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
          search-name-and-key
          @focus="getKeyValueBranches"
        />
        <div
          class="d-inline-flex branch-form-icon-right"
          :class="[
            { 'cursor-pointer': record.customer_data.branch },
            { 'cursor-not-allowed': !record.customer_data.branch },
          ]"
          @click="copyBranchKeyToClipboard"
        >
          <div :class="{ 'color-disabled': !record.customer_data.branch }">
            <BaseIcon
              v-if="!copiedToClipboard"
              icon="fa-regular fa-copy"
              :title="$t('general.copy_sbi_code')"
            />
          </div>
          <div v-if="copiedToClipboard">
            <BaseIcon
              icon="fa-solid fa-check text-success"
              class="ms-1"
            />
          </div>
        </div>
      </div>
      <AmountInput
        v-model="record.customer_data.credit_need_amount"
        :label="$t('customer_data.credit_need_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.credit_need_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.annual_revenue_amount"
        :label="$t('customer_data.annual_revenue_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.annual_revenue_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.forecast_annual_revenue_amount"
        :label="$t('customer_data.forecast_annual_revenue_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.forecast_annual_revenue_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.debtor_balance_amount"
        :label="$t('customer_data.debtor_balance_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.debtor_balance_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <NumberInput
        v-model="record.customer_data.debtors_count"
        :errors="errorMessages['customer_data.debtors_count']"
        :label-content="$t('customer_data.debtors_count')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <NumberInput
        v-model="record.customer_data.invoices_count"
        :errors="errorMessages['customer_data.invoices_count']"
        :label-content="$t('customer_data.invoices_count')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.average_invoice_amount"
        :label="$t('customer_data.average_invoice_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.average_invoice_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <EnumSelect
        v-model="record.customer_data.default_payment_term_days"
        :label="$t('customer_data.default_payment_term_days')"
        :errors="errorMessages['customer_data.default_payment_term_days']"
        enum-name="average_payment_term_days"
        with-empty
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.customer_data.average_payment_term_days"
        :label="$t('customer_data.average_payment_term_days')"
        :errors="errorMessages['customer_data.average_payment_term_days']"
        enum-name="average_payment_term_days"
        with-empty
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <SearchInput
        v-model="record.customer_data.accounting_software.name"
        :entities="accountingSoftwareEntities"
        :errors="errorMessages['customer_data.accounting_software_id.name']"
        :disallow-custom-entry="false"
        :label-content="$t('client.accounting_software')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @focus="getKeyValueAccountingSoftware"
      />
      <div
        v-for="(financialFacility, key) in currentFinancialFacilities"
        :key="key"
        class="mb-4 current-financial-facility"
      >
        <SearchInput
          v-model="financialFacility.financial_facility.name"
          :entities="financialFacilityEntities"
          :errors="
            errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.financial_facility.name`]
          "
          :disallow-custom-entry="false"
          :label-content="$t('customer_data.current_financial_facility')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
          @focus="getKeyValueFinancialFacilities"
        />
        <AmountInput
          v-model="financialFacility.facility_amount"
          :label="$t('customer_data.current_facility')"
          :inner-label="record.sales_status.currency"
          :errors="errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.facility_amount`]"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
          @enter="handleSave"
        />
        <MultiselectSearchInput
          v-model="financialFacility.facility_types"
          :entities="facilityTypeEntities"
          :array-of-key-value-objects="true"
          :errors="errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.facility_type`]"
          :label-content="$t('customer_data.facility_type')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
        />
        <div class="icon-button-wrapper">
          <IconButton
            v-if="key === 0"
            button-size="sm"
            icon="fa-solid fa-plus"
            @click="addFinancialFacility"
          />
          <IconButton
            v-if="currentFinancialFacilities.length > 1"
            button-size="sm"
            icon="fa-regular fa-trash-can"
            @click="removeFinancialFacility(key)"
          />
        </div>
      </div>
    </BaseColumn>
    <BaseColumn class="col-12 d-flex">
      <FormFooter
        :pending="loading"
        :disabled="_.isEqual(record, originalRecord) || loading"
        class="mt-auto"
        @save="handleSave"
        @cancel="handleCancel"
      />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import LabelInput from '@/components/form/LabelInput.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import VatNumberInput from '@/components/form-controls/VatNumberInput.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import FacilityTypes from '@/configs/constants/FacilityTypes';
import LeadChannels from '@/configs/constants/LeadChannels';
import apiClient from '@/services/ApiClient';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import {
  useAmountSuffixConvertCentsToAmount,
  useAmountSuffixConvertAmountToCents,
} from '@/composables/UseNumberManipulation';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import { useBeforeRouteLeave, useRemoveFormId, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import LabelSelect from '@/components/form/LabelSelect.vue';
import { en_GB, nl_NL } from '@/configs/constants/LocaleCodes';
import IconButton from '@/components/buttons/IconButton.vue';
import { BaseRow, BaseColumn } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import { required, numeric, requiredIf } from '@vuelidate/validators';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});

const newFinancialFacility = {
  id: null,
  facility_amount: '',
  facility_types: [],
  financial_facility: {
    id: null,
    name: null,
  },
};

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();
const leadChannels = LeadChannels;

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref(null);
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const labelColumnClasses = 'col-md-5 col-12';
const inputColumnClasses = 'col-md-7 col-12';
const financialFacilityEntities = ref({});
const accountingSoftwareEntities = ref({});
const branchEntities = ref([]);
const copiedToClipboard = ref(false);
const facilityTypeEntities = {};

const rules = computed(() => {
  return {
    sales_status: {
      company: {
        name: { required },
      },
    },
    customer_data: {
      debtors_count: { numeric },
      invoices_count: { numeric },
      intermediary_id: {
        requiredIf: requiredIf(
          leadChannels[record.value.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
            !record.value.customer_data.intermediary_id
        ),
      },
      intermediary_company_id: {
        requiredIf: requiredIf(
          leadChannels[record.value.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
            !record.value.customer_data.intermediary_company_id
        ),
      },
    },
  };
});

const computedCompaniesKeyValue = computed(() => {
  if (!record.value.customer_data.intermediary_id) {
    return {};
  }

  const intermediary = enums.data.intermediaries.find(
    (intermediary) => intermediary.id === record.value.customer_data.intermediary_id
  );

  return Object.values(intermediary?.companies).map((company) => {
    let companyObject = {
      id: company.id,
      name: company.name,
    };

    const duplicateName = Object.values(intermediary?.companies).filter(
      (filteredCompany) => filteredCompany.name === company.name
    );

    if (duplicateName.length > 1) {
      companyObject = {
        ...companyObject,
        uniqueIdentifier: company.company_registration_number,
      };
    }
    return companyObject;
  });
});

const getCountries = computed(() => {
  const countries = {};
  if (enums.data.countries) {
    enums.data.countries.map((country) => {
      countries[country.id] = country.translation;
    });
  }
  return countries;
});

const getLanguages = computed(() => {
  return { [en_GB]: t('languages.english'), [nl_NL]: t('languages.dutch') };
});

const currentFinancialFacilities = computed(() => {
  return record.value.customer_data.customer_data_financial_facilities?.data;
});

const computedLegalForms = computed(() => {
  return Object.entries(enums.data.legal_forms).map((legalForm) => {
    return {
      id: parseInt(legalForm[0]),
      name: legalForm[1],
    };
  });
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
  setFacilityTypes();
});

async function setValidationAndErrorMessages() {
  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
}

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  await setValidationAndErrorMessages();
  if (Object.keys(errorMessages.value).length) {
    return;
  }

  if (leadChannels[record.value.customer_data.lead_channel] !== leadChannels.INTERMEDIAIR) {
    record.value.customer_data.intermediary_id = null;
    record.value.customer_data.intermediary_company_id = null;
    record.value.customer_data.intermediary = {};
  }

  try {
    loading.value = true;

    const response = await apiClient.request(
      'post',
      '/ifapi/sales_statuses',
      { include: ['customer_data'] },
      useAmountSuffixConvertAmountToCents(structuredClone(record.value))
    );
    useSetToast('success', t('toast.success.customer_data_successfully_created'));

    addCompaniesEnum(response);
    enums.addOrUpdateEnumName('legal_forms', response.company.legal_form.id, response.company.legal_form.name);

    useRemoveFormId(formId);
    await router.push({ name: 'edit sales status', params: { sales_status_id: response.id } });
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    useSetToast('error', t('toast.error.creating_customer_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating customer data: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
  errorMessages.value = [];
}

async function getKeyValueAccountingSoftware() {
  if (_.isEmpty(accountingSoftwareEntities.value)) {
    accountingSoftwareEntities.value = await apiClient.get('/ifapi/dynamic/accounting_software');
  }
}
async function getKeyValueFinancialFacilities() {
  if (_.isEmpty(financialFacilityEntities.value)) {
    financialFacilityEntities.value = await apiClient.get('/ifapi/dynamic/financial_facilities');
  }
}
async function getKeyValueBranches() {
  if (_.isEmpty(branchEntities.value)) {
    branchEntities.value = await apiClient.request('get', '/ifapi/config/branches');
  }
}

function addFinancialFacility() {
  currentFinancialFacilities.value.push(structuredClone(newFinancialFacility));
}
function removeFinancialFacility(index) {
  currentFinancialFacilities.value.splice(index, 1);
}
function setFacilityTypes() {
  Object.entries(FacilityTypes).forEach((type) => {
    facilityTypeEntities[type[0]] = type[1];
  });
}

function addCompaniesEnum(response) {
  const companyEnum = {
    id: response.company.id,
    type: response.company.type,
    name: response.company.name,
    company_registration_number: response.company.company_registration_number,
  };

  enums.addOrUpdateEnumObject('companies', companyEnum);
}

function copyBranchKeyToClipboard() {
  if (!record.value.customer_data.branch) {
    return;
  }

  navigator.clipboard.writeText(findCurrentBranch()[0]);
  copiedToClipboard.value = true;
  setTimeout(() => {
    copiedToClipboard.value = false;
  }, 2000);
}
function findCurrentBranch() {
  return Object.entries(branchEntities.value).find((branch) => branch[1] === record.value.customer_data.branch);
}
</script>
