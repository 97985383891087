import apiClient from '@/services/ApiClient';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEnumerationsStore = defineStore('enums', () => {
  const data = ref({});
  const fetched = ref([]);

  async function fetchEnums(enums) {
    const apiUrl = `/ifapi/enums`;
    const enumObject = await apiClient.request('get', apiUrl, { enums: enums });
    enums.forEach((enumName) => {
      data.value[enumName] = enumObject[enumName];
    });
  }

  async function fetchEnum(enumName, params) {
    const apiUrl = `/ifapi/enum/${enumName}`;

    if (fetched.value.indexOf(enumName) === -1) {
      fetched.value.push(enumName);
      data.value[enumName] = await apiClient.request('get', apiUrl, params);
    }
  }

  async function fetchCustomEnums(apiUrl, enumName) {
    const request = await apiClient.request('get', apiUrl);
    if (request) {
      data.value[enumName] = request.data;
    }
  }

  function addEnum(enumName, object) {
    data.value[enumName] = { ...data.value[enumName], ...object };
  }

  function addOrUpdateEnumObject(enumName, object) {
    if (!object?.id) {
      return;
    }

    const index = Object.values(data.value[enumName]).findIndex((item) => {
      return parseInt(item.id) === object.id;
    });

    if (index !== -1) {
      data.value[enumName][index] = object;
      return;
    }

    data.value[enumName].push(object);
  }

  function addOrUpdateEnumName(enumName, id, name) {
    if (!id) {
      return;
    }

    const index = Object.values(data.value[enumName]).findIndex((item) => {
      return parseInt(item.id) === id;
    });

    if (index !== -1) {
      data.value[enumName][index].name = name;
      return;
    }

    addEnum(enumName, { [id]: name });
  }

  function removeEnum(enumName, id) {
    data.value[enumName] = Object.values(data.value[enumName]).filter((item) => {
      return parseInt(item.id) !== id;
    });
  }

  function mapIntermediaries(intermediaries) {
    if (!intermediaries?.length) {
      return [];
    }

    return intermediaries
      .map((item) => {
        let intermediary = { id: item.id, name: item.last_name };

        const duplicateName = intermediaries.filter((filterItem) => filterItem.last_name === intermediary.name);

        if (duplicateName.length > 1) {
          intermediary = {
            ...intermediary,
            uniqueIdentifier:
              item?.companies[0]?.name || item?.email_address || item?.phone_primair || item?.phone_secundair,
          };
        }

        return intermediary;
      })
      .filter((intermediary) => !!intermediary?.id);
  }

  return {
    data,
    fetched,
    fetchEnums,
    fetchEnum,
    fetchCustomEnums,
    addOrUpdateEnumObject,
    addOrUpdateEnumName,
    removeEnum,
    mapIntermediaries,
  };
});
