<template>
  <div
    v-if="show"
    :class="`modal modal-size-${size} show`"
    :aria-labelledby="labelId"
    :aria-hidden="!show"
  >
    <div :class="['modal-dialog', dialogClasses, modalDialogClasses]">
      <div
        v-click-outside="closeModal"
        class="modal-content"
        :style="{ 'min-height': props.minHeight }"
      >
        <div class="modal-header">
          <div
            v-if="modalTitle"
            class="modal-title"
          >
            {{ modalTitle }}
          </div>
          <button
            class="btn-close"
            :aria-label="labelClose"
            @click.prevent="closeModal"
          />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div
          v-if="defaultFooter"
          class="modal-footer"
        >
          <SecondaryButton @click.prevent="closeModal">
            {{ labelCancel }}
          </SecondaryButton>
          <PrimaryButton @click.prevent="emit('confirm')">
            {{ labelConfirm }}
          </PrimaryButton>
        </div>
        <div
          v-if="$slots.modalFooter && customFooter"
          class="modal-footer"
        >
          <slot name="modalFooter" />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="show"
    class="modal-backdrop show"
  />
</template>

<script setup>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'lg',
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value);
    },
  },
  modalTitle: {
    type: String,
    default: null,
  },
  labelConfirm: {
    type: String,
    default: null,
  },
  labelCancel: {
    type: String,
    default: null,
  },
  labelClose: {
    type: String,
    default: null,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  customFooter: {
    type: Boolean,
    default: false,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  closeOnEscape: {
    type: Boolean,
    default: true,
  },
  minHeight: {
    type: String,
    default: '0',
  },
  modalDialogClasses: {
    type: String,
    default: '',
  },
  modalFullscreen: {
    type: String,
    default: null,
    validator(value) {
      return (
        [
          'fullscreen',
          'fullscreen-sm-down',
          'fullscreen-md-down',
          'fullscreen-lg-down',
          'fullscreen-xl-down',
          'fullscreen-xxl-down',
        ].indexOf(value) !== -1
      );
    },
  },
});
const emit = defineEmits(['confirm', 'close']);

const { t } = useI18n();
let labelConfirm = ref(props.labelConfirm);
let labelCancel = ref(props.labelCancel);
let labelClose = ref(props.labelClose);

onBeforeUnmount(() => {
  document.removeEventListener('keydown', closeModalOnEscape);
});

onBeforeMount(() => {
  if (!labelConfirm.value) {
    labelConfirm.value = t('ui.confirm');
  }
  if (!labelCancel.value) {
    labelCancel.value = t('ui.cancel');
  }
  if (!labelClose.value) {
    labelClose.value = t('ui.close');
  }
});

onMounted(() => {
  if (props.closeOnEscape) {
    document.addEventListener('keydown', closeModalOnEscape);
  }
});

const labelId = computed(() => {
  return props.modalTitle ? `${props.modalTitle}-label` : null;
});

const defaultFooter = computed(() => {
  return props.hideFooter || props.customFooter ? false : true;
});

const dialogClasses = computed(() => {
  return {
    'modal-dialog-scrollable': props.scrollable,
    'modal-dialog-centered': props.centered,
    [`modal-${props.modalFullscreen}`]: props.modalFullscreen,
  };
});

function closeModal() {
  emit('close');
}

function closeModalOnEscape(event) {
  if (event.key === 'Escape') {
    closeModal();
  }
}
</script>
