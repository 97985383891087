<template>
  <div class="d-flex flex-column h-100">
    <TabList
      v-if="showTabs && tabs.length"
      class="mb-4 pb-3"
    >
      <TabItem
        v-for="(tab, index) in tabs"
        :key="index"
        :route="tab.name"
      >
        {{ $t(`navigation.${tab.meta.breadCrumbLabel}`) }}
      </TabItem>
    </TabList>
    <div
      class="h-100 d-flex flex-column"
      :class="{ 'px-5 mx-4': route.name !== 'organogram structure' }"
    >
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import TabList from '@/components/tabs/TabList.vue';
import TabItem from '@/components/tabs/TabItem.vue';
import MenuService from '@/services/MenuService';
import { ref, onMounted, onBeforeMount } from 'vue';
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, RouterView } from 'vue-router';

const route = useRoute();
const showTabs = ref(true);
const tabs = ref([]);
const parentRoute = ref(MenuService.findInRoute('tabs'));

onBeforeMount(async () => {
  if (parentRoute.value) {
    tabs.value = MenuService.filterTabsFromParentRoute(parentRoute);
  }
});

onMounted(() => {
  if (route.meta?.menutype === 'tab_child' || route.meta?.menutype === 'prospect_view') {
    showTabs.value = false;
  }
});

onBeforeRouteUpdate((to) => {
  if (to.meta?.menutype === 'tab_child' || to.meta?.menutype === 'prospect_view') {
    showTabs.value = false;
  }

  if (to.meta?.menutype === 'tab') {
    showTabs.value = true;
  }
});

onBeforeRouteLeave((to) => {
  if (to.meta.menutype === 'tab') {
    parentRoute.value = MenuService.findInRoute('tabs', to);
    tabs.value = MenuService.filterTabsFromParentRoute(parentRoute);
  }
});
</script>
