import DocumentView from '@/views/Sales/SalesStatus/Document/DocumentView.vue';

export default {
  path: 'documents',
  name: 'documents',
  component: DocumentView,
  meta: {
    breadCrumbLabel: 'documents',
    permission: 'todo',
  },
};
