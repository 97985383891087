export default {
  ui: {
    numbers: {
      readable_counts: {
        1: 'First',
        2: 'Second',
        3: 'Third',
        4: 'Fourth',
        5: 'Fifth',
      },
    },
  },
  email: {
    offer_message:
      'Beste {company},\n\nZoals reeds besproken sturen wij u hierbij met veel plezier onze offerte toe. Wij zijn benieuwd naar uw reactie hierop.\nMocht u vragen hebben, dan beantwoorden wij deze graag.\n\nMet vriendelijke groet,\n{employee}',
    credit_proposal_message:
      'Beste commissieleden,\n\nHierbij de stukken van {company} voor het kredietcomité. Ik zal een agendaverzoek sturen.\n\nGr,\n{employee}',
  },
  sales: {
    organogram: {
      change_layout: 'Change layout',
      top: 'Top',
      bottom: 'Bottom',
      left: 'Left',
      right: 'Right',
      full_screen: 'Full screen',
      center: 'Center',
      expand_all: 'Expand all',
      collapse_all: 'Collapse all',
    },
  },
};
