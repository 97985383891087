<template>
  {{ dateFormatted }}
</template>

<script>
import dateFormats from '@/modules/dateFormats';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import nl from 'dayjs/locale/nl';

dayjs.extend(localizedFormat);
dayjs.locale(nl);

/**
 * Formats of date and date time
 * Avoid too much different formats!!
 * @see https://day.js.org
 * @type {{simpleDate: string, readableDate: string, simpleDateTime: string}}
 */
export default {
  name: 'DateFormat',
  props: {
    date: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'simpleDate',
    },
    whenEmpty: {
      type: String,
      default: '-',
    },
  },
  computed: {
    dateFormatted() {
      return this.date ? dayjs(this.date, dayjs.ISO_8601).format(dateFormats[this.format]) : this.whenEmpty;
    },
  },
};
</script>
