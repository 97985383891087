<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <OrganogramForm
    v-else-if="Object.keys(record)?.length"
    :initial-record="record"
    @save="updateLogbook"
  />
</template>

<script setup>
import OrganogramForm from '@/forms/sales/sales-status/organogram/OrganogramForm.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();

const record = ref({});
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    const response = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/organogram`
    );

    if (response) {
      record.value = response;
    }

    if (!record.value?.cards) {
      record.value['cards'] = getCards();
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_organogram') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching organogram: ', error);
  } finally {
    loading.value = false;
  }
});

function getCards() {
  return [
    {
      id: null,
      person_id: null,
      company_id: null,
      card_id: nanoid(),
      parent_card_id: null,
      name: null,
    },
  ];
}

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
