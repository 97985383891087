<template>
  <FormControlWrapper
    v-if="labelContent"
    v-bind="formControlProps"
    :for="labelId"
    :wrapper-class="['dynamic-search-input', props.wrapperClass]"
  >
    <div
      v-click-outside="closeDropdown"
      @keydown.esc="closeDropdown"
    >
      <div
        class="dropdown-arrow"
        :class="{ 'dropdown-arrow-open': showDropdown }"
      >
        <BaseInput
          v-bind="$attrs"
          :id="labelId"
          v-model="computedModelValue"
          :placeholder="placeholder"
          :errors="props.errors"
          :show-error-messages="props.showErrorMessages"
          :class="{
            'fade-in-text': computedModelValue,
          }"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div class="position-relative w-100">
        <SearchInputDropdown
          v-if="showDropdown"
          :data="computedSearchResults"
          :initial-selected-item="selectedItem"
          :class="{ 'overflow-auto': computedSearchResults?.length > 8 }"
          class="w-100"
          @clicked-item="handleSelectItem"
        />
      </div>
    </div>
    <slot />
  </FormControlWrapper>
  <div
    v-else
    class="dynamic-search-input"
  >
    <div
      v-click-outside="closeDropdown"
      @keydown.esc="closeDropdown"
    >
      <BaseInput
        v-bind="$attrs"
        :id="labelId"
        v-model="computedModelValue"
        :errors="props.errors"
        :show-error-messages="props.showErrorMessages"
        :placeholder="placeholder"
        :class="{
          'fade-in-text': selectedItem !== computedModelValue,
        }"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div class="position-relative w-100">
        <SearchInputDropdown
          v-if="showDropdown"
          :data="computedSearchResults"
          :initial-selected-item="selectedItem"
          :class="{ 'overflow-auto': computedSearchResults?.length > 8 }"
          class="w-100"
          @clicked-item="handleSelectItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import BaseInput from '@/components/form/BaseInput';
import SearchInputDropdown from '@/components/form-controls/SearchInput/SearchInputDropdown.vue';
// import { useFocusFirstNode, useFocusLastNode } from '@/composables/UseHotkeyNodeSelection';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { nanoid } from 'nanoid';

const props = defineProps({
  entities: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  modelValue: {
    type: [Number, String],
    default: null,
  },
  itemKey: {
    type: Number,
    default: null,
  },
  fieldName: {
    type: String,
    default: 'name',
    validator: (val) => ['id', 'name'].includes(val),
  },
  initialPlaceholder: {
    type: String,
    default: '',
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  searchNameAndKey: {
    type: Boolean,
    default: false,
  },
  sortByName: {
    type: Boolean,
    default: true,
  },
  disallowCustomEntry: {
    type: Boolean,
    default: true,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
  labelContent: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
});
const emit = defineEmits(['update:modelValue', 'focus', 'selectItem']);

const { t } = useI18n();
const formControlProps = {
  labelContent: props.labelContent,
  labelColumns: props.labelColumns,
  inputColumns: props.inputColumns,
  tooltipContent: props.tooltipContent,
};

const placeholder =
  props.initialPlaceholder || props.disallowCustomEntry
    ? t('ui.placeholders.search')
    : t('ui.placeholders.search_or_add');

const showDropdown = ref(false);
const originalModelValue = ref(null);
const selectedItem = ref(null);
const labelId = nanoid();

const computedModelValue = computed({
  get() {
    if (!Object.keys(props.entities).length) {
      return props.modelValue;
    }

    if (Number.isInteger(props.modelValue)) {
      const item = getSelectedItemById(props.modelValue);
      return item?.name;
    }

    // todo if name is found en id is same as props.itemId find() then selectedItem with id
    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});

const computedSearchResults = computed(() => {
  return filteredList();
});

const computedEntities = computed(() => {
  if (!Object.entries(props.entities).length && !props.entities?.length) {
    return props.entities;
  }

  let entities = props.entities;
  if (_.isObject(props.entities) && !Array.isArray(props.entities)) {
    entities = Object.entries(props.entities).map((entry) => {
      return { id: parseInt(entry[0]), name: entry[1] };
    });
  }

  if (props.sortByName) {
    entities = _.sortBy(entities, 'name');
  }

  return entities;
});

onBeforeMount(() => {
  selectedItem.value = props.modelValue;
  if (Number.isInteger(props.modelValue)) {
    emit('selectItem', props.modelValue, props.itemKey);
  }
});

function filteredList() {
  if (!computedModelValue.value) {
    return computedEntities.value;
  }
  const filteredArray = [];
  for (let i = 0, cachedLength = computedEntities.value.length; i < cachedLength; ++i) {
    if (
      computedEntities.value[i].name.toLowerCase().includes(computedModelValue.value.toLowerCase()) ||
      (props.searchNameAndKey && computedEntities.value[i].id.toString().includes(computedModelValue.value))
    ) {
      filteredArray.push(computedEntities.value[i]);
    }
  }
  return filteredArray;
}

function handleSelectItem(item) {
  if (selectedItem.value === item?.id) {
    computedModelValue.value = null;
    selectedItem.value = null;
    emit('selectItem', null, props.itemKey);
    return;
  }

  selectedItem.value = item?.id;
  emit('selectItem', item?.id, props.itemKey);

  computedModelValue.value = item.name;

  if (props.fieldName === 'id' && item.id) {
    emit('update:modelValue', item.id);
  }

  closeDropdown();
}

function onBlur(event) {
  if (!event?.relatedTarget?.classList.contains('item')) {
    if (!Object.keys(props.entities).length) {
      if (props.disallowCustomEntry) {
        emit('update:modelValue', null);
        emit('selectItem', null, props.itemKey);
        selectedItem.value = null;
      }
      closeDropdown();
      return;
    }

    if (!props.disallowCustomEntry) {
      const item = getSelectedItemByName(computedModelValue.value);
      emit('selectItem', item?.id || null, props.itemKey);
      closeDropdown();
      return;
    }

    if (props.disallowCustomEntry) {
      if (!computedModelValue.value) {
        emit('selectItem', null, props.itemKey);
        closeDropdown();
        return;
      }

      let item;
      item = getSelectedItemByName(computedModelValue.value);

      if (!item?.id) {
        item = getSelectedItemByName(originalModelValue.value);
      }

      if (!item?.id) {
        emit('update:modelValue', null);
        emit('selectItem', null, props.itemKey);
        selectedItem.value = null;
        closeDropdown();
        return;
      }

      selectedItem.value = item.id;
      if (props.fieldName === 'id') {
        emit('update:modelValue', item.id);
        emit('selectItem', item.id, props.itemKey);
      } else {
        emit('update:modelValue', item.name);
        emit('selectItem', item.name, props.itemKey);
      }
    }
    closeDropdown();
  }
}

function onFocus() {
  originalModelValue.value = structuredClone(computedModelValue.value);
  if (!computedModelValue.value) {
    selectedItem.value = null;
  }

  if (Number.isInteger(props.modelValue)) {
    selectedItem.value = props.modelValue;
    emit('focus');
    openDropdown();
    return;
  }

  if (Object.entries(props.entities).length && computedModelValue.value) {
    const item = getSelectedItemByName(computedModelValue.value);
    selectedItem.value = item?.id;
  }

  emit('focus');
  openDropdown();
}

function getSelectedItemByName(value) {
  return computedEntities.value.find((entity) => {
    if (entity.uniqueIdentifier) {
      return entity?.id === selectedItem.value && entity?.name?.toLowerCase() === value?.toLowerCase();
    }
    return entity?.name?.toLowerCase() === value?.toLowerCase();
  });
}

function getSelectedItemById(value) {
  return computedEntities.value.find((entity) => {
    return entity.id === value;
  });
}

function openDropdown() {
  showDropdown.value = true;
}
function closeDropdown() {
  showDropdown.value = false;
}
// function setDropdownItemRefs(refs) {
//   dropdownItemRefs.value = refs;
// }
// function focusFirstDropdownElement(event) {
//   useFocusFirstNode(event, dropdownItemRefs.value);
// }
// function focusLastDropdownElement(event) {
//   useFocusLastNode(event, dropdownItemRefs.value);
// }
</script>
