<template>
  <div>
    <a
      href="#logbookSidebar"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right"
    >
      {{ $t('sales.log.title') }}
    </a>
    <BaseSidebar
      id="logbookSidebar"
      backdrop-disabled
    >
      <LogbookCard
        v-if="props.initialRecord?.customer_log?.length"
        :initial-record="props.initialRecord"
        @before-mount="handleEmitBeforeMount"
      />
    </BaseSidebar>
  </div>
</template>

<script setup>
import LogbookCard from '@/components/sales/LogbookCard.vue';
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save', 'beforeMount']);

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
}
</script>
