<template>
  <CreateOrUpdateNoteModal
    v-if="showCreateNoteModal"
    :show-modal="showCreateNoteModal"
    :initial-record="note"
    @close="closeNoteModal"
    @save="setRecord"
  />
  <div
    class="previous-button svg-wrapper d-flex align-items-center"
    @click="goToIntermediaries"
  >
    <svg
      width="24"
      height="12"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 4.25L17.25 4.25"
        stroke="black"
        stroke-width="1.00088"
        stroke-linejoin="round"
      />
      <path
        d="M1.25 4.25003L16.75 4.25003"
        stroke="black"
        stroke-width="1.00088"
        stroke-linejoin="round"
      />
      <path
        d="M3.75 1.25C2.57843 2.42157 1.92157 3.07843 0.750001 4.25L3.75 7.25"
        stroke="black"
        stroke-width="1.00088"
        stroke-linejoin="round"
      />
    </svg>

    <span>{{ $t('intermediary.intermediaries') }}</span>
  </div>

  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <IntermediaryForm
    v-else-if="Object.keys(record)?.length"
    :initial-record="record"
  >
    <template #prospects>
      <div class="col-3 px-4">
        <div class="action-bar d-flex justify-content-between align-items-center mb-3 pe-4">
          <div class="intermediary-title pt-2 mb-2">{{ $t('intermediary.prospects') }}</div>
          <ActionAddButton
            :label="$t('sales.status.prospect')"
            size="sm"
            @click="goToCreateProspect"
          />
        </div>
        <IntermediaryProspectList
          v-if="record.prospects.length"
          :initial-record="record.prospects"
        />
      </div>
    </template>
    <template #notes>
      <div class="col-3 px-4">
        <div class="action-bar d-flex justify-content-between align-items-center mb-3 pe-4">
          <div class="intermediary-title pt-2 mb-2">{{ $t('intermediary.notes') }}</div>
          <ActionAddButton
            :label="$t('intermediary.note')"
            size="sm"
            @click="addNote"
          />
        </div>
        <IntermediaryNoteList
          v-if="record.notes.length"
          :initial-record="record"
          :initial-show-note-id="noteId"
          @edit="editNote"
          @show="setNoteId"
        />
      </div>
    </template>
  </IntermediaryForm>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import IntermediaryForm from '@/forms/Intermediaries/IntermediaryForm.vue';
import IntermediaryProspectList from '@/lists/intermediaries/IntermediaryProspectList.vue';
import IntermediaryNoteList from '@/lists/intermediaries/IntermediaryNoteList.vue';
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import CreateOrUpdateNoteModal from '@/forms/Intermediaries/CreateOrUpdateNoteModal.vue';
import { useAuthorizationStore } from '@/stores/authorization';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();

const record = ref({});
const loading = ref(false);
const showCreateNoteModal = ref(false);
const noteId = ref(null);
const note = ref(null);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request('get', `/ifapi/intermediaries/${route.params.id}`);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_intermediary') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching intermediary: ', error);
  } finally {
    loading.value = false;
  }
});

function addNote() {
  note.value = null;
  openNoteModal();
}

function openNoteModal() {
  showCreateNoteModal.value = true;
}

function closeNoteModal() {
  showCreateNoteModal.value = false;
}

function goToCreateProspect() {
  router.push({
    name: 'create customer',
  });
}

function setRecord(noteRecord) {
  if (note.value?.id) {
    const index = record.value.notes.findIndex((noteRecord) => noteRecord.id === note.value.id);
    record.value.notes[index] = noteRecord;
    return;
  }

  if (!noteRecord?.id) {
    return;
  }

  record.value.notes = [...record.value?.notes, noteRecord];
  noteId.value = noteRecord.id;
}

function editNote(noteRecord) {
  if (noteRecord.employee_id !== authorizationStore.employee.id) {
    return;
  }

  note.value = noteRecord;
  openNoteModal();
}

function setNoteId(id) {
  if (noteId.value === id) {
    noteId.value = null;
    return;
  }
  noteId.value = id;
}

function goToIntermediaries() {
  router.push({ name: 'list intermediaries' });
}
</script>
