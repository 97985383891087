<template>
  <TopBar sticky>
    <BaseColumn class="col-md-8 col-lg-9">
      <IconButton
        class="me-3"
        icon="fa-solid fa-bars"
        @click="toggleMainNav"
      />
      <BreadCrumb :breadcrumbs="navigationStore.breadCrumbs" />
    </BaseColumn>
    <BaseColumn class="col-md-4 col-lg-3 justify-content-end">
      <SearchInput id="search-topbar" />
      <IconButton
        class="app-navigation"
        icon="fa-solid fa-bell"
        :title="$t('ui.notifications')"
      />
      <IconButton
        class="app-navigation"
        icon="fa-solid fa-user"
      />
      <IconButton
        class="app-navigation"
        icon="fa-solid fa-right-from-bracket"
        :title="$t('ui.logout.label')"
        @click="logout"
      />
    </BaseColumn>
  </TopBar>

  <BaseModal
    :show="logoutModal"
    :modal-title="$t('ui.logout.title')"
    :close-on-escape="false"
    custom-footer
    scrollable
    @close="toggleLogoutModal"
  >
    <p>{{ $t('ui.logout.body') }}</p>
    <template #modalFooter>
      <SecondaryButton
        data-bs-dismiss="modal"
        @click.prevent="toggleLogoutModal"
      >
        {{ $t('ui.logout.cancel') }}
      </SecondaryButton>
      <BaseButton
        button-style="danger"
        :link="{ name: 'logout' }"
      >
        {{ $t('ui.logout.confirm') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup>
import { useApplicationStore } from '@/stores/application';
import { useNavigationStore } from '@/stores/navigation';
import BaseModal from '@/components/BaseModal.vue';
import {
  TopBar,
  BaseColumn,
  SearchInput,
  IconButton,
  BreadCrumb,
  BaseButton,
  SecondaryButton,
} from '@impact-factoring/impact-branding';
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const emit = defineEmits(['toggleMainNav']);

const applicationStore = useApplicationStore();
const navigationStore = useNavigationStore();
const logoutModal = ref(false);
const router = useRouter();
const store = useStore();
const customBreadCrumbLabelContent = ref('');

watch(router.currentRoute, async (to) => {
  customBreadCrumbLabelContent.value = '';

  if (to.params.client_id) {
    await getCurrentClientNumber();
  }

  navigationStore.setBreadCrumbs(customBreadCrumbLabelContent.value);
});

onMounted(async () => {
  await getCurrentClientNumber();
  navigationStore.setBreadCrumbs(customBreadCrumbLabelContent.value);
});

function logout() {
  if (applicationStore.checkIsDirtyState()) {
    toggleLogoutModal();
    return;
  }

  router.push({ name: 'logout' });
}

function toggleLogoutModal() {
  return (logoutModal.value = !logoutModal.value);
}

async function getCurrentClientNumber() {
  if (
    store.state.ClientsStore.entity?.client_number &&
    store.state.ClientsStore.entity?.id === router.currentRoute.value.params.client_id
  ) {
    customBreadCrumbLabelContent.value = store.state.ClientsStore.entity.client_number;
    return;
  }

  if (router.currentRoute.value.params.client_id) {
    const client = await store.dispatch(
      'ClientsStore/fetchEntity',
      `/ifapi/clients/${router.currentRoute.value.params.client_id}`
    );
    customBreadCrumbLabelContent.value = client.client_number;
    return;
  }

  customBreadCrumbLabelContent.value = '';
}

function toggleMainNav() {
  emit('toggleMainNav');
}
</script>
