<template>
  <FormControlWrapper
    v-bind="formControlProps"
    :for="labelId"
    @click="focusContentEditable"
  >
    <div
      v-click-outside="closeDropdown"
      class="multiselect-search-input"
      @keydown.esc="blurAndCloseDropdown"
      @mousedown="setShowDropdown"
    >
      <div
        class="input-wrapper"
        :class="{ 'input-error': props.errors.length }"
        @click="focusContentEditable"
      >
        <BaseIcon icon="fa-solid fa-angle-down icon-arrow-down" />
        <div
          class="multiselect-items"
          :class="{ 'px-1': computedModelValue.length }"
        >
          <template v-for="(item, index) in computedModelValue">
            <div
              v-if="item?.name"
              :key="index"
              class="item"
              :class="{ 'fade-in-item': selectedItemId === item.id }"
            >
              <div class="text-truncate">{{ item.name }}</div>
              <BaseIcon
                icon="fa-solid fa-xmark icon-close"
                @mouseup="removeItemOnClick(item)"
                @mousedown.prevent
              />
            </div>
          </template>
          <div
            :id="labelId"
            class="contenteditable-wrapper"
          >
            <span
              ref="contentEditable"
              contenteditable
              class="contenteditable"
              :class="{ 'contenteditable-placeholder ps-2': !computedModelValue.length }"
              :placeholder="placeholder"
              @focus="openDropdown"
              @keyup="handleKeyup"
              @keydown.backspace="removeLastItem"
            />
          </div>
        </div>
      </div>
      <div class="position-relative w-100">
        <MultiselectDropdown
          v-if="searchResults.length"
          :data="searchResults"
          :initial-selected-items="computedModelValue"
          :class="{ 'overflow-auto': searchResults.length > 8 }"
          class="w-100"
          @clicked-item="handleClickedItem"
        />
      </div>
      <ErrorMessages :errors="props.errors" />
    </div>
  </FormControlWrapper>
</template>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import MultiselectDropdown from '@/components/form-controls/Multiselect/MultiselectDropdown.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
// import { useFocusFirstNode, useFocusLastNode } from '@/composables/UseHotkeyNodeSelection';
import ErrorMessages from '@/components/ErrorMessages.vue';
import { computed, ref } from 'vue';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const props = defineProps({
  entities: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  labelContent: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  initialPlaceholder: {
    type: String,
    default: '',
  },
  arrayOfKeyValueObjects: {
    type: Boolean,
    default: false,
  },
  sortByName: {
    type: Boolean,
    default: true,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();
const formControlProps = {
  labelContent: props.labelContent,
  labelColumns: props.labelColumns,
  inputColumns: props.inputColumns,
};
const labelId = nanoid();
const searchResults = ref([]);
const placeholder = props.initialPlaceholder || t('ui.placeholders.search');
const contentEditable = ref(null);
const showDropdown = ref(false);
const selectedItemId = ref(null);

const computedModelValue = computed({
  get() {
    if (props.arrayOfKeyValueObjects) {
      const modelValue = props.modelValue.map((val) => {
        return {
          id: val,
          name: Object.entries(props.entities).find((entity) => parseInt(entity[0]) === val || entity[0] === val)?.[1],
        };
      });
      return modelValue.filter((item) => item?.name);
    }

    return props.modelValue;
  },
  set(value) {
    if (props.arrayOfKeyValueObjects) {
      value = value.map((val) => val.id);
    }
    emit('update:modelValue', value);
  },
});

const computedItems = computed(() => {
  const entities = Object.entries(props.entities).map((item) => {
    return {
      id: parseInt(item[0]) || item[0],
      name: item[1],
    };
  });

  if (props.sortByName) {
    return _.sortBy(entities, 'name');
  }

  return entities;
});

function handleKeyup(event) {
  searchItems(event.target.innerText);
}

function searchItems(value) {
  searchResults.value = filteredList(value);
}
function filteredList(val) {
  return computedItems.value.filter((entity) => entity.name.toLowerCase().includes(val.toLowerCase()));
}

function handleClickedItem(selectedItem) {
  selectedItemId.value = selectedItem.id;

  if (!isNaN(parseInt(selectedItem.id))) {
    selectedItem.id = parseInt(selectedItem.id);
  }

  const index = computedModelValue.value.findIndex((item) => {
    return item.id === selectedItem.id;
  });

  if (index !== -1) {
    removeItem(selectedItem.id);
    return;
  }
  computedModelValue.value = [...computedModelValue.value, { id: selectedItem.id, name: selectedItem.name }];
  contentEditable.value.innerText = '';
  searchItems(contentEditable.value.innerText);
}

function removeItem(id) {
  if (!isNaN(parseInt(id))) {
    id = parseInt(id);
  }

  computedModelValue.value = computedModelValue.value.filter((item) => {
    return item.id !== id;
  });
}
function removeItemOnClick(selectedItem) {
  showDropdown.value = false;
  removeItem(selectedItem.id);
}
function removeLastItem() {
  if (!contentEditable.value.innerText) {
    computedModelValue.value = computedModelValue.value.filter((item, index) => {
      return index !== computedModelValue.value.length - 1;
    });
  }
}

function setShowDropdown() {
  showDropdown.value = true;
}
function openDropdown() {
  if (showDropdown.value) {
    searchResults.value = filteredList(contentEditable.value.innerText);
  }
}
function closeDropdown() {
  searchResults.value.length = 0;
  contentEditable.value.innerText = '';
  showDropdown.value = false;
}
function blurAndCloseDropdown() {
  closeDropdown();
  contentEditable.value.blur();
}
function focusContentEditable() {
  contentEditable.value.focus();
}

// function setDropdownItemRefs(refs) {
//   dropdownItemRefs.value = refs;
// }
// function focusFirstDropdownElement(event) {
//   useFocusFirstNode(event, dropdownItemRefs.value);
// }
// function focusLastDropdownElement(event) {
//   useFocusLastNode(event, dropdownItemRefs.value);
// }
</script>
