<template>
  <ConfirmModal
    v-if="showDeleteFileConfirmModal"
    :show="showDeleteFileConfirmModal"
    @confirm="deleteFile"
    @close="closeDeleteFileConfirmModal"
  >
    <span v-safe-html="t('modal.warning_text_when_deleting_document', { name: getFileNameString(deletedFileId) })" />
  </ConfirmModal>
  <ConfirmModal
    v-if="showDeleteFilesConfirmModal"
    :show="showDeleteFilesConfirmModal"
    @confirm="handleDeleteFiles"
    @close="closeDeleteFilesConfirmModal"
  >
    <span v-safe-html="t('modal.warning_text_when_deleting_documents', { documents: getFilesNameString() })" />
  </ConfirmModal>
  <div class="documents pt-3">
    <div
      class="file-upload-box mb-4"
      @drop.stop.prevent="onDropFileInput"
      @dragover.prevent
    >
      <div
        class="d-flex align-items-center justify-content-center flex-column h-100 cursor-pointer"
        @click.stop.prevent="$refs.fileInput.click()"
      >
        <div class="icon-upload position-relative mb-1">
          <i class="fa-regular fa-file icon-document" />
          <i class="fa-solid fa-upload icon-upload position-absolute" />
        </div>
        <div class="file-upload-box-title">{{ t('documents.click_to_upload_or_drag_and_drop') }}</div>
        <div class="file-upload-box-subtitle">{{ t('documents.maximum_file_size') }}</div>
      </div>
      <input
        ref="fileInput"
        type="file"
        multiple
        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,image/jpeg,image/bmp,image/png,image/gif"
        class="d-none"
        @change="onClickFileInput"
      />
    </div>
    <template v-if="Object.keys(filesToUpload)?.length">
      <div
        v-for="(data, key) in filesToUpload"
        :key="key"
        class="d-flex"
      >
        <FileTag
          v-if="data?.file"
          :file-key="key"
          :file-name="data.file?.name"
          allow-remove
          @remove="removeFileToUpload"
          @click="handleOpenFile(data.file)"
        />
        <div class="file-upload-category-wrapper">
          <BaseSelect
            v-model="data.category"
            :errors="errorMessages[`${key}.category`]"
            :options="categories"
            field-name="value"
          />
        </div>
      </div>
      <div class="mt-2">
        <PrimaryButton
          :loading="loadingStoreDocumentFiles"
          @click="handleStoreDocumentFiles"
        >
          {{ $t('documents.upload') }}
        </PrimaryButton>
      </div>
    </template>
    <template v-if="record.files.length">
      <div class="d-flex justify-content-between filters-container documents-filters">
        <div class="d-flex filters-wrapper">
          <div
            :class="{ active: filter === filters.allFiles }"
            @click="setFilter(filters.allFiles)"
          >
            {{ $t('documents.all_files') }}
          </div>
          <div
            :class="{ active: filter === filters.yourFiles }"
            @click="setFilter(filters.yourFiles)"
          >
            {{ $t('documents.your_files') }}
          </div>
        </div>
        <div class="overview-search-input">
          <div class="position-relative">
            <BaseInput
              v-model="searchValue"
              :placeholder="t('ui.placeholders.search')"
            />
            <i class="fa-solid fa-magnifying-glass icon-search position-absolute" />
          </div>
        </div>
      </div>
      <ResponsiveTable
        v-if="record.files"
        hoverable
        table-fixed
        class="documents-table"
      >
        <tr>
          <th
            v-for="(header, key) in headers"
            :key="key"
            class="text-truncate"
          >
            <template v-if="key === 0">
              <span class="me-3">
                <BaseCheckbox
                  v-model="selectAllFilesCheckbox"
                  @change="handleSelectAllFiles"
                />
              </span>
            </template>
            {{ header }}
          </th>
        </tr>
        <tr
          v-for="(file, key) in computedFilteredFiles"
          :key="key"
        >
          <td>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2">
                <span class="me-3">
                  <BaseCheckbox
                    :checked="selectedFileIds.includes(file.id)"
                    @change="handleSelectedFileIds(file.id)"
                  />
                </span>
                <span class="file-icon">
                  <BaseIcon :icon="`far ${getFileIcon(file.file_name)}`" />
                </span>
              </div>
              <div>
                <div class="d-flex align-items-center ms-2">
                  <span class="file-name text-truncate">
                    {{ getFileNameWithoutExtension(file.file_name) }}
                  </span>
                  <span
                    v-if="file.file_name.includes('.')"
                    class="file-extension text-truncate"
                    >.{{ getExtension(file.file_name) }}</span
                  >
                </div>
                <div class="file-size ms-2">
                  <span>
                    {{ bytesToReadableFormat().format(file.size) }}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td class="text-truncate">
            <BaseSelect
              v-model="file.category"
              :errors="updateDataErrorMessages[`${key}.category`]"
              :options="categories"
              :initial-placeholder="false"
              :disabled="file.locked"
              field-name="value"
              class="file-category"
            />
          </td>
          <td class="text-truncate file-date">
            {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(file.created_at)) }}
          </td>
          <td class="text-truncate file-date">
            {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(file.updated_at)) }}
          </td>
          <td>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-truncate file-employee">
                {{ getEmployeeName(file.employee_id) }}
              </span>
              <div class="d-flex">
                <span
                  class="icon icon-download me-1"
                  @click="handleOpenUploadedFile(file.url)"
                >
                  <i class="fa-solid fa-arrow-up-right-from-square" />
                </span>
                <span
                  class="icon icon-download me-1"
                  @click="handleDownloadFile(file)"
                >
                  <i class="fas fa-download" />
                </span>
                <span
                  class="icon icon-delete"
                  :class="{ disabled: file.locked }"
                  @click="handleDeleteFile(file)"
                >
                  <i class="far fa-trash-alt" />
                </span>
              </div>
            </div>
          </td>
        </tr>
      </ResponsiveTable>
      <div class="mt-2 mb-auto d-flex flex-column align-items-start">
        <PrimaryButton
          :disabled="!selectedFileIds.length"
          :loading="loadingDownloadDocumentFilesAsZip"
          class="mb-2 mt-3"
          @click.prevent="handleDownloadFilesAsZip"
        >
          <template v-if="selectedFileIds.length === 1">
            {{ $t('documents.download_file') }}
          </template>
          <template v-else>
            {{ $t('documents.download_files', { count: selectedFileIds.length || '' }) }}
          </template>
        </PrimaryButton>
        <PrimaryButton
          :disabled="!selectedFileIds.length"
          :loading="loadingDeleteDocumentFiles"
          @click.prevent="handleShowDeleteFilesConfirmModal"
        >
          <template v-if="selectedFileIds.length === 1">
            {{ $t('documents.remove_file') }}
          </template>
          <template v-else>
            {{ $t('documents.remove_files', { count: selectedFileIds.length || '' }) }}
          </template>
        </PrimaryButton>
      </div>
      <FormFooter
        :pending="loadingUpdateDocumentFiles"
        :disabled="_.isEqual(record, originalRecord) || loadingUpdateDocumentFiles"
        @save="handleUpdateDocumentFilesData"
        @cancel="handleCancelUpdateDocumentFiles"
      />
    </template>
  </div>
</template>

<script setup>
import FormFooter from '@/components/form-controls/FormFooter.vue';
import apiClient from '@/services/ApiClient';
import { useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import CategoriesConstants from '@/configs/constants/Categories';
import BaseSelect from '@/components/form/BaseSelect.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import { bytesToReadableFormat, toLocaleDateString } from '@/modules/formatters';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import { useAuthorizationStore } from '@/stores/authorization';
import ConfirmModal from '@/components/ConfirmModal.vue';
import FileTag from '@/components/form-controls/FileTag.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import getClassNameForExtension from 'font-awesome-filetypes';
import dayjs from 'dayjs';
import _ from 'lodash';
import axios from 'axios';
import { required } from '@vuelidate/validators';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const enumerationsStore = useEnumerationsStore();

const headers = [
  t('documents.file_name'),
  t('documents.category'),
  t('documents.upload_date'),
  t('documents.last_update'),
  t('documents.uploaded_by'),
];
const record = ref(props.initialRecord.data);
const originalRecord = ref([]);
const errorMessages = ref([]);
const updateDataErrorMessages = ref([]);
const loadingStoreDocumentFiles = ref(false);
const loadingUpdateDocumentFiles = ref(false);
const loadingDeleteDocumentFiles = ref(false);
const loadingDownloadDocumentFilesAsZip = ref(false);
const formId = nanoid();
const showDeleteFileConfirmModal = ref(false);
const showDeleteFilesConfirmModal = ref(false);
const deletedFileId = ref(null);
const filesToUpload = ref([]);
const selectedFileIds = ref([]);
const selectAllFilesCheckbox = ref(false);
const searchValue = ref(null);
const filters = ref({
  allFiles: 'all_files',
  yourFiles: 'your_files',
});
const filter = ref(filters.value.allFiles);

const uploadFileValidationRules = computed(() => {
  return getValidationRules(filesToUpload.value);
});

const updateFileDataValidationRules = computed(() => {
  return getValidationRules(record.value.files);
});

const categories = Object.entries(CategoriesConstants).map((entry) => {
  return { id: entry[0], value: entry[1] };
});

const computedFilteredFiles = computed(() => {
  if (filter.value === filters.value.allFiles) {
    const files = !searchValue.value ? record.value.files : getFileByName(searchValue.value.toLowerCase());
    return _.orderBy(files, 'created_at', 'desc');
  }

  if (filter.value === filters.value.yourFiles) {
    const employeeFiles = getEmployeeFiles(record.value.files);
    const files = !searchValue.value
      ? employeeFiles
      : employeeFiles.filter((file) => file.file_name.toLowerCase().includes(searchValue.value.toLowerCase()));
    return _.orderBy(files, 'created_at', 'desc');
  }

  return _.orderBy(record.value.files, 'created_at', 'desc');
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
});

function getValidationRules(array) {
  const data = {};

  array.forEach((fileData, key) => {
    Object.assign(data, {
      [key]: {
        category: { required },
      },
    });
  });

  return data;
}

function addFiles(files) {
  files.forEach((file) => {
    filesToUpload.value = [...filesToUpload.value, { file: file, category: null }];
  });
}
function removeFileToUpload(key) {
  filesToUpload.value.splice(key, 1);
}

function handleOpenFile(file) {
  openFile(URL.createObjectURL(file));
}
function handleOpenUploadedFile(url) {
  openFile(url);
}
async function handleDownloadFile(file) {
  try {
    await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}/file/${file.id}/download`
    );
  } catch (error) {
    useSetToast('error', t('toast.error.downloading_document') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while downloading document: ', error);
  }
}

async function handleStoreDocumentFiles() {
  const data = new FormData();
  filesToUpload.value.forEach((fileData, key) => {
    data.append(`data[${key}][file]`, fileData.file);
    data.append(`data[${key}][category]`, fileData.category);
  });

  errorMessages.value = await useVuelidateValidation(uploadFileValidationRules.value, filesToUpload.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  if (!record.value.id) {
    createDocument(data);
    return;
  }

  updateDocument(data);
}
async function handleUpdateDocumentFilesData() {
  if (_.isEqual(record.value, originalRecord.value) || loadingUpdateDocumentFiles.value) {
    return;
  }

  updateDataErrorMessages.value = await useVuelidateValidation(updateFileDataValidationRules.value, record.value.files);

  if (Object.keys(updateDataErrorMessages.value).length) {
    return;
  }

  try {
    loadingUpdateDocumentFiles.value = true;

    const recordCopy = structuredClone(record.value).files.filter((file) => {
      return originalRecord.value.files.find((originalFile) => {
        return originalFile.id === file.id && originalFile.category !== file.category;
      });
    });

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}/files`,
      null,
      {
        files: recordCopy,
      }
    );
    useSetToast('success', t('toast.success.documents_successfully_updated'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value = useSetRecord(response.data, formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.updating_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating documents: ', error);
  } finally {
    loadingUpdateDocumentFiles.value = false;
  }
}
function handleCancelUpdateDocumentFiles() {
  record.value = useSetRecord(originalRecord.value, formId);
  updateDataErrorMessages.value = [];
}

function createDocument(data) {
  try {
    loadingStoreDocumentFiles.value = true;

    const response = axios({
      method: 'post',
      url: `/api/ifapi?p=/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents`,
      baseURL: process.env.VUE_APP_EMPLOYEESAPI_BASEURL,
      data: data,
    });
    useSetToast('success', t('toast.success.documents_successfully_created'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value = useSetRecord(response.data, formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
    filesToUpload.value = [];
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.creating_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating documents: ', error);
  } finally {
    loadingStoreDocumentFiles.value = false;
  }
}
function updateDocument(data) {
  try {
    loadingStoreDocumentFiles.value = true;

    data.append('_method', 'put');
    const response = axios({
      method: 'post',
      url: `/api/ifapi?p=/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}`,
      baseURL: process.env.VUE_APP_EMPLOYEESAPI_BASEURL,
      data: data,
      // transformRequest: (data) => data,
    });
    useSetToast('success', t('toast.success.documents_successfully_updated'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    record.value = useSetRecord(response.data, formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
    filesToUpload.value = [];
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.updating_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating documents: ', error);
  } finally {
    loadingStoreDocumentFiles.value = false;
  }
}

function handleDeleteFile(file) {
  if (file.locked) {
    return;
  }
  showDeleteFileConfirmModal.value = true;
  deletedFileId.value = file.id;
}
async function deleteFile() {
  showDeleteFileConfirmModal.value = false;
  try {
    const response = await apiClient.request(
      'delete',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}/file/${deletedFileId.value}`
    );
    useSetToast('success', t('toast.success.document_successfully_deleted'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    originalRecord.value.files = originalRecord.value.files.filter((file) => file.id !== deletedFileId.value);
    record.value.files = record.value.files.filter((file) => file.id !== deletedFileId.value);
    originalRecord.value = useSetOriginalRecord(originalRecord.value, formId);
    selectedFileIds.value = [];

    updateDataErrorMessages.value = await useVuelidateValidation(
      updateFileDataValidationRules.value,
      record.value.files
    );
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.deleting_document') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while deleting document: ', error);
  } finally {
    loadingDeleteDocumentFiles.value = false;
  }
}
function handleShowDeleteFilesConfirmModal() {
  showDeleteFilesConfirmModal.value = true;
}
async function handleDeleteFiles() {
  closeDeleteFilesConfirmModal();
  try {
    loadingDeleteDocumentFiles.value = true;

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}/file`,
      null,
      {
        file_ids: selectedFileIds.value,
      }
    );
    useSetToast('success', t('toast.success.documents_successfully_deleted'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    originalRecord.value.files = originalRecord.value.files.filter((file) => !selectedFileIds.value.includes(file.id));
    record.value.files = record.value.files.filter((file) => !selectedFileIds.value.includes(file.id));
    originalRecord.value = useSetOriginalRecord(originalRecord.value, formId);
    selectedFileIds.value = [];

    updateDataErrorMessages.value = await useVuelidateValidation(
      updateFileDataValidationRules.value,
      record.value.files
    );
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.deleting_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while deleting documents: ', error);
  } finally {
    loadingDeleteDocumentFiles.value = false;
  }
}

async function handleDownloadFilesAsZip() {
  try {
    await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/documents/${record.value.id}/files/download/zip`,
      { file_ids: selectedFileIds.value }
    );
  } catch (error) {
    useSetToast('error', t('toast.error.downloading_documents') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while downloading documents: ', error);
  }
}

function handleSelectedFileIds(fileId) {
  if (selectedFileIds.value.includes(fileId)) {
    const index = selectedFileIds.value.indexOf(fileId);
    selectedFileIds.value.splice(index, 1);
    return;
  }
  selectedFileIds.value.push(fileId);
}
function handleSelectAllFiles() {
  setSelectAllFiles();
}

function getFile(fileId) {
  return record.value.files.find((file) => file.id === fileId);
}
function getFileByName(value) {
  return record.value.files.filter((file) => file.file_name.toLowerCase().includes(value.toLowerCase()));
}
function getFileIcon(fileName) {
  const extension = getExtension(fileName);
  return getClassNameForExtension(extension);
}
function getExtension(fileName) {
  return fileName.split('.').pop();
}
function getFileNameWithoutExtension(fileName) {
  return fileName.split('.').shift();
}
function getEmployeeName(employeeId) {
  return Object.entries(enumerationsStore.data.employees).find(
    (employee) => parseInt(employee[0]) === parseInt(employeeId)
  )[1];
}
function getEmployeeFiles(files) {
  return files.filter((file) => file.employee_id === authorizationStore.employee.id);
}

function getFileNameString(fileId) {
  return '<b>' + getFile(fileId).file_name + '</b>';
}
function getFilesNameString() {
  const files = _.orderBy(originalRecord.value.files, 'created_at', 'desc').filter((file) =>
    selectedFileIds.value.includes(file.id)
  );
  let string = '<br /> <br />';

  files.forEach((file, key) => {
    string += '<b>' + file.file_name + '</b>';

    if (key !== files.length - 1) {
      string += ', ';
    }
    string += '<br />';
  });

  return string;
}

function setFilter(value) {
  filter.value = value;
}
function setSelectAllFiles() {
  if (selectAllFilesCheckbox.value) {
    selectedFileIds.value = record.value.files.map((file) => {
      return file.id;
    });
    return;
  }
  selectedFileIds.value = [];
}

async function onClickFileInput(event) {
  addFiles(Object.values(event.target.files));
}
async function onDropFileInput(event) {
  addFiles(Object.values(event.dataTransfer.files));
}

function openFile(url) {
  window.open(url, '_blank', 'noopener');
}

function closeDeleteFileConfirmModal() {
  showDeleteFileConfirmModal.value = false;
}
function closeDeleteFilesConfirmModal() {
  showDeleteFilesConfirmModal.value = false;
}
</script>
