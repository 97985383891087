<template>
  <ResponsiveTable
    class="offer-required-documents-table"
    hoverable
    borderless
  >
    <tr
      v-for="(requiredDocument, key) in record.data"
      :key="key"
    >
      <td class="align-middle check">
        <div class="d-flex">
          <BaseCheckbox v-model="requiredDocument.selected" />
        </div>
      </td>
      <td
        class="align-middle required-documents-fields-wrapper"
        :class="{ 'text-muted fst-italic': !requiredDocument.selected }"
      >
        <template v-if="requiredDocument.type === 'final_annual_figures'">
          {{ splitTitle(requiredDocument.title)[0] }}
          <NumberInput
            v-model="requiredDocument.values.year"
            :errors="errorMessages[`${key}.values.year`]"
            :show-error-messages="false"
            :disabled="!requiredDocument.selected"
            text-position="center"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="requiredDocument.type === 'final_or_interim_annual_figures'">
          {{ splitTitle(requiredDocument.title)[0] }}
          <NumberInput
            v-model="requiredDocument.values.year"
            :errors="errorMessages[`${key}.values.year`]"
            :show-error-messages="false"
            :disabled="!requiredDocument.selected"
            text-position="center"
            @enter="handleSave"
          />
          {{ splitTitle(requiredDocument.title)[1] }}
        </template>

        <template v-else-if="requiredDocument.type === 'liquidity_forecast'">
          {{ splitTitle(requiredDocument.title)[0] }}
          <NumberInput
            v-model="requiredDocument.values.year"
            :errors="errorMessages[`${key}.values.year`]"
            :show-error-messages="false"
            :disabled="!requiredDocument.selected"
            text-position="center"
            @enter="handleSave"
          />
        </template>

        <template v-else>
          {{ requiredDocument.title }}
        </template>
      </td>
      <td class="align-middle action-buttons">
        <FormIconButton
          v-if="requiredDocumentIsRemovable(requiredDocument, key)"
          :disabled="!requiredDocument.selected"
          :button-style="requiredDocument.selected ? 'primary' : 'secondary'"
          button-size="sm"
          icon="fa-solid fa-minus"
          outline
          @click.prevent="deleteDuplicateRequiredDocument(key)"
        />
        <FormIconButton
          v-if="requiredDocumentIsDuplicable(requiredDocument, key)"
          :disabled="!requiredDocument.selected"
          :button-style="requiredDocument.selected ? 'primary' : 'secondary'"
          button-size="sm"
          outline
          icon="fa-solid fa-plus"
          @click.prevent="duplicateRequiredDocument(key, requiredDocument.type)"
        />
      </td>
    </tr>
  </ResponsiveTable>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import apiClient from '@/services/ApiClient';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import NumberInput from '@/components/form/NumberInput.vue';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { ref, onBeforeMount } from 'vue';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { required } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();

const requiredDocumentsValuesRecord = {
  final_annual_figures: {
    year: null,
  },
  final_or_interim_annual_figures: {
    year: null,
  },
  liquidity_forecast: {
    year: null,
  },
};

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value.data);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value.data);
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  await setErrorMessages();

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/required-documents/available`,
      null,
      { offer_required_documents: record.value.data }
    );
    useSetToast('success', t('toast.success.offer_required_documents_successfully_updated'));
    emit('save', { customerLogs: response.customer_log, offerPreview: response.offer_preview });

    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.updating_offer_required_documents') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while updating offer required document: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function splitTitle(title) {
  const titleParts = title.split(' ');
  let parsedTitleParts = [];
  let key = 0;

  titleParts.forEach((part) => {
    if (part.includes(':')) {
      key++;
      return;
    }

    parsedTitleParts[key] = key in parsedTitleParts ? `${parsedTitleParts[key]} ${part}` : `${part}`;
  });

  return parsedTitleParts;
}

function requiredDocumentIsRemovable(requiredDocument, key) {
  if (!requiredDocument.duplicable) {
    return false;
  }

  const firstDuplicableRequiredDocumentIndex = record.value.data.findIndex((document) => {
    return document.title === requiredDocument.title;
  });

  if (firstDuplicableRequiredDocumentIndex !== key) {
    return true;
  }
  return false;
}

function requiredDocumentIsDuplicable(requiredDocument, key) {
  if (requiredDocument.duplicable) {
    if (record.value.data[key - 1]?.title === requiredDocument.title) {
      return false;
    }
    return true;
  }
  return false;
}

function duplicateRequiredDocument(key, type = null) {
  const duplicate = structuredClone(record.value.data[key]);
  delete duplicate.offer_required_document_id;

  if (type) {
    duplicate.values = requiredDocumentsValuesRecord[type];
  }

  const lastDuplicableRequiredDocumentIndex = record.value.data.findLastIndex(
    (requiredDocument) => requiredDocument.title === duplicate.title
  );

  record.value.data.splice(lastDuplicableRequiredDocumentIndex + 1, 0, structuredClone(duplicate));
}

async function deleteDuplicateRequiredDocument(key) {
  record.value.data.splice(key, 1);
  await setErrorMessages();
}

async function setErrorMessages() {
  createRulesObject();
  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value.data);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value.data);
}

function createRulesObject() {
  validationRules.value = {};

  record.value.data?.forEach((certaintyItem, key) => {
    if (!certaintyItem.selected) {
      return;
    }

    if (
      certaintyItem.type === 'final_annual_figures' ||
      certaintyItem.type === 'final_or_interim_annual_figures' ||
      certaintyItem.type === 'liquidity_forecast'
    ) {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            year: { required },
          },
        },
      });
    }
  });
}
</script>
