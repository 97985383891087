<template>
  <BaseColumn class="p-0 sidebar-nav sticky-top col-sm-1">
    <SubNav class="py-3">
      <div class="sub-nav-item nav-item">
        <RouterLink
          :to="{ name: 'data' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.master_data') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'annual figures' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.annual_figures') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'debtor and creditor analyses' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.debtor_creditor_analyses') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'organogram structure' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.organogram') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'documents' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.documents') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'offer' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.offer') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'credit proposal' }"
          class="sub-nav-item-link nav-link"
        >
          {{ $t('navigation.credit_proposal') }}
        </RouterLink>
        <RouterLink
          :to="props.prospectStatus.credit_proposal?.status !== APPROVED ? '' : { name: 'sales status contract' }"
          class="sub-nav-item-link nav-link"
          :class="{ disabled: props.prospectStatus.credit_proposal?.status !== APPROVED }"
        >
          {{ $t('navigation.contract') }}
        </RouterLink>
        <template v-if="route.name === 'creditsafe search update'">
          <RouterLink
            :to="{ name: 'creditsafe search update' }"
            class="sub-nav-item-link nav-link"
          >
            {{ $t('navigation.creditsafe') }}
          </RouterLink>
        </template>
      </div>
    </SubNav>
  </BaseColumn>
</template>

<script setup>
import { APPROVED } from '@/configs/constants/Status';
import { BaseColumn, SubNav } from '@impact-factoring/impact-branding';
import { useRoute } from 'vue-router';

const props = defineProps({
  prospectStatus: {
    type: Object,
    default: () => ({}),
  },
});

const route = useRoute();
</script>
