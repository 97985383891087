// Components / Views
import LoginView from '@/views/LoginView.vue';
import LogoutView from '@/views/LogoutView.vue';
import PasswordForgottenView from '@/views/PasswordForgottenView.vue';
import PasswordResetView from '@/views/PasswordResetView.vue';
import WelcomeSetPasswordView from '@/views/WelcomeSetPasswordView.vue';
import NotFoundView from '@/views/NotFoundView.vue';

// routes
import Dashboard from '@/router/dashboard';
// import Operations from '@/router/operations';
import Sales from '@/router/sales';
import Settings from '@/router/settings';
import Intermediaries from '@/router/intermediaries';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      guest: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      guest: false,
    },
  },
  {
    path: '/password/forgotten',
    name: 'password-forgotten',
    component: PasswordForgottenView,
    meta: {
      guest: true,
    },
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: PasswordResetView,
    meta: {
      guest: true,
    },
  },
  {
    path: '/welcome/:welcome_token',
    name: 'set-password',
    component: WelcomeSetPasswordView,
    meta: {
      guest: true,
    },
  },
  { ...Dashboard },
  // { ...Operations },
  { ...Sales },
  { ...Intermediaries },
  { ...Settings },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
