<template>
  <BaseAlert
    v-for="(message, index) in app.messages"
    :key="index"
    :alert="{ message: message.message, type: message.type }"
    class="global-error-message rounded-0 m-0"
  />
</template>

<script setup>
import { useApplicationStore } from '@/stores/application';
import { BaseAlert } from '@impact-factoring/impact-branding';

const app = useApplicationStore();
</script>
