<template>
  <BaseRow class="vh-100 bg-primary bg-opacity-10">
    <BaseColumn class="align-self-center">
      <BaseCard class="login-form mx-auto border-0 shadow-sm">
        <template #body>
          <div class="text-center">
            <TheLogo class="login-form-logo mb-3" />
          </div>
          <FloatingLabelInput
            id="email"
            v-model="auth.email"
            :label-content="$t('general.email')"
          />
          <FloatingLabelInput
            id="password"
            v-model="password"
            :label-content="$t('ui.login.password')"
          />
          <FloatingLabelInput
            id="password-confirmation"
            v-model="passwordConfirmation"
            :label-content="$t('ui.login.password_confirmation')"
          />
          <div class="d-flex justify-content-between align-items-center">
            <PrimaryButton @click="submit">
              {{ $t('ui.send') }}
            </PrimaryButton>
            <AppLink
              link="login"
              class="text-end"
            >
              {{ $t('ui.password_forgotten.goto_login') }}
            </AppLink>
          </div>
          <BaseAlert
            :alert="alert"
            class="mb-0 mt-3"
          />
        </template>
      </BaseCard>
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import apiurls from '@/modules/apiurls';
import { useAuthorizationStore } from '@/stores/authorization';
import router from '@/router';
import { ref } from 'vue';
import {
  BaseRow,
  BaseColumn,
  BaseCard,
  BaseAlert,
  FloatingLabelInput,
  PrimaryButton,
  AppLink,
  TheLogo,
} from '@impact-factoring/impact-branding';

const auth = useAuthorizationStore();
const password = ref();
const passwordConfirmation = ref();
const alert = ref({ message: null });

async function submit() {
  try {
    const response = await apiClient.post(apiurls.getUrl('reset_password'), {
      email: auth.email,
      password: password.value,
      password_confirmation: passwordConfirmation.value,
      token: router.currentRoute.value.query.token,
    });
    alert.value = {
      message: response.message,
      type: 'info',
    };
  } catch (data) {
    alert.value = {
      message: data.message,
      type: 'warning',
    };
  }
}
</script>
