<template>
  <div>
    <MultiselectSearchInput
      v-model="record.countries"
      :entities="countriesKeyValue"
      :label-content="$t('address.country')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :tooltip-content="$t('tooltip.creditsafe_select_all_countries')"
      :errors="errorMessages['record.countries']"
    />
  </div>
  <div class="mb-4">
    <LabelInput
      v-model="record.regNo"
      :errors="errorMessages['company_registration_number']"
      :label-content="$t('general.company_registration_number')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :disabled="nameOrCityOrPostalCodeOrStreet || !!record.vatNo"
    />
  </div>
  <div class="mb-4">
    <LabelInput
      v-model="record.vatNo"
      :label-content="$t('general.vat_number')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :disabled="nameOrCityOrPostalCodeOrStreet || !!record.regNo"
    />
  </div>
  <LabelInput
    v-model="record.name"
    :label-content="$t('general.name')"
    :label-columns="labelColumnClasses"
    :input-columns="inputColumnClasses"
    :disabled="regNoOrVatNo"
  />
  <LabelInput
    v-model="record.postalCode"
    :label-content="$t('address.postal_code')"
    :label-columns="labelColumnClasses"
    :input-columns="inputColumnClasses"
    :tooltip-content="$t('tooltip.creditsafe_postal_code_wildcard')"
    :disabled="regNoOrVatNo"
  />
  <LabelInput
    v-model="record.city"
    :label-content="$t('address.city')"
    :label-columns="labelColumnClasses"
    :input-columns="inputColumnClasses"
    :disabled="regNoOrVatNo"
  />
  <LabelInput
    v-model="record.street"
    :label-content="$t('address.street')"
    :label-columns="labelColumnClasses"
    :input-columns="inputColumnClasses"
    :disabled="regNoOrVatNo"
  />
  <div
    v-if="errorMessages['record.all']"
    class="form-text text-danger error-messages mt-4"
  >
    {{ errorMessages['record.all'][0] }}
  </div>
  <BaseRow>
    <BaseColumn class="col-8">
      <div class="d-flex footer-secondary justify-content-end mt-4">
        <IconButton
          v-if="!props.salesStatusId"
          button-style="primary"
          :label-right="$t('customer_data.manually_create_customer')"
          outline
          class="me-3"
          @click="goToCreateCustomer"
        />
        <PrimaryButton
          :loading="loading"
          :disabled="loading"
          @click="handleSave"
        >
          {{ $t('ui.button_search') }}
        </PrimaryButton>
      </div>
    </BaseColumn>
  </BaseRow>
  <div class="text-description text-secondary mt-5">
    {{ $t('description.creditsafe_description') }}
  </div>
</template>

<script setup>
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import apiClient from '@/services/ApiClient';
import LabelInput from '@/components/form/LabelInput.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { useSetToast } from '@/composables/UseToast';
import IconButton from '@/components/buttons/IconButton.vue';
import { BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['loadCompanies', 'loading']);

const props = defineProps({
  salesStatusId: {
    type: [Number, String],
    default: null,
  },
});

const record = ref({
  countries: [],
  name: '',
  regNo: '',
  city: '',
  street: '',
  postalCode: '',
  vatNo: '',
});

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();
const labelColumnClasses = 'col-md-3 col-12';
const inputColumnClasses = 'col-md-5 col-12';
const errorMessages = ref([]);
const loading = ref(false);

const nameOrCityOrPostalCodeOrStreet = computed(() => {
  return !!(record.value.name || record.value.city || record.value.postalCode || record.value.street);
});

const regNoOrVatNo = computed(() => {
  return !!(record.value.regNo || record.value.vatNo);
});

const countriesKeyValue = computed(() => {
  if (!enums.data.countries) {
    return [];
  }
  let countries = mapCountries();
  countries = {
    PLC: t('general.all_countries'),
    ...countries,
  };
  return countries;
});

function mapCountries() {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}

function goToCreateCustomer() {
  router.push({ name: 'create customer' });
}

async function handleSave() {
  if (!validation()) {
    return;
  }

  const urlParameters = generateUrlParameters();

  try {
    loading.value = true;
    emit('loading', loading.value);
    const companies = await getCompanies(urlParameters);

    if (!companies) {
      emit('loadCompanies', {});
      loading.value = false;
      return;
    }
    emit('loadCompanies', companies);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_companies') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe companies: ', error);
  } finally {
    emit('loading', false);
    loading.value = false;
  }
}

function getCompanies(urlParameters) {
  return apiClient.request('get', '/ifapi/integrations/creditsafe/companies', {
    requestSource: urlParameters,
  });
}

function validation() {
  if (!record.value.countries.length) {
    errorMessages.value['record.countries'] = [t('validation.value_is_required')];
  } else {
    delete errorMessages.value['record.countries'];
  }

  if (!regNoOrVatNo.value && !nameOrCityOrPostalCodeOrStreet.value) {
    errorMessages.value['record.all'] = [t('validation.kvk_or_btw_or_combination_other_fields_required')];
  } else {
    delete errorMessages.value['record.all'];
  }
  if (!record.value.countries.length || (!regNoOrVatNo.value && !nameOrCityOrPostalCodeOrStreet.value)) {
    return false;
  }
  return true;
}

function generateUrlParameters() {
  let filteredParameters = filterFilledFields();
  const urlParameters = setDefaultParameters() + createUrlFromParameters(filteredParameters);
  return urlParameters;
}

function setDefaultParameters() {
  return '?pageSize=15';
}

function filterFilledFields() {
  const filteredParameters = Object.entries(record.value).filter((field) => {
    if (!field[1]?.length) {
      return false;
    }
    return true;
  });
  return filteredParameters;
}

function createUrlFromParameters(parameters) {
  let urlParameters = '';
  parameters.forEach((parameter, index) => {
    if (index !== 0) {
      urlParameters += '&';
    }
    if (parameter[0] === 'countries') {
      urlParameters += createCountriesUrl(parameter);
      return;
    }
    if (parameter[0] === 'vatNo' && parameter[1].startsWith('NL')) {
      urlParameters += parameter[0] + '=' + stripNLPrefixFromVatNo(parameter[1]);
      return;
    }

    urlParameters += parameter[0] + '=' + parameter[1];
  });
  return '&' + urlParameters;
}

function createCountriesUrl(countries) {
  let enumCountry;
  let parameterCountryUrl = countries[0] + '=';

  countries[1].forEach((country, index) => {
    enumCountry = enums.data.countries.find((enumCountry) => {
      return enumCountry.id === country.id;
    });

    if (country.id === 'PLC') {
      enumCountry = { iso: country.id };
    }

    parameterCountryUrl += enumCountry.iso;

    if (index !== countries[1].length - 1) {
      parameterCountryUrl += ',';
    }
  });
  return parameterCountryUrl;
}

function stripNLPrefixFromVatNo(string) {
  return string.replace('NL', '');
}
</script>
