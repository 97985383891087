import PageView from '@/views/Router/PageView.vue';
import CustomerDataListView from '@/views/Sales/SalesStatus/CustomerData/CustomerDataListView.vue';
import AnnualFigures from '@/router/sales/sales-status/annual-figures';
import DebtorCreditorAnalyses from '@/router/sales/sales-status/debtor-creditor-analyses';
import Organogram from '@/router/sales/sales-status/organogram';
import Documents from '@/router/sales/sales-status/documents';
import Offers from '@/router/sales/sales-status/offers';
import CreditProposal from '@/router/sales/sales-status/credit-proposal';
import Contract from '@/router/sales/sales-status/contract';
import CreditsafeView from '@/views/Sales/SalesStatus/Creditsafe/CreditsafeView.vue';
import CreateCustomerDataForm from '@/views/Sales/SalesStatus/CustomerData/CreateCustomerDataView.vue';
import CustomerData from '@/router/sales/sales-status/customer-data';
import ProspectView from '@/views/Router/ProspectView.vue';

export default {
  path: 'customers',
  meta: {
    breadCrumbLabel: 'customers',
  },
  children: [
    {
      path: '',
      name: 'list sales statuses',
      component: CustomerDataListView,
      meta: {
        breadCrumbLabel: 'customers',
        permission: 'todo',
      },
    },
    {
      path: ':sales_status_id/creditsafe',
      name: 'creditsafe pageview update',
      component: PageView,
      meta: {
        breadCrumbLabel: 'creditsafe',
        permission: 'todo',
      },
      children: [
        {
          path: 'search',
          name: 'creditsafe search update',
          component: CreditsafeView,
          props: true,
          meta: {
            breadCrumbLabel: 'creditsafe',
            permission: 'todo',
          },
        },
      ],
    },
    {
      path: 'creditsafe',
      name: 'creditsafe pageview create',
      component: PageView,
      redirect: { name: 'creditsafe search create' },
      meta: {
        breadCrumbLabel: 'creditsafe',
        menutype: 'page',
        permission: 'todo',
      },
      children: [
        {
          path: 'search',
          name: 'creditsafe search create',
          component: CreditsafeView,
          meta: {
            breadCrumbLabel: 'creditsafe',
            permission: 'todo',
          },
        },
      ],
    },
    {
      path: 'create',
      name: 'create customer',
      component: PageView,
      redirect: { name: 'create data' },
      meta: {
        newEntity: true,
        breadCrumbLabel: 'customer',
        menutype: 'page',
        permission: 'todo',
      },
      children: [
        {
          path: 'data',
          name: 'create data',
          component: CreateCustomerDataForm,
          meta: {
            breadCrumbLabel: 'master_data',
            permission: 'todo',
          },
        },
      ],
    },
    {
      path: ':sales_status_id',
      name: 'edit sales status',
      component: ProspectView,
      redirect: { name: 'data' },
      meta: {
        breadCrumbLabel: 'customer',
        hasCustomBreadCrumbLabel: true,
        menutype: 'prospect view',
        permission: 'todo',
      },
      children: [
        { ...CustomerData },
        { ...AnnualFigures },
        { ...DebtorCreditorAnalyses },
        { ...Organogram },
        { ...Documents },
        { ...Offers },
        { ...CreditProposal },
        { ...Contract },
      ],
    },
  ],
};
