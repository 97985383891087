import { useAuthorizationStore } from '@/stores/authorization';
import { RESET } from '@/configs/constants/Status';
import PersonSalutationConstants from '@/configs/constants/PersonSalutations';
import { ref } from 'vue';

const company = ref({});
const logbook = ref({});
const prospectStatus = ref({});
const offerPreview = ref(null);
const creditProposalPreview = ref(null);

export function useSetCompanyRef(companyRef) {
  company.value = companyRef.value;
}
export function useSetLogbookRef(logbookRef) {
  logbook.value = logbookRef.value;
}
export function useSetProspectStatusRef(prospectStatusRef) {
  prospectStatus.value = prospectStatusRef.value;
}

export function useSetCompanyName(string) {
  company.value.name_plus_legal_form = string;
}
export function useSetCompanyContactPersons(array) {
  company.value.contact_persons = array;
}
export function useSetLogbookItems(object) {
  logbook.value.sales_status.customer_log = object;
}
export function useSetProspectCreditProposalStatus(object) {
  prospectStatus.value.credit_proposal = object;
}
export function useSetOfferPreview(string) {
  offerPreview.value = { offer: string };
}
export function useSetCreditProposalPreview(string) {
  creditProposalPreview.value = { creditProposal: string };
}

export function useGetCompany() {
  return company.value;
}
export function useGetOfferPreview() {
  return offerPreview.value?.offer;
}
export function useGetCreditProposalPreview() {
  return creditProposalPreview.value?.creditProposal;
}
export function useGetProspectCreditProposalOwner() {
  return prospectStatus.value?.credit_proposal?.owner;
}
export function useGetProspectCreditProposalStatus() {
  return prospectStatus.value?.credit_proposal?.status;
}

export function useIsOwner(evaluations) {
  if (!evaluations || !Object.keys(evaluations)?.length) {
    return null;
  }
  return !!evaluations.find(
    (evaluation) =>
      evaluation.owner && evaluation.employee_id === useAuthorizationStore().employee.id && evaluation.status !== RESET
  );
}

export function useMapContactPersons(contactPersons) {
  if (!contactPersons?.length || !contactPersons[0]?.person?.id) {
    return [];
  }

  return contactPersons
    .map((contactPerson) => {
      let person = { id: contactPerson.person.id, name: contactPerson.person.name };

      const duplicateName = contactPersons.filter((contactPerson) => contactPerson.person.name === person.name);

      if (duplicateName.length > 1) {
        person = {
          ...person,
          uniqueIdentifier:
            contactPerson.contact_detail.email_address ||
            contactPerson.contact_detail.phone_primair ||
            contactPerson.person.first_name ||
            PersonSalutationConstants[contactPerson.person.salutation],
        };
      }

      return person;
    })
    .filter((contactPerson) => !!contactPerson?.id);
}
