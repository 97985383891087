import BaseStore from '@/store/BaseStore';
import api from '@/services/ApiClient';
import router from '@/router';

const store = new BaseStore(['address', 'contact_types', 'contact_detail', 'business_function'], ['contact_detail']);

const ContactPersonsStore = {
  namespaced: store.namespaced,
  state: { ...store.state },
  mutations: { ...store.mutations },
  actions: {
    ...store.actions,
    async fetchListSignerPersons({ commit }, params = {}) {
      const url = `/ifapi/clients/${router.currentRoute.value.params.client_id}/signerpersons`;
      params.include = ['address'];
      const data = await api.request('get', url, params);
      commit('SET_LIST', data);

      return data;
    },
  },
  getters: {
    ...store.getters,
    getCompetentSigners(state) {
      if (state.list.data === undefined) {
        return {};
      }

      let competentSigners = {};
      state.list.data.forEach((person) => {
        competentSigners[person.id] = { ...person };
      });

      return competentSigners;
    },
  },
};

export { ContactPersonsStore };
