<template>
  <div
    ref="dropdown"
    class="search-input-dropdown"
  >
    <div
      v-for="(item, index) in computedData"
      :key="index"
      tabindex="0"
      class="text-truncate item"
      :class="{ 'bg-selected': itemIsSelected(item) }"
      @click="handleClickedItem(item)"
      @keydown.enter="handleClickedItem(item)"
    >
      <div>{{ item.name }}</div>
      <div
        v-if="item.uniqueIdentifier"
        class="identifier"
      >
        {{ item.uniqueIdentifier }}
      </div>
    </div>
  </div>
</template>

<script setup>
// import { useFocusItem, useGoDown, useGoUp } from '@/composables/UseHotkeyNodeSelection';
import { computed, ref } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  initialSelectedItem: {
    type: [String, Number],
    default: '',
  },
});
const emit = defineEmits(['clickedItem', 'loadDropdownRefs']);

const selectedItem = computed(() => {
  return props.initialSelectedItem;
});
const dropdown = ref(null);

const computedData = computed(() => {
  // if (dropdown.value?.children) {
  //   emit('loadDropdownRefs', dropdown.value.children);
  // }
  return props.data;
});

function handleClickedItem(item) {
  emit('clickedItem', item);
}

// function handleHoveredItem(event) {
//   useFocusItem(event);
// }
// function goUp(event) {
//   useGoUp(event, dropdown.value.children);
// }
// function goDown(event) {
//   useGoDown(event, dropdown.value.children);
// }

function itemIsSelected(item) {
  // todo probably redundant if itemId is being sent? what if id is not found and uniqueIdentifier is not unique?
  // return selectedItem.value && (selectedItem.value === item.id || selectedItem.value === item.uniqueIdentifier);
  return selectedItem.value && selectedItem.value === item.id;
}
</script>
