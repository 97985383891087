<template>
  <BaseSpinner
    v-if="!form.entities?.user"
    type="primary"
  />
  <template v-else>
    <BaseAlert
      v-show="form.entities.user.banned"
      :alert="{
        type: 'danger',
        message: $t('general.is_blocked', [form.originals.user.name]),
      }"
    />
    <div
      v-if="form.entities.user.id"
      class="action-bar mb-4"
    >
      <WelcomeEmail
        :user="form.entities.user"
        class="me-2"
      />
      <BanUser
        :store="form"
        :user="form.entities.user"
      />
      <div
        v-if="form.entities.user.welcome_email_send_at"
        class="mt-2 text-muted"
      >
        {{ $t('general.welcome_email_sent_at') }}
        <DateFormat
          :date="form.entities.user.welcome_email_send_at"
          format="simpleDateTime"
        />
      </div>
    </div>
    <StoreForm
      :rules="rules"
      :config="props.config"
    >
      <LabelInput
        v-model="form.entities.user.name"
        :label-content="$t('general.name')"
        :errors="form.errors?.user.name"
      />
      <LabelInput
        v-model="form.entities.user.email"
        :label-content="$t('general.email')"
        :errors="form.errors?.user.email"
        required
      />
      <EnumCheck
        v-model="form.entities.user.roles.data"
        enum-name="roles"
        enum-url="/api/settings/authorization/roles"
        :label="$t('roles_permissions.roles')"
        :modal-title="$t('roles_permissions.roles')"
      />
      <EnumCheck
        v-model="form.entities.user.permissions.data"
        enum-name="permissions"
        enum-url="/api/settings/authorization/permissions"
        :label="$t('roles_permissions.extra_permissions')"
        :modal-title="$t('roles_permissions.extra_permissions')"
      />
    </StoreForm>
  </template>
</template>

<script setup>
import StoreForm from '@/components/StoreForm.vue';
import BanUser from '@/components/users/BanUser.vue';
import DateFormat from '@/components/DateFormat.vue';
import WelcomeEmail from '@/components/users/WelcomeEmail.vue';
import EnumCheck from '@/components/form-controls/EnumCheck.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import { useEntityformStore } from '@/stores/entityform';
import { BaseAlert, BaseSpinner } from '@impact-factoring/impact-branding';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
});

const form = useEntityformStore();

const rules = computed(() => {
  return {
    name: { required },
    email: { required },
  };
});
</script>
