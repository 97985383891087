<template>
  <ConfirmModal
    v-if="showDeleteIntermediaryConfirmModal"
    :show="showDeleteIntermediaryConfirmModal"
    :message="t('modal.warning_text_when_deleting_intermediary')"
    @confirm="handleConfirmDeleteIntermediary"
    @close="closeConfirmDeleteIntermediaryModal"
  />
  <div class="row intermediary-form pt-4 mt-3 px-5 mx-4">
    <div class="col-3">
      <div class="row">
        <div class="col-10">
          <div class="action-bar d-flex justify-content-between align-items-center mb-3">
            <div class="intermediary-title pt-2 mb-2">
              {{ $t('modal.person') }}
            </div>
            <template v-if="!record.companies.length">
              <ActionAddButton
                :label="$t('general.company')"
                size="sm"
                @click="addCompany"
              />
            </template>
          </div>
        </div>
      </div>
      <LabelSelect
        v-model="record.person.salutation"
        :options="personSalutations"
        :errors="errorMessages['person.salutation']"
        field-name="value"
        :label-content="$t('contact_persons.salutation')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
      />
      <LabelInput
        v-model="record.person.initials"
        :errors="errorMessages['person.initials']"
        :label-content="$t('contact_persons.initials')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.person.first_name"
        :errors="errorMessages['person.first_name']"
        :label-content="$t('contact_persons.first_name')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.person.last_name"
        :errors="errorMessages['person.last_name']"
        :label-content="$t('contact_persons.last_name')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.person.contact_detail.phone_primair"
        :errors="errorMessages['person.contact_detail.phone_primair']"
        :label-content="$t('general.mobile')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.person.contact_detail.phone_secundair"
        :errors="errorMessages['person.contact_detail.phone_secundair']"
        :label-content="$t('general.phone')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.person.contact_detail.email_address"
        :errors="errorMessages['person.contact_detail.email_address']"
        :label-content="$t('general.email')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <BaseRow v-if="!record.companies.length">
        <BaseColumn class="col-md-10 col-12">
          <FormFooter
            :pending="loading"
            :disabled="_.isEqual(record, originalRecord) || loading"
            class="footer-secondary"
            @save="handleSave"
            @cancel="handleCancel"
          >
            <template #bodyStart>
              <BaseButton
                v-if="record.id"
                button-style="danger"
                class="me-auto"
                @click="openConfirmDeleteIntermediaryModal"
              >
                {{ $t('ui.remove') }}
              </BaseButton>
            </template>
          </FormFooter>
        </BaseColumn>
      </BaseRow>
    </div>
    <div
      v-for="(company, key) in record.companies"
      :key="key"
      class="col-3"
    >
      <div class="col-md-10 col-12">
        <div class="action-bar d-flex justify-content-between align-items-center mb-3 pe-1">
          <div class="intermediary-title pt-2 mb-2">
            {{ $t('general.company') }}
            <BaseIcon
              icon="fa-regular fa-trash-can"
              class="cursor-pointer px-1 py-2"
              @click="removeCompany(key)"
            />
            <BaseSpinner
              v-if="loadingGetCompany && currentSelectedCompanyKey === key"
              type="primary"
              small
              class="ms-1"
            />
          </div>
          <template v-if="key === 0">
            <ActionAddButton
              :label="$t('general.company')"
              size="sm"
              @click="addCompany"
            />
          </template>
        </div>
      </div>
      <SearchInput
        v-model="company.name"
        :entities="getCompaniesKeyValue(company.id)"
        :errors="errorMessages[`companies.${key}.name`]"
        :disallow-custom-entry="false"
        :item-key="key"
        :label-content="$t('general.company_name')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @select-item="handleSetCompanyRecord"
      />
      <LabelInput
        v-model="company.address.address"
        :label-content="$t('address.address_information')"
        :errors="errorMessages[`companies.${key}.address.address`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="company.address.postal_code"
        :label-content="$t('address.postal_code')"
        :errors="errorMessages[`companies.${key}.address.postal_code`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="company.address.city"
        :label-content="$t('address.city')"
        :errors="errorMessages[`companies.${key}.address.city`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <SearchInput
        v-model="company.address.country_id"
        field-name="id"
        :entities="getCountries()"
        :errors="errorMessages[`companies.${key}.address.country_id`]"
        :label-content="$t('address.country')"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
      />
      <LabelInput
        v-model="company.website"
        :label-content="$t('general.website')"
        :errors="errorMessages[`companies.${key}.website`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <CompanyRegistrationNumberNumberInput
        v-model="company.company_registration_number"
        :label-content="$t('general.company_registration_number')"
        :errors="errorMessages[`companies.${key}.company_registration_number`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <VatNumberInput
        v-model="company.vat_number"
        :label-content="$t('general.vat_number')"
        :errors="errorMessages[`companies.${key}.vat_number`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="company.pivot.iban"
        :label-content="$t('intermediary.iban')"
        :errors="errorMessages[`companies.${key}.pivot.iban`]"
        :label-columns="personAndCompanyLabelColumnClasses"
        :input-columns="personAndCompanyInputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <PercentageInput
        v-model="company.pivot.factoring_provision_percentage"
        :label="$t('intermediary.factoring_provision_percentage')"
        :errors="errorMessages[`companies.${key}.pivot.factoring_provision_percentage`]"
        :label-columns="companyLabelColumnClasses"
        :input-columns="companyInputColumnClasses"
        :decimals="2"
        @enter="handleSave"
      />
      <PercentageInput
        v-model="company.pivot.interest_provision_percentage"
        :label="$t('intermediary.interest_provision_percentage')"
        :errors="errorMessages[`companies.${key}.pivot.interest_provision_percentage`]"
        :label-columns="companyLabelColumnClasses"
        :input-columns="companyInputColumnClasses"
        :decimals="2"
        @enter="handleSave"
      />
      <LabelToggleSwitch
        v-model="company.pivot.credit_insurance"
        :errors="errorMessages[`companies.${key}.pivot.credit_insurance`]"
        :label-content="$t('intermediary.credit_insurance')"
        :label-columns="companyLabelColumnClasses"
        :input-columns="companyInputColumnClasses"
      />
      <BaseRow v-if="key === record.companies.length - 1">
        <BaseColumn class="col-md-10 col-12">
          <FormFooter
            :pending="loading"
            :disabled="_.isEqual(record, originalRecord) || loading"
            class="footer-secondary"
            @save="handleSave"
            @cancel="handleCancel"
          >
            <template #bodyStart>
              <BaseButton
                v-if="record.id"
                button-style="danger"
                class="me-auto"
                @click="openConfirmDeleteIntermediaryModal"
              >
                {{ $t('ui.remove') }}
              </BaseButton>
            </template>
          </FormFooter>
        </BaseColumn>
      </BaseRow>
    </div>
    <slot name="prospects" />
    <slot name="notes" />
  </div>
</template>

<script setup>
import FormFooter from '@/components/form-controls/FormFooter.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import { useBeforeRouteLeave, useRemoveFormId, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import LabelInput from '@/components/form/LabelInput.vue';
import PersonSalutationConstants from '@/configs/constants/PersonSalutations';
import { useEnumerationsStore } from '@/stores/enumerations';
import LabelToggleSwitch from '@/components/form/LabelToggleSwitch.vue';
import PercentageInput from '@/components/form-controls/PercentageInput.vue';
import VatNumberInput from '@/components/form-controls/VatNumberInput.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import { INTERMEDIARY } from '@/configs/constants/CompanyTypes';
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import BaseButton from '@/components/buttons/BaseButton.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { BaseSpinner, BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { ref, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { email, helpers, required, requiredIf } from '@vuelidate/validators';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const enums = useEnumerationsStore();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref(getValidationRules());
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const loadingGetCompany = ref(false);
const currentSelectedCompanyKey = ref(null);
const showDeleteIntermediaryConfirmModal = ref(false);

const personAndCompanyLabelColumnClasses = 'col-md-4 col-12';
const personAndCompanyInputColumnClasses = 'col-md-6 col-12';

const companyLabelColumnClasses = 'col-md-5 col-12';
const companyInputColumnClasses = 'col-md-5 col-12';

const personSalutations = Object.entries(PersonSalutationConstants).map((entry) => {
  return { id: entry[0], value: entry[1] };
});

onBeforeMount(async () => {
  useBeforeRouteLeave();

  record.value.companies = mapCompanies(record.value.companies);
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  await setErrorMessages();
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  await setErrorMessages();

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;

    const response = await apiClient.request('put', `/ifapi/intermediaries/${record.value.id}`, null, record.value);

    useSetToast('success', t('toast.success.intermediary_successfully_updated'));
    response.companies = mapCompanies(response.companies);
    response.companies.forEach((company) => findAndAddCompanyEnum(company));
    findAndAddIntermediaryEnum(response);

    record.value = useSetRecord(response, formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.updating_intermediary') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating intermediary: ', error);
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function openConfirmDeleteIntermediaryModal() {
  showDeleteIntermediaryConfirmModal.value = true;
}
function closeConfirmDeleteIntermediaryModal() {
  showDeleteIntermediaryConfirmModal.value = false;
}

async function handleConfirmDeleteIntermediary() {
  try {
    await apiClient.request('delete', `/ifapi/intermediaries/${record.value.id}`);
    useSetToast('success', t('toast.success.intermediary_successfully_deleted'));

    enums.removeEnum('intermediaries', record.value.id);
    useRemoveFormId(formId);
    await router.push({ name: 'list intermediaries' });
  } catch (error) {
    useSetToast('error', t('toast.error.deleting_intermediary') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while deleting intermediary: ', error);
  }
}

function getValidationRules() {
  let validationRules = {
    person: {
      last_name: { required },
      contact_detail: {
        email_address: {
          email,
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.phone_primair && !record.value.person.contact_detail.phone_secundair
              );
            })
          ),
        },
        phone_primair: {
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.email_address && !record.value.person.contact_detail.phone_secundair
              );
            })
          ),
        },
        phone_secundair: {
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.phone_primair && !record.value.person.contact_detail.email_address
              );
            })
          ),
        },
      },
    },
    companies: [],
  };

  const validationRule = {
    name: { required },
  };

  record.value.companies.forEach(() => {
    validationRules.companies = [...validationRules.companies, validationRule];
  });

  return validationRules;
}

function getCompaniesKeyValue(companyId) {
  return Object.values(enums.data.companies)
    .filter(
      (company) =>
        (company.type === INTERMEDIARY &&
          company.id !== record.value.companies.find((findCompany) => findCompany.id === company.id)?.id) ||
        company.id === companyId
    )
    .map((company) => {
      let companyObject = {
        id: company.id,
        name: company.name,
      };

      const duplicateName = Object.values(enums.data.companies).filter(
        (filterCompany) => filterCompany.name === company.name
      );

      if (duplicateName.length > 1) {
        companyObject = {
          ...companyObject,
          uniqueIdentifier: company.company_registration_number,
        };
      }
      return companyObject;
    });
}

function mapCompany(company) {
  return {
    id: company.id || null,
    type: company.type || null,
    name: company.name,
    name_plus_legal_form: company.name_plus_legal_form,
    company_registration_number: company.company_registration_number,
    vat_number: company.vat_number,
    website: company.website,
    address: {
      id: company.address.id,
      country_id: company.address.country_id,
      address: company.address.address,
      postal_code: company.address.postal_code,
      city: company.address.city,
    },
    pivot: {
      id: company?.pivot?.id || null,
      iban: company?.pivot?.iban || null,
      credit_insurance: !!company?.pivot?.credit_insurance || false,
      factoring_provision_percentage: company?.pivot?.factoring_provision_percentage || null,
      interest_provision_percentage: company?.pivot?.interest_provision_percentage || null,
    },
  };
}

function mapCompanies(companies) {
  const mappedCompanies = companies.map((company) => {
    return mapCompany(company);
  });

  return _.orderBy(mappedCompanies, 'pivot.id', 'asc');
}

function mapCountries() {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}

function getCountries() {
  if (!enums.data.countries) {
    return [];
  }
  let countries = mapCountries();
  countries = {
    PLC: t('general.all_countries'),
    ...countries,
  };
  return countries;
}

async function handleSetCompanyRecord(companyId, key) {
  currentSelectedCompanyKey.value = key;
  if (!companyId || typeof companyId === 'string') {
    record.value.companies[key].id = null;
    return;
  }

  try {
    loadingGetCompany.value = true;
    const response = await getCompany(companyId);
    if (Object.keys(response)) {
      record.value.companies[key] = mapCompany(response);
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_company') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching company: ', error);
  } finally {
    loadingGetCompany.value = false;
  }
}

async function getCompany(companyId) {
  return await apiClient.request('get', `/ifapi/intermediaries/${route.params.id}/company/${companyId}`);
}

function findAndAddCompanyEnum(company) {
  const companyEnum = {
    id: company.id,
    type: company.type,
    name: company.name,
    company_registration_number: company.company_registration_number,
  };

  enums.addOrUpdateEnumObject('companies', companyEnum);
}

function findAndAddIntermediaryEnum(response) {
  const companies = response.companies.map((company) => {
    return {
      id: company.id,
      name: company.name,
      company_registration_number: company.company_registration_number,
    };
  });

  const intermediaryEnum = {
    id: response.id,
    salutation: response.person.salutation,
    initials: response.person.initials,
    first_name: response.person.first_name,
    last_name: response.person.last_name,
    phone_primair: response.person.contact_detail.phone_primair,
    phone_secundair: response.person.contact_detail.phone_secundair,
    email_address: response.person.contact_detail.email_address,
    companies: companies,
  };

  enums.addOrUpdateEnumObject('intermediaries', intermediaryEnum);
}

function addCompany() {
  record.value.companies = [...record.value.companies, getEmptyCompanyRecord()];
  validationRules.value = setValidationRules();
}

function removeCompany(key) {
  record.value.companies = record.value.companies.filter((company, companyKey) => companyKey !== key);
  validationRules.value = setValidationRules();
}

function getEmptyCompanyRecord() {
  return {
    id: null,
    type: INTERMEDIARY,
    name: null,
    company_registration_number: null,
    vat_number: null,
    website: null,
    address: {
      id: null,
      country_id: null,
      address: null,
      postal_code: null,
      city: null,
    },
    pivot: {
      iban: null,
      credit_insurance: false,
      factoring_provision_percentage: null,
      interest_provision_percentage: null,
    },
  };
}

async function setErrorMessages() {
  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
}

function setValidationRules() {
  return useMergeDefaultVuelidateValidationRules(getValidationRules(), record.value);
}
</script>
