import dayjs from 'dayjs';

export function entitySelect(value) {
  const valid = value > 0;

  return {
    $valid: valid,
    $message: 'Gekozen waarde is niet geldig',
  };
}

export function date(value) {
  const valid = dayjs(value).isValid();

  return {
    $valid: valid,
    $message: 'Datum is niet geldig',
  };
}

export function contractPeriods(value) {
  const valid = value >= 1 && value <= 4;

  return {
    $valid: valid,
    $message: 'Periode is niet geldig',
  };
}

export function termPayment(value) {
  const valid = [30, 45, 60, 90].indexOf(parseInt(value)) > -1;

  return {
    $valid: valid,
    $message: 'Termijn is niet geldig',
  };
}

export function noticePeriods(value) {
  const valid = value >= 2 && value <= 3;

  return {
    $valid: valid,
    $message: 'Periode is niet geldig',
  };
}

export function number(value) {
  const regex = new RegExp('[0-9]*', 'g');
  const valid = regex.test(value);

  return {
    $valid: valid,
    $message: 'Getal is niet geldig',
  };
}

export function boolean(value) {
  const valid = [1, 0, true, false, 'true', 'false', '1', '0'].indexOf(value) > -1;

  return {
    $valid: valid,
    $message: 'Geen geldige ja/nee waarde',
  };
}

export function currency(value) {
  const valid = ['EUR', 'USD'].indexOf(value) > -1;

  return {
    $valid: valid,
    $message: 'Geen geldige munteenheid',
  };
}

export function numericPrecision(value) {
  const valid = !isNaN(parseFloat(value));

  return {
    $valid: valid,
    $message: 'Geen geldige waarde',
  };
}
