<template>
  <BaseRow class="vh-100 bg-primary bg-opacity-10">
    <BaseColumn class="align-self-center">
      <BaseCard class="login-form mx-auto border-0 shadow-sm">
        <template #body>
          <div class="text-center">
            <TheLogo class="login-form-logo mb-3" />
          </div>
          <div v-if="name">
            <h5>Welkom {{ name }}</h5>
            <p>Om te kunnen beginnen vragen we je eerst een wachtwoord te maken.</p>
            <FloatingLabelInput
              id="password"
              v-model="password"
              :label-content="label.password"
            />
            <FloatingLabelInput
              id="password-confirmation"
              v-model="passwordConfirmation"
              :label-content="label.passwordConfirmation"
            />
            <div class="d-flex justify-content-between align-items-center">
              <PrimaryButton @click="submit">
                {{ label.submit }}
              </PrimaryButton>
            </div>
          </div>
          <BaseAlert
            :alert="alert"
            class="mb-0 mt-3"
          />
        </template>
      </BaseCard>
    </BaseColumn>
  </BaseRow>
</template>

<script>
import router from '@/router';
import api from '@/services/ApiClient';
import {
  BaseRow,
  BaseColumn,
  BaseCard,
  BaseAlert,
  FloatingLabelInput,
  PrimaryButton,
  TheLogo,
} from '@impact-factoring/impact-branding';
// import store from '@/store';

export default {
  name: 'WelcomeSetPasswordView',
  components: {
    BaseRow,
    BaseColumn,
    BaseCard,
    BaseAlert,
    FloatingLabelInput,
    PrimaryButton,
    TheLogo,
  },
  data() {
    return {
      name: null,
      password: null,
      passwordConfirmation: null,
      url: {
        login: 'login',
      },
      label: {
        submit: 'Verzenden',
        email: 'E-mailadres',
        password: 'Wachtwoord',
        passwordConfirmation: 'Wachtwoord herhalen',
      },
      alert: {
        message: null,
      },
    };
  },
  async created() {
    try {
      const response = await api.get('/welcome/' + router.currentRoute.value.params.welcome_token);
      this.name = response.name;
      // await store.dispatch('AuthStore/setEmail', response.email);
    } catch ({ response: { data } }) {
      this.alert = {
        message: data.message,
        type: 'warning',
      };
    }
  },
  methods: {
    async submit() {
      try {
        const response = await api.postWithCsrf('/welcome', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          welcome_token: this.$route.params.welcome_token,
        });
        this.alert = {
          message: response.data.message,
          type: 'info',
          link: response.data.link,
        };
      } catch (error) {
        this.alert = {
          message: error.response.data.message,
          type: 'warning',
          link: error.response.data.link,
        };
      }
    },
  },
};
</script>
