import TabsView from '@/views/Router/TabsView';
import ComingSoonView from '@/views/Router/ComingSoonView.vue';
import OrganogramView from '@/views/Sales/SalesStatus/Organogram/OrganogramView.vue';

export default {
  path: 'organogram',
  name: 'organogram',
  component: TabsView,
  redirect: { name: 'organogram structure' },
  meta: {
    breadCrumbLabel: 'organogram',
    menutype: 'tabs',
    permission: 'todo',
  },
  children: [
    {
      path: 'structure',
      name: 'organogram structure',
      component: OrganogramView,
      meta: {
        breadCrumbLabel: 'company_structure',
        menutype: 'tab',
        permission: 'todo',
      },
    },
    {
      path: 'shareholders-structure',
      name: 'organogram shareholders structure',
      component: ComingSoonView,
      meta: {
        breadCrumbLabel: 'company_shareholders_structure',
        menutype: 'tab',
        permission: 'todo',
      },
    },
  ],
};
