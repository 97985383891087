import { useAuthorizationStore } from '@/stores/authorization';
import DOMPurify from 'dompurify';

function sanitizeHtml(el, binding) {
  el.innerHTML = DOMPurify.sanitize(binding.value, {
    FORCE_BODY: true,
  });
}

function hideNonEditableElement(el, binding) {
  if (!useAuthorizationStore().can(binding.value)) {
    el.classList.add('d-none');
  }
}

function disableNonEditableInputs(el, binding) {
  if (!useAuthorizationStore().can(binding.value)) {
    for (const element of el.querySelectorAll('input, select, textarea')) {
      element.setAttribute('disabled', 'disabled');
    }
  }
}

export { sanitizeHtml, hideNonEditableElement, disableNonEditableInputs };
