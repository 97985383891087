import api from '@/services/ApiClient';

export default class {
  constructor(includeList, includeEntity) {
    this.namespaced = true;
    this.state = {
      list: [],
      entity: {},
      original: {},
    };

    this.mutations = {
      SET_LIST(state, payload) {
        state.list = payload;
      },
      SET_ENTITY(state, payload) {
        state.entity = payload;
      },
      SET_ORIGINAL(state, payload) {
        state.original = payload;
      },
    };

    this.actions = {
      async fetchList({ commit }, url) {
        const data = await api.request('get', url, { include: includeList });
        if (data !== undefined) {
          commit('SET_LIST', data);
        }
        return data;
      },
      async fetchEntity({ commit }, url) {
        if (typeof url === 'object') {
          includeEntity = url.include;
          url = url.url;
        }
        const data = await api.request('get', url, { include: includeEntity });
        if (data !== undefined) {
          commit('SET_ENTITY', data);
          commit('SET_ORIGINAL', JSON.parse(JSON.stringify(data)));
        }
        return data;
      },
      async save({ commit }, config) {
        let data;
        config.payload.id
          ? (data = await api.request(
              config.method ? config.method : 'put',
              `${config.url}${config.payload.id}`,
              { include: includeEntity },
              config.payload
            ))
          : (data = await api.request(
              config.method ? config.method : 'post',
              config.url,
              { include: includeEntity },
              config.payload
            ));
        if (data !== undefined) {
          commit('SET_ENTITY', data);
          commit('SET_ORIGINAL', JSON.parse(JSON.stringify(data)));
        }
        return data;
      },
      async patch({ commit }, url, payload) {
        let data = await api.request('patch', url, payload);
        if (data !== undefined) {
          commit('SET_ENTITY', data);
        }
        return data;
      },
      async resetEntity({ commit }, data) {
        commit('SET_ENTITY', data);
        commit('SET_ORIGINAL', data);
      },
      async resetList({ commit }, data) {
        commit('SET_LIST', data);
      },
      async setEntity({ commit }, data) {
        commit('SET_ENTITY', JSON.parse(JSON.stringify(data)));
      },
      async cancelEntity({ commit, state }) {
        commit('SET_ENTITY', JSON.parse(JSON.stringify(state.original)));
      },
    };
  }
}
