<template>
  <ViewSpacing>
    <BaseSpinner
      v-if="!form.entities?.role"
      type="primary"
    />
    <StoreForm
      v-else
      :rules="rules"
      :config="config"
    >
      <LabelInput
        v-model="form.entities.role.name"
        :label-content="$t('general.name')"
        :errors="form.errors?.role.name"
      />
      <EnumCheck
        v-model="form.entities.role.permissions.data"
        enum-name="permissions"
        enum-url="/api/settings/authorization/permissions"
        :label="$t('roles_permissions.extra_permissions')"
        :modal-title="$t('roles_permissions.extra_permissions')"
      />
    </StoreForm>
  </ViewSpacing>
</template>

<script setup>
import ViewSpacing from '@/components/grid/ViewSpacing.vue';
import StoreForm from '@/components/StoreForm.vue';
import EnumCheck from '@/components/form-controls/EnumCheck.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import { useEntityformStore } from '@/stores/entityform';
import { useApplicationStore } from '@/stores/application';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, onMounted } from 'vue';
import { required } from '@vuelidate/validators';

const form = useEntityformStore();
const config = {
  name: 'role',
  include: ['permissions'],
};

const rules = computed(() => {
  return {
    name: { required },
  };
});

onMounted(async () => {
  try {
    await form.load(config);
  } catch (error) {
    useApplicationStore().warning(error);
  }
});
</script>
