<template>
  <div class="w-100 h-100 position-relative">
    <div
      ref="chartContainer"
      class="w-100 h-100 d-flex"
      :class="{ 'chart-container-fullscreen': isFullScreen }"
    >
      <BaseModal
        v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
        :show="showConfirmCreditProposalDataModal"
        :modal-title="$t('ui.confirm')"
        size="sm"
        @confirm="closeCreditProposalModal"
        @close="closeCreditProposalModal"
      >
        <template v-if="useGetProspectCreditProposalStatus() !== APPROVED">
          {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
        </template>
        <template v-else>
          {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_accepted_without_signed_contract') }}
        </template>
      </BaseModal>
      <ConfirmModal
        v-if="showDeleteNodesConfirmModal"
        :show="showDeleteNodesConfirmModal"
        @confirm="handleRemoveNode"
        @close="closeDeleteNodesConfirmModal"
      >
        <span
          v-if="Object.keys(removedNodeIds).length > 1"
          v-safe-html="
            t('modal.warning_text_when_deleting_cards', {
              currentCard: `<b>${currentNode.name}</b>`,
              cards: getRemovedNodeNamesString(),
            })
          "
        />
        <span
          v-else
          v-safe-html="
            t('modal.warning_text_when_deleting_card', {
              currentCard: `<b>${currentNode?.name || '-'}</b>`,
            })
          "
        />
      </ConfirmModal>
      <CreditsafeSelectCompanyOrPersonModal
        v-if="showCreditsafeCreateCompanyModal"
        :initial-contact-persons="companyRecord?.contact_persons"
        :initial-sales-status-company-id="companyRecord?.id"
        :initial-company-id="currentNode.company_id"
        :initial-person-id="currentNode.person_id"
        @select-contact-person="handleSetPersonCardData"
        @select-company="handleSetCompanyCardsData"
        @close="closeShowCreditsafeCreateCompanyModal"
      />

      <div class="organogram-filters h-100 d-flex flex-column align-items-start">
        <div class="overview-search-input mt-3 mb-3">
          <div class="position-relative">
            <BaseInput
              v-model="search"
              class="organogram-search mb-3 mt-4"
              :placeholder="$t('ui.placeholders.search')"
              @enter="filterChart"
            />
            <i class="fa-solid fa-magnifying-glass icon-search position-absolute" />
          </div>
        </div>
        <div
          class="cursor-pointer mb-3"
          @click="compact"
        >
          <i class="fa-solid fa-sitemap icon" />
          {{ $t('sales.organogram.change_layout') }}
        </div>
        <div
          class="cursor-pointer"
          @click="flipChart('bottom')"
        >
          <i class="fa-solid fa-arrow-up icon" />
          {{ $t('sales.organogram.top') }}
        </div>
        <div
          class="cursor-pointer"
          @click="flipChart('top')"
        >
          <i class="fa-solid fa-arrow-down icon" />
          {{ $t('sales.organogram.bottom') }}
        </div>
        <div
          class="cursor-pointer"
          @click="flipChart('left')"
        >
          <i class="fa-solid fa-arrow-right icon" />
          {{ $t('sales.organogram.right') }}
        </div>
        <div
          class="cursor-pointer mb-3"
          @click="flipChart('right')"
        >
          <i class="fa-solid fa-arrow-left icon" />
          {{ $t('sales.organogram.left') }}
        </div>
        <div
          class="cursor-pointer"
          @click="fitToScreen"
        >
          <i class="fa-solid fa-maximize icon" />
          {{ $t('sales.organogram.center') }}
        </div>
        <div
          class="cursor-pointer mb-3"
          @click="fullScreen"
        >
          <i class="fa-solid fa-expand icon" />
          {{ $t('sales.organogram.full_screen') }}
        </div>
        <div
          class="cursor-pointer"
          @click="collapseAll"
        >
          <i class="fa-solid fa-chevron-up icon" />
          {{ $t('sales.organogram.collapse_all') }}
        </div>
        <div
          class="cursor-pointer mb-3"
          @click="expandAll"
        >
          <i class="fa-solid fa-chevron-down" />
          {{ $t('sales.organogram.expand_all') }}
        </div>
        <div
          v-if="!isFullScreen"
          class="cursor-pointer"
          @click="exportImg()"
        >
          <i class="fa-solid fa-download icon" />
          {{ $t('ui.download') }}
        </div>
        <FormFooter
          :pending="loading"
          :disabled="_.isEqual(mappedRecord, originalMappedRecord)"
          class="footer-organogram mb-3 pb-2"
          @save="handleSave"
          @cancel="handleCancel"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseInput from '@/components/form/BaseInput.vue';
import CreditsafeSelectCompanyOrPersonModal from '@/forms/sales/sales-status/creditsafe/CreditsafeSelectCompanyOrPersonModal.vue';
import {
  useGetCompany,
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import ConfirmModal from '@/components/ConfirmModal.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import {
  useBeforeRouteLeave,
  useGetOriginalRecord,
  useSetOriginalRecord,
  useSetRecord,
} from '@/composables/UseIsDirty';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import BaseModal from '@/components/BaseModal.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useEnumerationsStore } from '@/stores/enumerations';
import { OrgChart } from 'd3-org-chart';
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import { nanoid } from 'nanoid';
import { select } from 'd3-selection';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();

const companyRecord = ref(null);
const record = ref(props.initialRecord);
const originalRecord = ref({});
const originalMappedRecord = ref({});
const cardIdsOfCardWithErrors = ref([]);
const loading = ref(false);
const formId = nanoid();
const chartContainer = ref(null);
const search = ref(null);
const isFullScreen = ref(false);
const chartPosition = ref(null);
const showCompactLayout = ref(false);
const showCreditsafeCreateCompanyModal = ref(false);
const showDeleteNodesConfirmModal = ref(false);
const showConfirmCreditProposalDataModal = ref(false);
const removedNodeIds = ref([]);
const creditProposalUpdateDataNotAllowed =
  (useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner()) ||
  useGetProspectCreditProposalStatus() === APPROVED;
let preventOpenModal = false;
let originalCreditProposalObject = {};
let chartReference = null;

const currentNode = ref({
  id: null,
  card_id: null,
  parent_card_id: null,
  name: null,
  person_id: null,
  company_id: null,
});

function getCardIdsOfCardsWithErrors() {
  let cards = structuredClone(record.value.cards);
  let cardIdsOfCardWithErrors = [];

  if (cards.length === 1) {
    return cardIdsOfCardWithErrors;
  }

  cards?.forEach((card, key) => {
    if ((!card.parent_card_id && key !== 0) || (!card.company_id && !card.person_id)) {
      cardIdsOfCardWithErrors = [...cardIdsOfCardWithErrors, card.card_id];
    }
  });

  return cardIdsOfCardWithErrors;
}

watch(
  () => companyRecord.value?.contact_persons,
  () => {
    const originalMappedRecord = structuredClone(mappedRecord.value);
    mapCards();
    if (!_.isEqual(mappedRecord.value, originalMappedRecord.value)) {
      chartReference?.render();
      setChartHeight();
    }
  }
);

const mappedRecord = computed(() => {
  return record.value.cards.map((card) => {
    return {
      id: card.id,
      card_id: card.card_id,
      parent_card_id: card.parent_card_id,
      name: card.name,
      person_id: card.person_id,
      company_id: card.company_id,
    };
  });
});

onBeforeRouteLeave(() => {
  if (useGetOriginalRecord(formId)) {
    useSetRecord(mappedRecord.value, formId);
  }
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  companyRecord.value = useGetCompany();

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  originalRecord.value = structuredClone(record.value);
  originalMappedRecord.value = useSetOriginalRecord(mappedRecord.value, formId);
});

onMounted(() => {
  chartReference = new OrgChart();
  renderChart();
  chartContainer.value.addEventListener('click', setChartClickEventDelegation);
  document.addEventListener('fullscreenchange', handleFullScreenChange);
  expandAll();
});

async function handleSave() {
  if (_.isEqual(mappedRecord.value, originalMappedRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );
    if (creditProposalHasDataDifference) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  cardIdsOfCardWithErrors.value = getCardIdsOfCardsWithErrors();

  if (cardIdsOfCardWithErrors.value.length) {
    chartReference?.render();
    setChartHeight();
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;

    let response;

    if (!record.value.id) {
      response = await apiClient.request(
        'post',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/organogram`,
        null,
        record.value
      );
      useSetToast('success', t('toast.success.organogram_successfully_created'));
      record.value.id = response.data.id;
    } else {
      response = await apiClient.request(
        'put',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/organogram/${record.value.id}`,
        null,
        record.value
      );
      useSetToast('success', t('toast.success.organogram_successfully_updated'));
    }
    emit('save', {
      customerLogs: response.sales_status.customer_log,
    });
    record.value.cards = structuredClone(response.data.cards);
    record.value.cards = mapCards();
    chartReference.data(record.value.cards).render();
    expandAll();

    originalRecord.value = structuredClone(record.value);
    originalMappedRecord.value = useSetOriginalRecord(mappedRecord.value, formId);

    if (creditProposalUpdateDataNotAllowed) {
      originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
    }
  } catch (error) {
    if (!record.value.id) {
      useSetToast('error', t('toast.error.creating_organogram') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating organogram: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_organogram') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating organogram: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = structuredClone(originalRecord.value);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  cardIdsOfCardWithErrors.value = [];

  chartReference.data(record.value.cards).render();
  expandAll();
  originalRecord.value = structuredClone(record.value);
  originalMappedRecord.value = structuredClone(mappedRecord.value);
}

function setChartClickEventDelegation(event) {
  if (
    event.target.getAttribute('data-arrow') !== 'addChild' &&
    event.target.getAttribute('data-arrow') !== 'addSibling' &&
    event.target.getAttribute('data-remove') !== 'removeNode'
  ) {
    return;
  }

  preventOpenModal = true;

  setTimeout(() => {
    if (event.target.getAttribute('data-arrow') === 'addChild') {
      addChildNode();
    }
    if (event.target.getAttribute('data-arrow') === 'addSibling') {
      addSiblingNode();
    }
    if (event.target.getAttribute('data-remove') === 'removeNode') {
      openDeleteNodesConfirmModal();
    }
    preventOpenModal = false;
  }, 2);
}

function getContactPerson(personId) {
  return companyRecord.value?.contact_persons.find((contactPerson) => {
    return contactPerson.person.id === personId;
  });
}

function handleSetCompanyCardsData(company) {
  record.value.cards.forEach((node, index) => {
    if (node.card_id === currentNode.value.card_id) {
      record.value.cards[index]['person_id'] = null;
      record.value.cards[index]['company_id'] = company?.id;
      record.value.cards[index].name = company?.name;
      if (
        originalRecord.value.cards[index] &&
        originalRecord.value.cards[index]?.company_id === record.value.cards[index]?.company_id
      ) {
        originalRecord.value.cards[index].person_id = record.value.cards[index].person_id;
        originalRecord.value.cards[index].company_id = record.value.cards[index].company_id;
        originalRecord.value.cards[index].name = record.value.cards[index].name;
        originalMappedRecord.value[index].person_id = record.value.cards[index].person_id;
        originalMappedRecord.value[index].company_id = record.value.cards[index].company_id;
        originalMappedRecord.value[index].name = record.value.cards[index].name;
      }
      currentNode.value = record.value.cards[index];
      return;
    }

    if (node.company_id && node.company_id === company?.id) {
      record.value.cards[index].name = company?.name;
      if (originalRecord.value.cards[index]) {
        originalRecord.value.cards[index].name = record.value.cards[index].name;
        originalMappedRecord.value[index].name = record.value.cards[index].name;
      }
    }
  });

  chartReference.render();
  setChartHeight();
}

function handleSetPersonCardData(person) {
  record.value.cards.forEach((node, index) => {
    if (node.card_id === currentNode.value.card_id) {
      record.value.cards[index]['person_id'] = person?.id;
      record.value.cards[index]['company_id'] = null;
      record.value.cards[index].name = person?.name;
      if (
        originalRecord.value.cards[index] &&
        originalRecord.value.cards[index]?.person_id === record.value.cards[index]?.person_id
      ) {
        originalRecord.value.cards[index].person_id = record.value.cards[index].person_id;
        originalRecord.value.cards[index].company_id = record.value.cards[index].company_id;
        originalRecord.value.cards[index].name = record.value.cards[index].name;
        originalMappedRecord.value[index].person_id = record.value.cards[index].person_id;
        originalMappedRecord.value[index].company_id = record.value.cards[index].company_id;
        originalMappedRecord.value[index].name = record.value.cards[index].name;
      }
      currentNode.value = record.value.cards[index];
      return;
    }

    if (node.person_id && node.person_id === person?.id) {
      record.value.cards[index].name = person?.name;
      if (originalRecord.value.cards[index]) {
        originalRecord.value.cards[index].name = record.value.cards[index].name;
        originalMappedRecord.value[index].name = record.value.cards[index].name;
      }
    }
  });

  chartReference.render();
  setChartHeight();
}

function mapCards() {
  return record.value.cards.map((card, index) => {
    if (card.company_id) {
      const company = Object.values(enums.data.companies).find((company) => company.id === card.company_id);
      card.name = company.name;

      if (Number.isInteger(card.company_id) && !company?.id) {
        card.name = null;
        card.person_id = null;
      }
      return card;
    }

    if (card.person_id) {
      const contactPerson = getContactPerson(card.person_id);
      if (card.name !== contactPerson?.person?.name) {
        card.name = contactPerson?.person?.name;
        if (Object.keys(originalRecord.value).length && originalRecord.value.cards[index]) {
          originalRecord.value.cards[index].name = card.name;
          originalMappedRecord.value[index].name = card.name;
        }
      }

      if (Number.isInteger(card.person_id) && !contactPerson?.person?.id) {
        card.name = null;
        card.person_id = null;
        if (Object.keys(originalRecord.value).length && originalRecord.value.cards[index]) {
          originalRecord.value.cards[index].name = null;
          originalMappedRecord.value[index].name = null;
          originalRecord.value.cards[index].person_id = null;
          originalMappedRecord.value[index].person_id = null;
        }
      }
    }

    return card;
  });
}

function getCreditProposalObject() {
  const organogram = { organogram: structuredClone(record.value.cards) };
  return { sales_status: organogram };
}

function setChartHeight() {
  const container = chartContainer.value?.querySelector('.svg-chart-container');
  if (!container) {
    return;
  }
  container.setAttribute('height', '100%');
  container.setAttribute('width', '100%');
}

function toggleShowCreditsafeCreateCompanyModal() {
  showCreditsafeCreateCompanyModal.value = !showCreditsafeCreateCompanyModal.value;
}

function closeShowCreditsafeCreateCompanyModal() {
  showCreditsafeCreateCompanyModal.value = false;
}

function toggleDeleteNodesConfirmModal() {
  showDeleteNodesConfirmModal.value = !showDeleteNodesConfirmModal.value;
}

function openDeleteNodesConfirmModal() {
  record.value.cards.forEach((node) => {
    if (
      node.card_id === currentNode.value.card_id ||
      node.parent_card_id === currentNode.value.card_id ||
      Object.values(removedNodeIds.value).includes(node.parent_card_id)
    ) {
      removedNodeIds.value = [...removedNodeIds.value, node.card_id];
    }
  });
  toggleDeleteNodesConfirmModal();
}

function closeDeleteNodesConfirmModal() {
  toggleDeleteNodesConfirmModal();
  removedNodeIds.value = [];
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}

function renderChart() {
  const d3 = {
    select,
  };

  chartReference
    .container(chartContainer.value)
    .compact(false)
    .data(record.value.cards)
    .nodeId((d) => d.card_id)
    .parentNodeId((d) => d.parent_card_id)
    .nodeHeight((d) => 85)
    .nodeWidth((d) => 220 + 2)
    .childrenMargin((d) => 50)
    .compactMarginBetween((d) => 35)
    .compactMarginPair((d) => 30)
    .neighbourMargin((a, b) => 20)
    .siblingsMargin((d) => 25)
    // .buttonContent(({ node, state }) => {
    //   return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
    //     (node.children && chartPosition.value === 'TOP') || chartPosition.value === 'BOTTOM'
    //       ? `<i class="fas fa-angle-up"></i>`
    //       : `<i class="fas fa-angle-down"></i>`
    //   }</span> ${node.data._directSubordinates}  </div>`;
    // })
    .nodeUpdate(function (d, i, arr, state) {
      d3.select(this)
        .select('.node-rect')
        .attr('stroke', (d) => (d.data._highlighted || d.data._upToTheRootHighlighted ? '#E27396' : 'none'))
        .attr('stroke-width', d.data._highlighted || d.data._upToTheRootHighlighted ? 0 : 1);

      if (chartReference.layout() === 'left') {
        d3.select(this)
          .selectAll('.node-button-foreign-object, .node-button-rect')
          .attr('width', (d) => 22)
          .attr('height', (d) => 18)
          .attr('x', (d) => -1)
          .attr('y', (d) => -9);
      }

      if (chartReference.layout() === 'right') {
        d3.select(this)
          .selectAll('.node-button-foreign-object, .node-button-rect')
          .attr('width', (d) => 22)
          .attr('height', (d) => 18)
          .attr('x', (d) => -21)
          .attr('y', (d) => -9);
      }

      if (chartReference.layout() === 'bottom') {
        d3.select(this)
          .selectAll('.node-button-foreign-object, .node-button-rect')
          .attr('width', (d) => 22)
          .attr('height', (d) => 18)
          .attr('x', (d) => -11)
          .attr('y', (d) => -18);
      }

      if (chartReference.layout() === 'top') {
        d3.select(this)
          .selectAll('.node-button-foreign-object, .node-button-rect')
          .attr('width', (d) => 22)
          .attr('height', (d) => 18)
          .attr('x', (d) => -11)
          .attr('y', (d) => 1);
      }
    })

    .nodeContent(function (d, i, arr, state) {
      let arrowUpHtml;
      let arrowRightHtml;
      let arrowDownHtml;
      let arrowLeftHtml;

      const errorClass = cardIdsOfCardWithErrors.value.includes(d.data?.card_id) ? 'error' : '';
      const removeIcon = i !== 0 ? `<i class="fa-solid fa-xmark icon icon-close" data-remove="removeNode"></i>` : '';

      // card arrows html with data selector for horizontal layout
      if (!chartReference.compact()) {
        arrowUpHtml = `<div class="w-100 arrow-up"><i class="fa-solid fa-arrow-up icon"></i></div>`;
        arrowRightHtml = `<div class="arrow-right"><i class="fa-solid fa-arrow-right icon"></i></div>`;
        arrowDownHtml = `<div class="w-100 arrow-down"><i class="fa-solid fa-arrow-down icon"></i></div>`;
        arrowLeftHtml = `<div class="arrow-left"><i class="fa-solid fa-arrow-left icon"></i></div>`;

        if (chartPosition.value === 'top' || !chartPosition.value) {
          arrowUpHtml = '';
          arrowLeftHtml = '';

          arrowRightHtml = i !== 0 ? arrowRightHtml.replace('<i', '<i data-arrow="addSibling"') : '';
          arrowDownHtml = arrowDownHtml.replace('<i', '<i data-arrow="addChild"');
        }

        if (chartPosition.value === 'right') {
          arrowUpHtml = '';
          arrowRightHtml = '';

          arrowDownHtml = i !== 0 ? arrowDownHtml.replace('<i', '<i data-arrow="addSibling"') : '';
          arrowLeftHtml = arrowLeftHtml.replace('<i', '<i data-arrow="addChild"');
        }

        if (chartPosition.value === 'bottom') {
          arrowDownHtml = '';
          arrowLeftHtml = '';

          arrowUpHtml = arrowUpHtml.replace('<i', '<i data-arrow="addChild"');
          arrowRightHtml = i !== 0 ? arrowRightHtml.replace('<i', '<i data-arrow="addSibling"') : '';
        }

        if (chartPosition.value === 'left') {
          arrowUpHtml = '';
          arrowLeftHtml = '';

          arrowRightHtml = arrowRightHtml.replace('<i', '<i data-arrow="addChild"');
          arrowDownHtml = i !== 0 ? arrowDownHtml.replace('<i', '<i data-arrow="addSibling"') : '';
        }
      }

      // card arrows html with data selector for compact layout
      if (chartReference.compact()) {
        arrowUpHtml = `<div class="w-100 arrow-up"><i class="fa-solid fa-arrow-up icon"></i></div>`;
        arrowRightHtml = `<div class="arrow-right"><i class="fa-solid fa-arrows-left-right icon"></i></div>`;
        arrowDownHtml = `<div class="w-100 arrow-down"><i class="fa-solid fa-arrow-down icon"></i></div>`;
        arrowLeftHtml = `<div class="arrow-left"><i class="fa-solid fa-arrows-left-right icon"></i></div>`;

        if (chartPosition.value === 'top' || !chartPosition.value) {
          arrowUpHtml = '';
          arrowRightHtml = i !== 0 ? arrowRightHtml.replace('<i', '<i data-arrow="addSibling"') : '';
          arrowDownHtml = arrowDownHtml.replace('<i', '<i data-arrow="addChild"');
          arrowLeftHtml = i !== 0 ? arrowLeftHtml.replace('<i', '<i data-arrow="addSibling"') : '';
        }

        if (chartPosition.value === 'right') {
          arrowRightHtml = '';

          arrowUpHtml =
            i !== 0
              ? arrowUpHtml.replace('<i', '<i data-arrow="addSibling"').replace(`fa-arrow-up`, 'fa-arrows-up-down')
              : '';
          arrowLeftHtml = arrowLeftHtml
            .replace('<i', '<i data-arrow="addChild"')
            .replace(`fa-arrows-left-right`, 'fa-arrow-left');
          arrowDownHtml =
            i !== 0
              ? arrowDownHtml.replace('<i', '<i data-arrow="addSibling"').replace(`fa-arrow-down`, 'fa-arrows-up-down')
              : '';
        }

        if (chartPosition.value === 'bottom') {
          arrowDownHtml = '';

          arrowUpHtml = arrowUpHtml.replace('<i', '<i data-arrow="addChild"');
          arrowRightHtml = i !== 0 ? arrowRightHtml.replace('<i', '<i data-arrow="addSibling"') : '';
          arrowLeftHtml = i !== 0 ? arrowLeftHtml.replace('<i', '<i data-arrow="addSibling"') : '';
        }

        if (chartPosition.value === 'left') {
          arrowLeftHtml = '';

          arrowUpHtml =
            i !== 0
              ? arrowUpHtml.replace('<i', '<i data-arrow="addSibling"').replace(`fa-arrow-up`, 'fa-arrows-up-down')
              : '';
          arrowRightHtml = arrowRightHtml
            .replace('<i', '<i data-arrow="addChild"')
            .replace(`fa-arrows-left-right`, 'fa-arrow-right');
          arrowDownHtml =
            i !== 0
              ? arrowDownHtml.replace('<i', '<i data-arrow="addSibling"').replace(`fa-arrow-down`, 'fa-arrows-up-down')
              : '';
        }
      }

      return `
            <div
              data-content="openModal"
              class="organogram-card ${errorClass}"
              style="font-family: 'Roboto', sans-serif; background-color: #fff; position: absolute; word-break: break-all; box-shadow: 0 0 0 3px rgba(0, 173, 239, 0.125); border-radius: 12px; border: 1px solid #00adef; width:${
                d.width
              }px; height:${d.height}px; border: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '1px solid #E27396' : ''}; box-shadow: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '0 0 0 3px rgba(226, 115, 150, 0.25)' : ''}"
            >
              ${removeIcon}
              ${arrowUpHtml}
              ${arrowRightHtml}
              ${arrowDownHtml}
              ${arrowLeftHtml}
              <div style="height: 100%; display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 14px; color: #333; padding: 0 2rem;">${d.data.name || ''}</div>
              </div>
           </div>
  `;
    })
    .onNodeClick((d) => setCurrentNode(d))
    .render();
}

function setCurrentNode(d) {
  const data = d?.data;
  let cardId = data?.card_id;
  let parentCardId = data?.parent_card_id;

  currentNode.value = {
    id: data.id,
    card_id: cardId,
    parent_card_id: parentCardId,
    name: data.name,
    person_id: data.person_id,
    company_id: data.company_id,
  };

  setTimeout(() => {
    if (!preventOpenModal) {
      toggleShowCreditsafeCreateCompanyModal();
    }
  }, 1);
}

function addChildNode() {
  const uniqueString = nanoid();
  chartReference.addNode({
    id: null,
    card_id: uniqueString,
    parent_card_id: currentNode.value?.card_id,
    name: null,
    person_id: null,
    company_id: null,
    _centered: true,
  });
}

function addSiblingNode() {
  const uniqueString = nanoid();
  chartReference.addNode({
    id: null,
    card_id: uniqueString,
    parent_card_id: currentNode.value.parent_card_id,
    name: null,
    person_id: null,
    company_id: null,
    _centered: true,
  });
}

function handleRemoveNode() {
  removeNodes();
  toggleDeleteNodesConfirmModal();
}

function removeNodes() {
  record.value.cards = record.value.cards.filter((card) => {
    return !Object.values(removedNodeIds.value).includes(card.card_id);
  });
  removedNodeIds.value = [];
  chartReference.removeNode(currentNode.value.card_id).data(record.value.cards);
}

function getRemovedNodeNamesString() {
  const removedNodes = record.value.cards.filter((card) => {
    return Object.values(removedNodeIds.value).includes(card.card_id);
  });

  let string = '<br /> <br />';

  removedNodes.forEach((card, key) => {
    let cardName = card?.name || '-';

    if (currentNode.value.card_id === card.card_id) {
      return;
    }

    string += '<b>' + cardName + '</b>';

    if (key !== removedNodes.length - 1) {
      string += ', ';
    }
    string += '<br />';
  });

  return string;
}

function flipChart(position) {
  chartPosition.value = position;
  chartReference.layout(position).render().fit();
  setChartHeight();
}

function compact() {
  showCompactLayout.value = !showCompactLayout.value;
  chartReference.compact(showCompactLayout.value).render().fit();
  setChartHeight();
}

function fitToScreen() {
  chartReference.fit();
  setChartHeight();
}
function fullScreen() {
  chartReference.fullscreen();
  setChartHeight();
}

function expandAll() {
  chartReference.expandAll();
  setChartHeight();
}

function collapseAll() {
  chartReference.collapseAll();
  setChartHeight();
}

function exportImg() {
  const buttons = document.querySelectorAll('.node-button-g');

  // hide collapse/expand indicator before image download
  buttons.forEach((button) => (button.style.display = 'none'));

  chartReference.render().exportImg({ full: true });

  // show collapse/expand indicator after image download
  setTimeout(() => {
    buttons.forEach((button) => (button.style.display = 'flex'));
    setChartHeight();
  }, chartReference.duration() + 11);
}

function filterChart() {
  chartReference.clearHighlighting();
  const data = chartReference.data();
  // data.forEach((d) => (d._expanded = false));

  data.forEach((d) => {
    if (search.value && d.name?.toLowerCase().includes(search.value.toLowerCase())) {
      d._highlighted = true;
      // d._expanded = true;
    }
  });

  chartReference.data(data).render().fit();
  setChartHeight();
}

function handleFullScreenChange() {
  isFullScreen.value = !!document.fullscreenElement;

  if (!document.fullscreenElement) {
    setTimeout(() => {
      chartReference?.render();
      setChartHeight();
    }, 10);
  }
}
</script>
