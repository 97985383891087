<template>
  <FormControlWrapper
    v-if="props.label"
    :for="labelId"
    :label-content="props.label"
    :post-label="props.postLabel"
    :tooltip-content="props.tooltipContent"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    :wrapper-class="props.wrapperClass"
  >
    <BaseSelect
      v-bind="$attrs"
      :label-id="labelId"
      :col-width="colWidth"
      :options="getOptions"
      :errors="props.errors"
      :initial-placeholder="props.initialPlaceholder"
    />
    <template #extra>
      <slot name="extra" />
    </template>
  </FormControlWrapper>
  <BaseSelect
    v-else
    v-bind="$attrs"
    :label-id="labelId"
    :col-width="colWidth"
    :options="getOptions"
    :errors="props.errors"
    :initial-placeholder="props.initialPlaceholder"
    :show-error-messages="props.showErrorMessages"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import { computed } from 'vue';
import { nanoid } from 'nanoid';

const props = defineProps({
  enumName: {
    type: String,
    default: '',
  },
  withEmpty: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  optionsType: {
    type: String,
    default: 'object',
  },
  label: {
    type: String,
    default: '',
  },
  postLabel: {
    type: String,
    default: null,
  },
  initialPlaceholder: {
    type: [String, Boolean],
    default: '',
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  colWidth: {
    type: Boolean,
    default: false,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
});

const enums = useEnumerationsStore();
const labelId = nanoid();

const getEnum = computed(() => {
  if (enums.data[props.enumName] && props.enumName === 'countries') {
    return getCountryEnum(props.enumName);
  }
  return enums.data[props.enumName];
});

const getOptions = computed(() => {
  if (Object.entries(props.options).length) {
    return props.options;
  }
  return getEnum.value;
});

function getCountryEnum(enumName) {
  const countries = {};
  enums.data[enumName].map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}
</script>
