<template>
  <BaseModal
    v-if="showRemoveEmployeeFromEvaluationModal"
    :show="showRemoveEmployeeFromEvaluationModal"
    :modal-title="$t('sales.credit_proposal.remove_employee_from_evaluations')"
    size="md"
    custom-footer
    @close="closeRemoveEmployeeModal"
  >
    <div class="fw-normal mb-2">
      {{ $t('sales.credit_proposal.accompanying_note') }}
    </div>
    <BaseTextarea
      v-model="removeEmployeeFromEvaluationModalMessage"
      rows="5"
    />
    <template #modalFooter>
      <IconButton
        icon="fa-solid fa-paper-plane"
        :label-right="$t('ui.send')"
        :loading="loadingRemoveEmployee"
        button-style="primary"
        @click.prevent="handleRemoveEmployee"
      />
    </template>
  </BaseModal>
  <BaseModal
    v-if="showSubmitEvaluationsModal"
    :show="showSubmitEvaluationsModal"
    :modal-title="$t('sales.credit_proposal.submit_evaluations')"
    size="md"
    custom-footer
    @close="toggleSubmitEvaluationsModal"
  >
    <!-- todo wait for text and add translation -->
    Weet je zeker dat je het kredietvoorstel definitief wil maken?
    <template #modalFooter>
      <SecondaryButton @click.prevent="toggleSubmitEvaluationsModal">
        {{ t('ui.cancel') }}
      </SecondaryButton>
      <PrimaryButton
        :loading="loadingSubmitEvaluations"
        @click.prevent="handleConfirmSubmitEvaluations"
      >
        {{ t('ui.confirm') }}
      </PrimaryButton>
    </template>
  </BaseModal>
  <BaseModal
    v-if="showEvaluationMessageModal"
    :show="showEvaluationMessageModal"
    :modal-title="t('sales.credit_proposal.removed_by_the_applicant')"
    size="lg"
    custom-footer
    @close="toggleEvaluationMessageModal"
  >
    {{ currentEvaluationModalMessage }}
  </BaseModal>
  <div class="title mt-0">
    {{ t('sales.credit_proposal.assessment_committee.applicant') }}
    <span class="message-date">
      ({{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(evaluationOwner?.created_at)) }}
      {{ dayjs(evaluationOwner?.created_at).format(dateFormats.time) }})
    </span>
  </div>
  <div v-if="useGetProspectCreditProposalOwner()">
    {{ getEmployeeName(computedCurrentUserEvaluation.employee_id) }}
  </div>
  <div class="title">
    {{ t('sales.credit_proposal.assessment_committee.evaluations', 2) }}
  </div>
  <div class="mb-5">
    <div
      v-if="computedActiveEvaluations?.length"
      class="evaluation-status-container pb-4"
    >
      <template
        v-for="evaluation in _.orderBy(computedActiveEvaluations, (obj) => getEmployeeName(obj.employee_id), 'asc')"
        :key="evaluation"
      >
        <div
          v-if="!evaluation?.owner"
          class="committee-member"
        >
          <div
            v-if="useGetProspectCreditProposalOwner()"
            class="icon-ban"
          >
            <template v-if="!evaluation.owner && evaluation.employee_id !== authorizationStore.employee.id">
              <BaseIcon
                v-if="evaluation.status !== REMOVED"
                icon="fa-solid fa-ban"
                class="icon cursor-pointer me-2 px-1"
                @click="openRemoveEmployeeModal(evaluation)"
              />
            </template>
            <BaseTooltip
              v-if="evaluation.status === REMOVED"
              :content="
                t('sales.credit_proposal.removed_by_the_applicant') + ':\n' + t('tooltip.click_for_more_information')
              "
              class="me-2 px-1"
              @click="toggleEvaluationMessageModal(evaluation.message)"
            />
          </div>
          <div
            class="status-indicator"
            :class="evaluation.status?.toLowerCase()"
          />
          <span
            :class="{
              'fw-medium': evaluation.employee_id === authorizationStore.employee.id,
              'text-decoration-line-through': evaluation.status === REMOVED,
            }"
          >
            {{ getEmployeeName(evaluation.employee_id) }}
          </span>
          <BaseTooltip
            v-if="!useGetProspectCreditProposalOwner() && evaluation.status === REMOVED && evaluation.message"
            :content="evaluation.message"
            class="ms-2"
          />
        </div>
      </template>
    </div>
    <template
      v-if="
        !computedCurrentUserEvaluation.owner &&
        !computedCurrentUserEvaluation.status &&
        !computedCurrentUserEvaluation.message &&
        useGetProspectCreditProposalStatus() !== APPROVED
      "
    >
      <ButtonGroup
        :errors="errorMessages['status']"
        class="flex-column mb-3"
      >
        <div class="sales-status-selector btn-group btn-group-md">
          <template
            v-for="(status, key) in evaluationStatusTitles"
            :key="key"
          >
            <PrimaryButton
              :outline="evaluationRecord.status !== key"
              @click.prevent="handleSetEvaluationStatus($event, key)"
            >
              {{ status }}
            </PrimaryButton>
          </template>
        </div>
      </ButtonGroup>
      <BaseTextarea
        v-if="!computedCurrentUserEvaluation?.message"
        v-model="evaluationRecord.message"
        :placeholder="$t('sales.credit_proposal.assessment_committee.evaluation')"
        :errors="errorMessages['message']"
        rows="5"
      />
      <div class="d-flex align-items-center">
        <FormFooter
          :pending="loadingEvaluationRecord"
          :disabled="_.isEqual(evaluationRecord, originalEvaluationRecord) || loadingEvaluationRecord"
          @save="handleSaveEvaluation"
          @cancel="handleCancelEvaluation"
        />
      </div>
    </template>

    <template v-if="useGetProspectCreditProposalOwner()">
      <div class="d-flex flex-column justify-content-end">
        <PrimaryButton
          :disabled="
            useGetProspectCreditProposalStatus() === APPROVED ||
            (useGetProspectCreditProposalStatus() !== EVALUATION && useGetProspectCreditProposalStatus() !== REJECTED)
          "
          :loading="loadingResetEvaluations"
          class="ms-auto"
          @click.prevent="handleResetEvaluations"
        >
          {{ $t('sales.credit_proposal.reset_evaluations') }}
        </PrimaryButton>
        <PrimaryButton
          :disabled="
            !allEvaluationStatusHasBeenSetApprovedOrRejected ||
            useGetProspectCreditProposalStatus() === APPROVED ||
            useGetProspectCreditProposalStatus() === REJECTED
          "
          class="ms-auto mt-2"
          @click.prevent="handleSubmitEvaluations"
        >
          {{ $t('sales.credit_proposal.submit_evaluations') }}
        </PrimaryButton>
      </div>
    </template>
  </div>
  <template v-if="record?.evaluations?.length">
    <template
      v-for="(evaluation, key) in _.orderBy(record.evaluations, 'id', 'desc')"
      :key="key"
    >
      <template v-if="evaluation.owner">
        <div
          v-if="evaluation.status === RESET"
          class="d-flex mt-5"
        >
          <span class="fst-italic text-center flex-grow-1">
            {{
              t('information_messages.evaluations_have_been_reset', {
                name: getEmployeeName(evaluation.employee_id),
              })
            }}
          </span>
          <span class="message-date">
            {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(evaluation.updated_at)) }}
            {{ dayjs(evaluation.updated_at).format(dateFormats.time) }}
          </span>
        </div>
      </template>
      <div>
        <div
          v-if="evaluation.message"
          class="text-break mt-5 mb-3 pb-2"
        >
          <div class="d-flex justify-content-between">
            <div class="text-truncate w-75 fw-medium d-flex align-items-center">
              <div
                class="status-indicator"
                :class="evaluation.status?.toLowerCase()"
              />
              <span :class="{ 'text-decoration-line-through': evaluation.status === REMOVED }">
                {{ getEmployeeName(evaluation.employee_id) }}
              </span>
            </div>
            <div class="message-date">
              {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(evaluation.updated_at)) }}
              {{ dayjs(evaluation.updated_at).format(dateFormats.time) }}
            </div>
          </div>
          <div class="message-text">{{ evaluation.message }}</div>
          <div
            v-if="evaluation.status !== REMOVED"
            class="text-end"
          >
            <BaseIcon
              icon="fa fa-reply"
              class="cursor-pointer ps-3"
              @click="showCommentEditor(evaluation)"
            />
          </div>
        </div>

        <template v-if="evaluation.status !== REMOVED">
          <div
            v-for="(comment, commentKey) in evaluation.comments"
            :key="commentKey"
            class="text-break ms-5 mb-3"
          >
            <div class="d-flex justify-content-between">
              <div class="text-truncate w-75 fw-medium d-flex align-items-center">
                <div
                  class="status-indicator"
                  :class="getEvaluationByEmployeeId(comment.employee_id)?.status?.toLowerCase()"
                />
                <span :class="{ 'text-decoration-line-through': evaluation.status === REMOVED }">
                  {{ getEmployeeName(comment.employee_id) }}
                </span>
              </div>
              <div class="message-date">
                {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(comment.created_at)) }}
                {{ dayjs(comment.created_at).format(dateFormats.time) }}
              </div>
            </div>
            <div class="message-text">{{ comment.message }}</div>
          </div>
          <div
            v-show="selectedEvaluationId === evaluation.id"
            class="mt-4 pt-2"
          >
            <BaseTextarea
              v-model="commentRecord.message"
              :placeholder="$t('sales.credit_proposal.assessment_committee.comment')"
              rows="5"
            />
            <FormFooter
              :pending="loadingCommentRecord"
              :disabled="_.isEqual(commentRecord, originalCommentRecord) || loadingCommentRecord"
              @save="handleSaveComment(evaluation)"
              @cancel="handleCancelComment"
            />
          </div>
        </template>
      </div>
    </template>
    <div class="d-flex mt-5">
      <span class="fst-italic text-center flex-grow-1">
        {{
          t('information_messages.started_a_new_evaluation_commission', {
            name: getEmployeeName(evaluationOwner?.employee_id),
          })
        }}
      </span>
      <span class="message-date">
        {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(evaluationOwner?.created_at)) }}
        {{ dayjs(evaluationOwner?.created_at).format(dateFormats.time) }}
      </span>
    </div>
  </template>
</template>

<script setup>
import dateFormats from '@/modules/dateFormats';
import ButtonGroup from '@/components/buttons/ButtonGroup.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import {
  APPROVED,
  CONDITIONAL,
  EVALUATION,
  REJECTED,
  REMOVED,
  RESET,
  TRANSLATED_STATUS,
} from '@/configs/constants/Status';
import { useEnumerationsStore } from '@/stores/enumerations';
import { useAuthorizationStore } from '@/stores/authorization';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import BaseTooltip from '@/components/general/BaseTooltip.vue';
import { toLocaleDateString } from '@/modules/formatters';
import BaseModal from '@/components/BaseModal.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
  useSetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { required, requiredIf } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  creditProposalId: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['save', 'remove']);

const router = useRouter();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const enumerationsStore = useEnumerationsStore();

const record = ref(props.initialRecord);
const originalEvaluationRecord = ref({});
const originalCommentRecord = ref({});
const errorMessages = ref([]);
const loadingEvaluationRecord = ref(false);
const loadingCommentRecord = ref(false);
const loadingRemoveEmployee = ref(false);
const loadingResetEvaluations = ref(false);
const loadingSubmitEvaluations = ref(false);
const evaluationFormId = nanoid();
const commentFormId = nanoid();
const selectedEvaluationId = ref(null);
const selectedEvaluation = ref(null);
const showRemoveEmployeeFromEvaluationModal = ref(false);
const removeEmployeeFromEvaluationModalMessage = ref(null);
const showEvaluationMessageModal = ref(false);
const showSubmitEvaluationsModal = ref(false);
const currentEvaluationModalMessage = ref(null);

const evaluationStatusTitles = {
  APPROVED: TRANSLATED_STATUS[APPROVED],
  CONDITIONAL: TRANSLATED_STATUS[CONDITIONAL],
  REJECTED: TRANSLATED_STATUS[REJECTED],
};

const commentRecord = ref({
  credit_proposal_evaluation_id: null,
  employee_id: authorizationStore.employee.id,
  message: null,
});

const evaluationRecordValidationRules = computed(() => {
  return {
    status: { required },
    message: { requiredIf: requiredIf(statusIsUnsetOrRejectedOrConditional()) },
  };
});
const commentRecordValidationRules = computed(() => {
  return {
    message: { required },
  };
});

const computedActiveEvaluations = computed(() => {
  return record.value.evaluations.filter((evaluation) => statusIsActive(evaluation.status));
});

const computedCurrentUserEvaluation = computed(() => {
  return computedActiveEvaluations.value.find(
    (evaluation) => evaluation.employee_id === authorizationStore.employee.id && evaluation.status !== RESET
  );
});

const evaluationOwner = computed(() => {
  return record.value.evaluations.find((evaluation) => {
    if (evaluation.owner) {
      return evaluation.owner;
    }
  });
});

const allEvaluationStatusHasBeenSetApprovedOrRejected = computed(() => {
  const evaluations = record.value.evaluations.filter(
    (evaluation) =>
      evaluation.status === APPROVED ||
      evaluation.status === REJECTED ||
      evaluation.status === RESET ||
      evaluation.status === REMOVED
  );

  return evaluations.length === record.value.evaluations.length;
});

const evaluationRecord = ref({
  id: getEvaluationByEmployeeId(authorizationStore.employee.id)?.id,
  customer_credit_proposal_id: record.value?.id,
  employee_id: authorizationStore.employee.id,
  status: computedCurrentUserEvaluation.value?.status,
  message: computedCurrentUserEvaluation.value?.message,
});

onBeforeMount(async () => {
  useBeforeRouteLeave();

  evaluationRecord.value = useSetRecord(evaluationRecord.value, evaluationFormId);
  originalEvaluationRecord.value = useSetOriginalRecord(evaluationRecord.value, evaluationFormId);

  commentRecord.value = useSetRecord(commentRecord.value, commentFormId);
  originalCommentRecord.value = useSetOriginalRecord(commentRecord.value, commentFormId);
});

async function handleSaveEvaluation() {
  if (_.isEqual(evaluationRecord.value, originalEvaluationRecord.value) || loadingEvaluationRecord.value) {
    return;
  }

  const validationRules = useMergeDefaultVuelidateValidationRules(
    evaluationRecordValidationRules.value,
    evaluationRecord.value
  );
  errorMessages.value = await useVuelidateValidation(validationRules, evaluationRecord.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loadingEvaluationRecord.value = true;
    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}/evaluation`,
      null,
      evaluationRecord.value
    );
    emit('save', { customerLogs: response.sales_status.customer_log });

    if (!record.value.id) {
      record.value['id'] = response.data.customer_credit_proposal_id;
    }

    const evaluations = structuredClone(_.orderBy(record.value.evaluations, 'id', 'desc'));
    const index = evaluations.findIndex((evaluation) => {
      return evaluation.employee_id === response.data.employee_id;
    });

    if (index !== -1) {
      evaluations[index] = structuredClone(response.data);
      record.value.evaluations = evaluations;

      evaluationRecord.value = useSetRecord(evaluations[index], evaluationFormId);
      originalEvaluationRecord.value = useSetOriginalRecord(evaluationRecord.value, evaluationFormId);
    }
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.creating_credit_proposal_evaluation') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while creating evaluation: ', error);
  } finally {
    loadingEvaluationRecord.value = false;
  }
}

function handleCancelEvaluation() {
  evaluationRecord.value = useSetRecord(originalEvaluationRecord.value, evaluationFormId);
  errorMessages.value = [];
}

function showCommentEditor(evaluation) {
  commentRecord.value.message = null;
  selectedEvaluationId.value = evaluation.id;
}

async function handleSaveComment(evaluation) {
  if (_.isEqual(commentRecord.value, originalCommentRecord.value) || loadingCommentRecord.value) {
    return;
  }

  const validationRules = useMergeDefaultVuelidateValidationRules(
    commentRecordValidationRules.value,
    commentRecord.value
  );
  errorMessages.value = await useVuelidateValidation(validationRules, commentRecord.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  commentRecord.value.credit_proposal_evaluation_id = evaluation.id;
  try {
    loadingCommentRecord.value = true;

    const comment = await apiClient.request(
      'post',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}/evaluation_comment`,
      null,
      structuredClone(commentRecord.value)
    );

    const index = record.value.evaluations.findIndex((item) => {
      return item.id === comment.credit_proposal_evaluation_id;
    });

    if (index !== -1) {
      if (!record.value.evaluations[index]?.comments?.length) {
        record.value.evaluations[index]['comments'] = [comment];
      } else {
        record.value.evaluations[index].comments = [
          ...record.value.evaluations[index].comments,
          structuredClone(comment),
        ];
      }
    }

    selectedEvaluationId.value = null;
    commentRecord.value.message = null;

    commentRecord.value = useSetRecord(commentRecord.value, commentFormId);
    originalCommentRecord.value = useSetOriginalRecord(commentRecord.value, commentFormId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.creating_credit_proposal_evaluation_comment') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while creating evaluation comment: ', error);
  } finally {
    loadingCommentRecord.value = false;
  }
}

function handleCancelComment() {
  commentRecord.value = useSetRecord(originalCommentRecord.value, commentFormId);
  errorMessages.value = [];
}

function handleSetEvaluationStatus(event, evaluation) {
  event.target.blur();

  if (evaluation === evaluationRecord.value.status) {
    evaluationRecord.value.status = null;
    return;
  }

  evaluationRecord.value.status = evaluation;
}

async function handleSubmitEvaluations() {
  if (
    !useGetProspectCreditProposalOwner() ||
    !allEvaluationStatusHasBeenSetApprovedOrRejected.value ||
    useGetProspectCreditProposalStatus() === APPROVED ||
    useGetProspectCreditProposalStatus() === REJECTED
  ) {
    return;
  }
  toggleSubmitEvaluationsModal();
}

async function submitEvaluations() {
  try {
    loadingSubmitEvaluations.value = true;
    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}/submit_evaluations`
    );
    useSetToast('success', t('toast.success.credit_proposal_evaluations_successfully_submitted'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    useSetProspectCreditProposalStatus({
      id: response.data.id,
      status: response.data.status,
      owner: useGetProspectCreditProposalOwner(),
    });
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.submitting_credit_proposal_evaluations') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while submitting evaluations: ', error);
  } finally {
    toggleSubmitEvaluationsModal();
    loadingSubmitEvaluations.value = false;
  }
}

async function handleResetEvaluations() {
  if (
    !useGetProspectCreditProposalOwner() ||
    useGetProspectCreditProposalStatus() === APPROVED ||
    (useGetProspectCreditProposalStatus() !== EVALUATION && useGetProspectCreditProposalStatus() !== REJECTED)
  ) {
    return;
  }

  const evaluations = record.value.evaluations?.findIndex((item) => statusIsActive(item.status));
  if (evaluations === -1) {
    return;
  }

  try {
    loadingResetEvaluations.value = true;
    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}/reset_evaluations`
    );
    useSetToast('success', t('toast.success.credit_proposal_evaluations_successfully_reset'));
    emit('save', { customerLogs: response.sales_status.customer_log });

    useSetProspectCreditProposalStatus({
      id: record.value.id,
      status: EVALUATION,
      owner: useGetProspectCreditProposalOwner(),
    });

    record.value.evaluations = response.data;
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.creating_credit_proposal_evaluation') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while creating evaluations: ', error);
  } finally {
    loadingResetEvaluations.value = false;
  }
}

async function handleConfirmSubmitEvaluations() {
  await submitEvaluations();
}

async function handleRemoveEmployee() {
  const evaluation = record.value.evaluations.find((evaluation) => evaluation.id === selectedEvaluation.value.id);

  const evaluationObject = {
    id: evaluation.id,
    customer_credit_proposal_id: record.value?.id,
    employee_id: evaluation.employee_id,
    status: REMOVED,
    message: removeEmployeeFromEvaluationModalMessage.value
      ? t('sales.credit_proposal.removed_by_the_applicant') + ':\n' + removeEmployeeFromEvaluationModalMessage.value
      : t('sales.credit_proposal.removed_by_the_applicant'),
  };

  try {
    loadingRemoveEmployee.value = true;
    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.id}/evaluation`,
      null,
      evaluationObject
    );

    let evaluation = record.value.evaluations.findIndex((evaluation) => evaluation.id === response.data.id);
    if (evaluation !== -1) {
      record.value.evaluations[evaluation] = response.data;
    }
    removeEmployeeFromEvaluationModalMessage.value = null;
    closeRemoveEmployeeModal();

    emit('save', { customerLogs: response.sales_status.customer_log });
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.creating_credit_proposal_evaluation') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while creating evaluation: ', error);
  } finally {
    loadingRemoveEmployee.value = false;
  }
}

function toggleSubmitEvaluationsModal() {
  showSubmitEvaluationsModal.value = !showSubmitEvaluationsModal.value;
}

function getEmployeeName(employee_id) {
  if (!employee_id) {
    return;
  }
  return Object.entries(enumerationsStore.data.employees).find(
    (employee) => parseInt(employee[0]) === parseInt(employee_id)
  )[1];
}

function statusIsUnsetOrRejectedOrConditional() {
  return (
    !originalEvaluationRecord.value.status &&
    (evaluationRecord.value.status === REJECTED || evaluationRecord.value.status === CONDITIONAL)
  );
}

function statusIsActive(status) {
  return status === APPROVED || status === CONDITIONAL || status === REJECTED || status === REMOVED || !status;
}

function getEvaluationByEmployeeId(employeeId) {
  return _.orderBy(record.value.evaluations, 'id', 'desc').find((evaluation) => evaluation.employee_id === employeeId);
}

function openRemoveEmployeeModal(evaluation) {
  showRemoveEmployeeFromEvaluationModal.value = true;
  selectedEvaluation.value = evaluation;
}

function closeRemoveEmployeeModal() {
  showRemoveEmployeeFromEvaluationModal.value = false;
  selectedEvaluation.value = null;
}

function toggleEvaluationMessageModal(message = null) {
  currentEvaluationModalMessage.value = message;
  showEvaluationMessageModal.value = !showEvaluationMessageModal.value;
}
</script>
