import DashboardView from '@/views/DashboardView';
import MainView from '@/views/Router/MainView';

export default {
  path: '/',
  component: MainView,
  children: [
    {
      path: '',
      name: 'dashboard',
      alias: '/dashboard',
      component: DashboardView,
      meta: {
        breadCrumbLabel: 'dashboard',
      },
    },
  ],
};
