<template>
  <BaseRow class="mt-3">
    <BaseColumn class="col-md-4 col-12">
      <BaseModal
        v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
        :show="showConfirmCreditProposalDataModal"
        :modal-title="$t('ui.confirm')"
        size="sm"
        @confirm="closeCreditProposalModal"
        @close="closeCreditProposalModal"
      >
        <template v-if="useGetProspectCreditProposalStatus() !== APPROVED">
          {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
        </template>
        <template v-else>
          {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_accepted_without_signed_contract') }}
        </template>
      </BaseModal>
      <LabelInput
        v-model="record.sales_status.company.name"
        :errors="errorMessages['sales_status.company.name']"
        :label-content="$t('general.company_name')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <SearchInput
        v-model="record.sales_status.company.legal_form.name"
        :entities="computedLegalForms"
        :errors="errorMessages['sales_status.company.legal_form.name']"
        :disallow-custom-entry="false"
        :label-content="$t('company.legal_form')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.sales_status.correspondence_language"
        :label="$t('customer_data.correspondence_language')"
        :options="getLanguages"
        :errors="errorMessages['customer_data.correspondence_language']"
        :initial-placeholder="false"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.sales_status.currency"
        :label="$t('customer_data.currency')"
        :errors="errorMessages['customer_data.currency']"
        enum-name="currencies"
        :initial-placeholder="false"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <EnumSelect
        v-model="record.customer_data.account_manager_employee_id"
        :label="$t('sales.list.account_manager')"
        :errors="errorMessages['customer_data.account_manager_employee_id']"
        enum-name="employees"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelSelect
        v-model="record.customer_data.lead_channel"
        :errors="errorMessages['customer_data.lead_channel']"
        :label-content="$t('customer_data.lead_channel')"
        :options="leadChannels"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <SearchInput
        v-if="leadChannels[record.customer_data.lead_channel] === leadChannels.INTERMEDIAIR"
        v-model="record.customer_data.intermediary_id"
        field-name="id"
        :entities="enums.mapIntermediaries(enums.data.intermediaries)"
        :errors="errorMessages['customer_data.intermediary_id']"
        :label-content="$t('customer_data.intermediary')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <SearchInput
        v-if="
          leadChannels[record.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
          record.customer_data.intermediary_id
        "
        v-model="record.customer_data.intermediary_company_id"
        field-name="id"
        :entities="computedCompaniesKeyValue"
        :errors="errorMessages['customer_data.intermediary_company_id']"
        :label-content="$t('customer_data.intermediary_company')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <LabelInput
        v-model="record.sales_status.company.registered_office"
        :label-content="$t('address.registered_office')"
        :errors="errorMessages['sales_status.company.registered_office']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="mt-4"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.address"
        :label-content="$t('address.address_information')"
        :errors="errorMessages['sales_status.company.address.address']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.postal_code"
        :label-content="$t('address.postal_code')"
        :errors="errorMessages['sales_status.company.address.postal_code']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.address.city"
        :label-content="$t('address.city')"
        :errors="errorMessages['sales_status.company.address.city']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <SearchInput
        v-model="record.sales_status.company.address.country_id"
        field-name="id"
        :entities="getCountries"
        :errors="errorMessages['sales_status.company.address.country_id']"
        :label-content="$t('address.country')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <LabelInput
        v-model="record.sales_status.company.phone"
        :label-content="$t('general.phone')"
        :errors="errorMessages['sales_status.company.phone']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        text-position="start"
        @enter="handleSave"
      />
      <LabelInput
        v-model="record.sales_status.company.website"
        :label-content="$t('general.website')"
        :errors="errorMessages['sales_status.company.website']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <CompanyRegistrationNumberNumberInput
        v-model="record.sales_status.company.company_registration_number"
        :label-content="$t('general.company_registration_number')"
        :errors="errorMessages['sales_status.company.company_registration_number']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <VatNumberInput
        v-model="record.sales_status.company.vat_number"
        :label-content="$t('general.vat_number')"
        :errors="errorMessages['sales_status.company.vat_number']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
    </BaseColumn>
    <BaseColumn class="col-md-4 col-12 second-form-column">
      <div class="position-relative">
        <SearchInput
          v-model="record.customer_data.branch"
          :entities="record.customer_data.branches"
          :label-content="$t('client.branch')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
          search-name-and-key
        />
        <div
          class="d-inline-flex branch-form-icon-right"
          :class="[
            { 'cursor-pointer': record.customer_data.branch },
            { 'cursor-not-allowed': !record.customer_data.branch },
          ]"
          @click="copyBranchKeyToClipboard"
        >
          <div :class="{ 'color-disabled': !record.customer_data.branch }">
            <BaseIcon
              v-if="!copiedToClipboard"
              icon="fa-regular fa-copy"
              :title="$t('general.copy_sbi_code')"
            />
          </div>
          <div v-if="copiedToClipboard">
            <BaseIcon
              icon="fa-solid fa-check text-success"
              class="ms-1"
            />
          </div>
        </div>
      </div>
      <AmountInput
        v-model="record.customer_data.credit_need_amount"
        :label="$t('customer_data.credit_need_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.credit_need_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.annual_revenue_amount"
        :label="$t('customer_data.annual_revenue_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.annual_revenue_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.forecast_annual_revenue_amount"
        :label="$t('customer_data.forecast_annual_revenue_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.forecast_annual_revenue_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.debtor_balance_amount"
        :label="$t('customer_data.debtor_balance_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.debtor_balance_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <NumberInput
        v-model="record.customer_data.debtors_count"
        :errors="errorMessages['customer_data.debtors_count']"
        :label-content="$t('customer_data.debtors_count')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <NumberInput
        v-model="record.customer_data.invoices_count"
        :errors="errorMessages['customer_data.invoices_count']"
        :label-content="$t('customer_data.invoices_count')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <AmountInput
        v-model="record.customer_data.average_invoice_amount"
        :label="$t('customer_data.average_invoice_amount')"
        :inner-label="record.sales_status.currency"
        :errors="errorMessages['customer_data.average_invoice_amount']"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        @enter="handleSave"
      />
      <EnumSelect
        v-model="record.customer_data.default_payment_term_days"
        :label="$t('customer_data.default_payment_term_days')"
        :errors="errorMessages['customer_data.default_payment_term_days']"
        enum-name="average_payment_term_days"
        with-empty
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
      />
      <EnumSelect
        v-model="record.customer_data.average_payment_term_days"
        :label="$t('customer_data.average_payment_term_days')"
        :errors="errorMessages['customer_data.average_payment_term_days']"
        enum-name="average_payment_term_days"
        with-empty
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <SearchInput
        v-model="record.customer_data.accounting_software.name"
        :entities="accountingSoftwareEntities"
        :errors="errorMessages['customer_data.accounting_software.name']"
        :disallow-custom-entry="false"
        :label-content="$t('client.accounting_software')"
        :label-columns="labelColumnClasses"
        :input-columns="inputColumnClasses"
        wrapper-class="impact-default-group-margin"
      />
      <div
        v-for="(financialFacility, key) in financialFacilities"
        :key="financialFacility.id"
        class="mb-4 current-financial-facility"
      >
        <SearchInput
          v-model="financialFacility.financial_facility.name"
          :entities="financialFacilityEntities"
          :errors="
            errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.financial_facility.name`]
          "
          :disallow-custom-entry="false"
          :label-content="$t('customer_data.current_financial_facility')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
        />
        <AmountInput
          v-model="financialFacility.facility_amount"
          :label="$t('customer_data.current_facility')"
          :inner-label="record.sales_status.currency"
          :errors="errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.facility_amount`]"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
          @enter="handleSave"
        />
        <MultiselectSearchInput
          v-model="financialFacility.facility_types"
          :entities="facilityTypeEntities"
          :array-of-key-value-objects="true"
          :errors="errorMessages[`customer_data.customer_data_financial_facilities.data.${key}.facility_type`]"
          :label-content="$t('customer_data.facility_type')"
          :label-columns="labelColumnClasses"
          :input-columns="inputColumnClasses"
        />
        <div class="icon-button-wrapper">
          <FormIconButton
            v-if="key === 0"
            button-size="sm"
            icon="fa-solid fa-plus"
            class="p-2"
            @click="addFinancialFacility"
          />
          <FormIconButton
            v-if="financialFacilities.length > 1"
            button-size="sm"
            icon="fa-regular fa-trash-can"
            class="p-2"
            @click="removeFinancialFacility(key)"
          />
        </div>
      </div>
    </BaseColumn>
  </BaseRow>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  >
    <template #bodyStart>
      <IconButton
        button-style="primary"
        :label-right="$t('customer_data.retrieve_company')"
        outline
        @click="goToCreditsafeCompanySearch"
      />
    </template>
  </FormFooter>
</template>

<script setup>
import LabelInput from '@/components/form/LabelInput.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import VatNumberInput from '@/components/form-controls/VatNumberInput.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import FacilityTypes from '@/configs/constants/FacilityTypes';
import apiClient from '@/services/ApiClient';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useAppendToKeyValueObject } from '@/composables/UseObjectManipulation';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useEnumerationsStore } from '@/stores/enumerations';
import {
  useAmountSuffixConvertCentsToAmount,
  useAmountSuffixConvertAmountToCents,
} from '@/composables/UseNumberManipulation';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useSetToast } from '@/composables/UseToast';
import LabelSelect from '@/components/form/LabelSelect.vue';
import LeadChannels from '@/configs/constants/LeadChannels';
import { en_GB, nl_NL } from '@/configs/constants/LocaleCodes';
import BaseModal from '@/components/BaseModal.vue';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import IconButton from '@/components/buttons/IconButton.vue';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
  useSetCompanyName,
} from '@/composables/UseProspectViewData';
import { BaseRow, BaseColumn } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import { required, numeric, requiredIf } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();
const leadChannels = LeadChannels;

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref(null);
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const labelColumnClasses = 'col-md-5 col-12';
const inputColumnClasses = 'col-md-7 col-12';
const financialFacilityEntities = ref({});
const accountingSoftwareEntities = ref({});
const copiedToClipboard = ref(false);
const facilityTypeEntities = {};
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  (useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner()) ||
  useGetProspectCreditProposalStatus() === APPROVED;
let originalCreditProposalObject = {};

const newFinancialFacility = {
  id: null,
  facility_amount: '',
  facility_types: [],
  financial_facility: {
    id: null,
    name: null,
  },
};

const rules = computed(() => {
  return {
    sales_status: {
      company: {
        name: { required },
      },
    },
    customer_data: {
      debtors_count: { numeric },
      invoices_count: { numeric },
      intermediary_id: {
        requiredIf: requiredIf(
          leadChannels[record.value.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
            !record.value.customer_data.intermediary_id
        ),
      },
      intermediary_company_id: {
        requiredIf: requiredIf(
          leadChannels[record.value.customer_data.lead_channel] === leadChannels.INTERMEDIAIR &&
            !record.value.customer_data.intermediary_company_id
        ),
      },
    },
  };
});

const computedCompaniesKeyValue = computed(() => {
  if (!record.value.customer_data.intermediary_id) {
    return {};
  }

  const intermediary = enums.data.intermediaries.find(
    (intermediary) => intermediary.id === record.value.customer_data.intermediary_id
  );

  return Object.values(intermediary?.companies).map((company) => {
    let companyObject = {
      id: company.id,
      name: company.name,
    };

    const duplicateName = Object.values(intermediary?.companies).filter(
      (filteredCompany) => filteredCompany.name === company.name
    );

    if (duplicateName.length > 1) {
      companyObject = {
        ...companyObject,
        uniqueIdentifier: company.company_registration_number,
      };
    }
    return companyObject;
  });
});

const getCountries = computed(() => {
  const countries = {};
  if (enums.data.countries) {
    enums.data.countries.map((country) => {
      countries[country.id] = country.translation;
    });
  }
  return countries;
});

const getLanguages = computed(() => {
  return { [en_GB]: t('languages.english'), [nl_NL]: t('languages.dutch') };
});

const financialFacilities = computed(() => {
  return record.value.customer_data.customer_data_financial_facilities.data;
});

const computedLegalForms = computed(() => {
  return Object.entries(enums.data.legal_forms).map((legalForm) => {
    return {
      id: parseInt(legalForm[0]),
      name: legalForm[1],
    };
  });
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  setFacilityTypes();
  accountingSoftwareEntities.value = record.value.entities.accounting_software;
  financialFacilityEntities.value = record.value.entities.financial_facilities;

  if (!record.value.customer_data.customer_data_financial_facilities.data.length) {
    record.value.customer_data.customer_data_financial_facilities.data = [
      ...financialFacilities.value,
      structuredClone(newFinancialFacility),
    ];
  }

  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  await setValidationAndErrorMessages();
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (creditProposalHasDataDifference) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  await setValidationAndErrorMessages();

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  if (leadChannels[record.value.customer_data.lead_channel] !== leadChannels.INTERMEDIAIR) {
    record.value.customer_data.intermediary_id = null;
    record.value.customer_data.intermediary_company_id = null;
    record.value.customer_data.intermediary = {};
  }

  await save();
}

async function save() {
  try {
    loading.value = true;

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${record.value.sales_status.id}/customer_data`,
      {
        include: [
          'customer_data_financial_facilities.financial_facility',
          'sales_status.customer_log.task',
          'sales_status.customer_log.customer_status_title',
          'sales_status.company.legal_form',
        ],
      },
      useAmountSuffixConvertAmountToCents(structuredClone(record.value))
    );
    useSetToast('success', t('toast.success.customer_data_successfully_updated'));
    emit('save', { customerLogs: response.sales_status.customer_log.data });

    let companyName;
    if (!record.value.sales_status.company.legal_form.name) {
      companyName = record.value.sales_status.company.name;
    } else {
      companyName = record.value.sales_status.company.name + ' ' + record.value.sales_status.company.legal_form.name;
    }

    useSetCompanyName(companyName);
    record.value.customer_data = useAmountSuffixConvertCentsToAmount(record.value.customer_data);
    record.value.sales_status.company.legal_form.id = response.sales_status.company.legal_form.id;

    if (!record.value.customer_data.customer_data_financial_facilities.data.length) {
      record.value.customer_data.customer_data_financial_facilities.data = [structuredClone(newFinancialFacility)];
    }

    appendKeyValueToAccountingSoftware(response);
    appendKeyValueToFinancialFacilities(response);
    addOrUpdateCompaniesEnum(response);
    enums.addOrUpdateEnumName(
      'legal_forms',
      response.sales_status.company.legal_form.id,
      response.sales_status.company.legal_form.name
    );

    originalRecord.value = useSetOriginalRecord(record.value, formId);

    // const currentStatus = record.value.sales_status.statuses.find((status) => {
    //   return status.data.id === record.value.sales_status.customer_status_id;
    // });
    // if (currentStatus) {
    //   navigationStore.setBreadCrumbs(
    //     `${record.value.sales_status.company.name} (${t(
    //       `sales.status.${currentStatus.data.type.toLowerCase()}`
    //     ).toLowerCase()})`
    //   );
    // }
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    useSetToast('error', t('toast.error.updating_customer_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating customer data: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}
async function setValidationAndErrorMessages() {
  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
}

function appendKeyValueToAccountingSoftware(response) {
  accountingSoftwareEntities.value = useAppendToKeyValueObject(
    accountingSoftwareEntities.value,
    response.customer_data.accounting_software_id,
    record.value.customer_data.accounting_software.name
  );
}
function appendKeyValueToFinancialFacilities(response) {
  response.customer_data_financial_facilities.data.forEach((financialFacility) => {
    if (financialFacility.financial_facility.name) {
      financialFacilityEntities.value = useAppendToKeyValueObject(
        financialFacilityEntities.value,
        financialFacility.financial_facility.id,
        financialFacility.financial_facility.name
      );
    }
  });
}

function addOrUpdateCompaniesEnum(response) {
  const companyEnum = {
    id: response.sales_status.company.id,
    type: response.sales_status.company.type,
    name: response.sales_status.company.name,
    company_registration_number: response.sales_status.company.company_registration_number,
  };

  enums.addOrUpdateEnumObject('companies', companyEnum);
}

async function addFinancialFacility() {
  record.value.customer_data.customer_data_financial_facilities.data = [
    ...financialFacilities.value,
    structuredClone(newFinancialFacility),
  ];
  await setValidationAndErrorMessages();
}
async function removeFinancialFacility(index) {
  financialFacilities.value.splice(index, 1);
  await setValidationAndErrorMessages();
}
function setFacilityTypes() {
  Object.entries(FacilityTypes).forEach((type) => {
    facilityTypeEntities[type[0]] = type[1];
  });
}

function copyBranchKeyToClipboard() {
  if (!record.value.customer_data.branch) {
    return;
  }

  navigator.clipboard.writeText(findCurrentBranch()[0]);
  copiedToClipboard.value = true;
  setTimeout(() => {
    copiedToClipboard.value = false;
  }, 2000);
}
function findCurrentBranch() {
  return Object.entries(record.value.customer_data.branches).find(
    (branch) => branch[1] === record.value.customer_data.branch
  );
}

function goToCreditsafeCompanySearch() {
  router.push({ name: 'creditsafe search update', params: { sales_status_id: record.value.sales_status.id } });
}

function getCreditProposalObject() {
  const creditProposalData = structuredClone(record.value.sales_status);
  creditProposalData['customer_data'] = structuredClone(record.value.customer_data);
  creditProposalData.customer_data.customer_data_financial_facilities = structuredClone(
    record.value.customer_data.customer_data_financial_facilities.data
  );
  return { sales_status: creditProposalData };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}
</script>
