import api from '@/services/ApiClient';

const NavigationStore = {
  namespaced: true,
  state: {
    main: null,
    app: null,
    entityName: null,
    collapsed: {
      mainNav: false,
      pageNav: false,
    },
    crumbs: {},
  },
  mutations: {
    SET_NAVIGATION(state, payload) {
      state.main = payload.main;
      state.app = payload.app;
    },
    SET_ENTITYNAME(state, value) {
      state.entityName = value;
    },
    SET_CRUMB(state, value) {
      state.crumbs = value;
    },
    SET_COLLAPSED_MAINNAV(state, value) {
      state.collapsed.mainNav = value;
    },
    SET_COLLAPSED_PAGENAV(state, value) {
      state.collapsed.pageNav = value;
    },
  },
  actions: {
    async fetchNavigation({ commit }) {
      const navigation = await api.get('/api/app/navigation');
      if (navigation) {
        commit('SET_NAVIGATION', navigation);
      }
    },
    async updateEntityName({ commit }, data) {
      commit('SET_ENTITYNAME', data);
    },
    async setCrumb({ commit }, data) {
      commit('SET_CRUMB', data);
    },
    async resetCrumb({ commit }) {
      commit('SET_CRUMB', {});
    },
    toggleMainNav({ commit }, data) {
      commit('SET_COLLAPSED_MAINNAV', data);
    },
    togglePageNav({ commit }, data) {
      commit('SET_COLLAPSED_PAGENAV', data);
    },
  },
};

export { NavigationStore };
