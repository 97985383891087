<template>
  <ViewSpacing>
    <div class="d-flex justify-content-center w-full">
      <div class="table-overview-container table-prospects-container">
        <div class="action-bar">
          <ActionAddButton
            :label="$t('sales.status.prospect')"
            route-name="creditsafe search create"
          />
        </div>
        <BaseSpinner
          v-if="loading"
          type="primary"
          class="mt-4"
        />
        <CustomerList
          v-else-if="record?.data.length"
          :initial-record="record.data"
        />
      </div>
    </div>
  </ViewSpacing>
</template>

<script setup>
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import ViewSpacing from '@/components/grid/ViewSpacing.vue';
import CustomerList from '@/lists/sales/CustomerList.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const record = ref(null);
const loading = ref(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request('get', '/ifapi/sales_statuses', {
      include: ['customer_data', 'status', 'company', 'offer', 'progresses', 'customer_log.customer_status_title'],
    });

    record.value.data = record.value.data.map((salesStatus) => {
      salesStatus['customer_data'] = salesStatus.customer_data.customer_data;
      return salesStatus;
    });
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_customer_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching customer data: ', error);
  } finally {
    loading.value = false;
  }
});
</script>
