import TabsView from '@/views/Router/TabsView.vue';
import EmptyView from '@/views/Router/EmptyView.vue';
import ComingSoonView from '@/views/Router/ComingSoonView.vue';

export default {
  path: 'contract',
  name: 'sales status contract',
  component: TabsView,
  redirect: { name: 'sales status clients and contract' },
  meta: {
    breadCrumbLabel: 'contract',
    menutype: 'tabs',
    permission: 'todo',
  },
  children: [
    {
      path: 'clients-contract',
      name: 'sales status clients and contract',
      component: ComingSoonView,
      meta: {
        breadCrumbLabel: 'clients_contract',
        menutype: 'tab',
        permission: 'todo',
      },
    },
    {
      path: 'details',
      name: 'sales status edit contract',
      component: ComingSoonView,
      meta: {
        breadCrumbLabel: 'details',
        menutype: 'tab',
        permission: 'todo',
      },
    },
    {
      path: 'financing',
      name: 'sales status contract financing',
      component: ComingSoonView,
      meta: {
        breadCrumbLabel: 'contract_financing',
        menutype: 'tab',
        permission: 'todo',
      },
    },
    {
      path: 'rates',
      name: 'sales status contract rates',
      component: ComingSoonView,
      meta: {
        breadCrumbLabel: 'contract_rates',
        menutype: 'tab',
        permission: 'todo',
      },
    },
    {
      path: 'certaintyacts',
      name: 'sales status contract certaintyacts',
      redirect: { name: 'sales status list contract certaintyacts' },
      component: EmptyView,
      meta: {
        breadCrumbLabel: 'contract_certaintyacts',
        menutype: 'tab',
        permission: 'todo',
      },
      children: [
        {
          path: '',
          name: 'sales status list contract certaintyacts',
          component: ComingSoonView,
          meta: {
            breadCrumbLabel: 'contract_certaintyacts',
            permission: 'todo',
          },
        },
        {
          path: ':certaintyact_id',
          name: 'sales status edit contract certaintyact',
          component: ComingSoonView,
          meta: {
            breadCrumbLabel: 'contract_certaintyacts',
            menutype: 'tab_child',
            permission: 'todo',
          },
        },
      ],
    },
  ],
};
