<template>
  <div
    ref="toast"
    class="toast show"
    :class="`toast-${props.type}`"
    role="alert"
  >
    <div class="d-flex">
      <div class="toast-status p-3">
        <BaseIcon
          :icon="icon"
          class="toast-status-icon"
        />
      </div>
      <div class="toast-body py-3 ps-0 pe-3">
        <p class="fw-medium mb-0 fs-6">{{ title }}</p>
        <p
          v-safe-html="props.message"
          class="mb-0 text-secondary"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  toastKey: {
    type: Number,
    default: null,
  },
  message: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'success',
    validator(value) {
      return ['success', 'warning', 'error'].indexOf(value) !== -1;
    },
  },
});
const emit = defineEmits(['mount']);

const { t } = useI18n();
const toast = ref();

onMounted(() => {
  emit('mount', props.toastKey);
});

const icon = computed(() => {
  if (props.type === 'success') return 'fa-solid fa-circle-check';
  if (props.type === 'warning') return 'fa-solid fa-circle-exclamation';
  if (props.type === 'error') return 'fa-solid fa-circle-xmark';
  return 'fa-solid fa-circle-exclamation';
});

const title = computed(() => {
  if (props.type === 'success') return t('ui.alert_status.success');
  if (props.type === 'warning') return t('ui.alert_status.warning');
  if (props.type === 'error') return t('ui.alert_status.error');
  return t('ui.alert_status.warning');
});
</script>
