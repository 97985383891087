import { EmployeesStore } from './employees';
import { NavigationStore } from './navigation';
import { ClientsStore } from './operations/clients';
import { CompaniesStore } from './operations/companies';
import { ContactPersonsStore } from './operations/contactPersons';
import { UsersStore } from './settings/users';
import { ContractsStore } from './operations/contracts';
import { ContractRatesStore } from './operations/contracts/contractRates';
import { ContractFinancingStore } from './operations/contracts/contractFinancing';
import { ContractCertaintyActsStore } from './operations/contracts/contractCertaintyActs';
import { InsurersStore } from './insurers';
import enumsStore from './EnumsStore';
import { createStore } from 'vuex';

const EnumsStore = new enumsStore();

export default createStore({
  state: {},
  getters: {
    loadingStatus: (state) => state.loading,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    loading({ commit }, value) {
      commit('SET_LOADING', value);
    },
  },
  modules: {
    EmployeesStore,
    NavigationStore,
    UsersStore,
    ClientsStore,
    CompaniesStore,
    ContactPersonsStore,
    ContractsStore,
    ContractRatesStore,
    ContractFinancingStore,
    ContractCertaintyActsStore,
    InsurersStore,
    EnumsStore,
  },
});
