<template>
  <BaseButton
    v-can="'f0211b3bf7e54049f444245fb3f8595f1644a4725654cc3e007c2b10e502599d'"
    button-style="danger"
    outline
    @click="onClickAction"
  >
    {{ buttonLabel }}
  </BaseButton>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import { BaseButton } from '@impact-factoring/impact-branding';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  user: {
    type: Object,
    default: () => ({}),
  },
  store: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();

const onClickAction = computed(() => {
  return props.user.banned ? unBan : banUser;
});
const buttonLabel = computed(() => {
  return props.user.banned ? t('user.unblock_user') : t('user.block_user');
});

async function banUser() {
  await apiClient.request('put', `/api/settings/users/${props.user.id}/ban`, ['roles', 'permissions'], {
    banned: true,
  });
  props.store.patchValue('user.banned', true);
}
async function unBan() {
  await apiClient.request('put', `/api/settings/users/${props.user.id}/ban`, ['roles', 'permissions'], {
    banned: false,
  });
  props.store.patchValue('user.banned', false);
}
</script>
