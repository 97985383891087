<template>
  <FormControlWrapper
    v-bind="props"
    :for="labelId"
  >
    <BaseSelect
      v-if="props.canEdit && props.options"
      v-bind="attrs"
      :label-id="labelId"
      :options="props.options"
      :field-name="props.fieldName"
      :errors="props.errors"
      :not-narrow="props.notNarrow"
      :col-width="props.colWidth"
      :model-value="props.modelValue"
      @update:model-value="updateValue"
    />
    <BaseSelect
      v-if="props.canEdit && !props.options"
      v-bind="attrs"
      :label-id="labelId"
      :errors="props.errors"
      :not-narrow="props.notNarrow"
      :col-width="props.colWidth"
      :model-value="props.modelValue"
      @update:model-value="updateValue"
    >
      <slot />
    </BaseSelect>
    <BaseInput
      v-if="!props.canEdit"
      v-bind="attrs"
      :id="labelId"
      type="text"
      disabled
      :not-narrow="props.notNarrow"
      :model-value="props.readonlyValue || props.modelValue"
    />
    <template #extra>
      <slot name="extra" />
    </template>
  </FormControlWrapper>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import { nanoid } from 'nanoid';
import { useAttrs } from 'vue';

const props = defineProps({
  labelContent: {
    type: String,
    required: true,
  },
  postLabel: {
    type: String,
    default: null,
  },
  options: {
    type: [Object, Array],
    default: null,
  },
  fieldName: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  extraColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
  alignLabelCenter: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  notInline: {
    type: Boolean,
    default: false,
  },
  colWidth: {
    type: Boolean,
    default: false,
  },
  notNarrow: {
    type: Boolean,
    default: false,
  },
  tooltipContent: {
    type: String,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  readonlyValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const attrs = useAttrs();
const labelId = nanoid();

function updateValue(modelValue) {
  if (!modelValue) {
    emit('update:modelValue', null);
    return;
  }

  const value = isNaN(modelValue) ? modelValue : parseInt(modelValue);
  emit('update:modelValue', value);
}
</script>
