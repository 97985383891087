<template>
  <DataTable
    :headers="headers"
    :config="config"
  >
    <tr
      v-for="entity in datalist.lists.user.data"
      :key="entity.id"
      class="cursor-pointer"
      @click="router.push({ name: 'edit user', params: { user_id: entity.id } })"
    >
      <td>{{ entity.name }}</td>
      <td>{{ entity.email }}</td>
    </tr>
  </DataTable>
</template>

<script setup>
import DataTable from '@/components/tables/DataTable.vue';
import { useEntitieslistStore } from '@/stores/entitieslist';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();
const datalist = useEntitieslistStore();
const headers = [t('general.name'), t('general.email')];
const config = {
  name: 'user',
};
</script>
