import UsersListView from '@/views/Settings/Users/UsersListView';
import UserFormView from '@/views/Settings/Users/UserFormView';

export default {
  path: 'users',
  meta: {
    breadCrumbLabel: 'users',
  },
  children: [
    {
      path: '',
      name: 'list users',
      component: UsersListView,
      meta: {
        breadCrumbLabel: 'users',
        permission: '1032ae282c396f47d9959df3bcfd782ca4c7ddb14298ac8dabbb3107b77b9187',
      },
    },
    {
      path: 'new',
      name: 'new user',
      component: UserFormView,
      meta: {
        breadCrumbLabel: 'new_user',
        permission: 'f0211b3bf7e54049f444245fb3f8595f1644a4725654cc3e007c2b10e502599d',
      },
    },
    {
      path: ':user_id',
      name: 'edit user',
      component: UserFormView,
      meta: {
        breadCrumbLabel: 'edit_user',
        permission: 'ce311a0b65eb6ca15c6950e215cbaa39b504a561e546af048d2f998b81f60acb',
      },
    },
  ],
};
