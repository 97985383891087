<template>
  <BaseRow v-if="!_.isEmpty(enums.data)">
    <ToastContainer />
    <ConfirmModal
      v-if="useConfirm.show"
      :show="useConfirm.show"
      :title="useConfirm.title"
      :message="useConfirm.message"
      @confirm="confirmModal"
      @close="closeModal"
    />
    <MainNav :collapsed="mainNavCollapsed">
      <BaseAccordion :accordion-parent="navParent">
        <MainNavItem
          v-for="(route, index) in mainRoutes"
          :key="index"
          :route="route"
          :nav-parent="navParent"
          :collapsed="mainNavCollapsed"
          @toggle-main-nav="toggleMainNav"
        />
        <div class="d-flex justify-content-center">
          <BaseIcon
            icon="fa-solid main-nav-icon fa-people-group cursor-pointer fa-sm"
            @click="goToPage('list intermediaries')"
          />
        </div>
      </BaseAccordion>
    </MainNav>
    <BaseColumn class="main-content">
      <HeaderContainer @toggle-main-nav="toggleMainNav" />
      <RouterView />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import MainNavItem from '@/components/navigation/MainNavItem.vue';
import { useConfirmLeavingPage, useGetConfirm, useSetShowConfirm } from '@/composables/UseIsDirty';
import HeaderContainer from '@/components/HeaderContainer.vue';
import { useAuthorizationStore } from '@/stores/authorization';
import { useEnumerationsStore } from '@/stores/enumerations';
import ToastContainer from '@/components/toasts/ToastContainer.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { BaseRow, BaseColumn, MainNav, BaseAccordion } from '@impact-factoring/impact-branding';
import { useRouter, RouterView } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import _ from 'lodash';

const router = useRouter();
const enums = useEnumerationsStore();
const mainNavCollapsed = ref(false);
const useConfirm = useGetConfirm();

onBeforeMount(async () => {
  // todo change fetchProfile request after SSO
  await useAuthorizationStore().fetchProfile();

  if (useAuthorizationStore().profile?.id) {
    await useAuthorizationStore().setCurrentEmployee(useAuthorizationStore().profile.id);
    await enums.fetchEnums([
      'employees',
      'companies',
      'intermediaries',
      'legal_forms',
      'currencies',
      'competences',
      'insurers',
      'average_payment_term_days',
      'countries',
      'contact_types',
    ]);
  }
});

// Data
const mainRoutes = router.options.routes.filter(
  (route) => route.meta?.menutype === 'main' && route.children?.length >= 1
);
const navParent = 'main-nav';

function toggleMainNav() {
  mainNavCollapsed.value = !mainNavCollapsed.value;
}

function confirmModal() {
  useConfirm.value.show = useSetShowConfirm(false);
  useConfirmLeavingPage();
}

function closeModal() {
  useSetShowConfirm(false);
}

function goToPage(routerName) {
  router.push({ name: routerName });
}
</script>
