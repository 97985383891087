<template>
  <PrimaryButton
    v-can="'f0211b3bf7e54049f444245fb3f8595f1644a4725654cc3e007c2b10e502599d'"
    outline
    @click="sendWelcomeEmail"
  >
    {{ $t('user.welcome_email') }}
  </PrimaryButton>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

const props = defineProps({
  user: {
    type: Object,
    default: () => ({}),
  },
});

async function sendWelcomeEmail() {
  await apiClient.request('put', `/api/settings/users/${props.user.id}/invite`);
}
</script>
