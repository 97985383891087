import apiClient from '@/services/ApiClient';
import apiurls from '@/modules/apiurls';
import { useApplicationStore } from '@/stores/application';
import { validateFields } from '@/modules/storeValidator';
import {
  useAmountSuffixConvertAmountToCents,
  useAmountSuffixConvertCentsToAmount,
} from '@/composables/UseNumberManipulation';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

export const useEntityformStore = defineStore('entityform', () => {
  const entities = ref();
  const errors = ref();
  const route = useRoute();
  let originals = {};
  let configs = {};

  /**
   * Patch a value using a dotted path and a new value.
   * @param modelPath
   * @param newValue
   * @returns {Promise<void>}
   */
  async function patchValue(modelPath, newValue) {
    const entityPatch = {};
    let container = entityPatch;

    modelPath.split('.').map((k, i, values) => {
      container = container[k] = i == values.length - 1 ? newValue : {};
    });

    this.$patch({
      entities: entityPatch,
    });
  }

  /**
   * Fetch an entity from the server and return it
   * @returns {Promise<*>}
   * @param config
   * @param entityId
   * @param newEntity
   */
  async function load(config, newEntity, entityId = null) {
    try {
      config.urls = apiurls.getUrls(config.name, entityId);
    } catch (error) {
      useApplicationStore().warning(error);
    }

    configs[config.name] = config;
    let entity = await this.fetchEntityFromServer(config.name, (newEntity || route.meta.newEntity) ?? false);
    entity = useAmountSuffixConvertCentsToAmount(entity);
    this.setEntity(entity, config.name);

    return entity;
  }

  /**
   * Fetch an entity from the server
   * @returns {Promise<any|undefined>}
   * @param entityName
   * @param newEntity
   */
  async function fetchEntityFromServer(entityName, newEntity) {
    return await apiClient.request(
      'get',
      newEntity === true ? configs[entityName].urls.new : configs[entityName].urls.entity,
      { include: configs[entityName].include }
    );
  }

  /**
   * Commit changes to the server
   * @returns {Promise<boolean>}
   */
  async function commit(config) {
    try {
      let entity = this.entities[config.name];
      const method = entity.id === null ? 'post' : 'put';
      const apiUrl = entity.id === null ? configs[config.name].urls.index : configs[config.name].urls.entity;

      entity = useAmountSuffixConvertAmountToCents(entity);

      let savedEntity = await apiClient.request(method, apiUrl, { include: configs[config.name].include }, entity);
      if (savedEntity) {
        savedEntity = useAmountSuffixConvertCentsToAmount(savedEntity);
        this.setEntity(savedEntity, config.name);
      } else {
        entity = useAmountSuffixConvertCentsToAmount(entity);
        this.setEntity(entity, config.name);
      }

      return true;
    } catch (response) {
      this.$patch((state) => {
        state.errors = { [config.name]: response.errors };
      });

      return false;
    }
  }

  /**
   * Cancel changes and set state back to the original
   */
  function cancel(config) {
    this.$patch({ errors: null });
    this.$patch({
      entities: {
        [config.name]: structuredClone(originals[config.name]),
      },
    });
  }

  /**
   * Patch an entity or part of an entity
   * @param entity
   * @param type
   */
  function patch(data, type, entityName) {
    this.$patch({
      [type]: {
        [entityName]: JSON.parse(JSON.stringify(data)),
      },
    });
  }

  /**
   * Set the entity and it's original
   * @param entity
   */
  function setEntity(data, entityName) {
    if (data && entityName) {
      const parsedData = JSON.parse(JSON.stringify(data));
      this.$patch({
        entities: {
          [entityName]: parsedData,
        },
      });
      originals[entityName] = structuredClone(data);
    }
  }

  /**
   * Validate the state
   * @param config
   * @param rules
   * @returns {Promise<boolean|*>}
   */
  async function validate(config, rules) {
    this.$patch({ errors: null });

    const entity = this.entities[config.name];
    const validation = await validateFields(rules, entity);

    if (validation.inputErrors) {
      this.$patch({
        errors: {
          [config.name]: validation.inputErrors,
        },
      });
    }

    return validation.valid;
  }

  function resetErrors() {
    errors.value = null;
  }

  return {
    entities,
    originals,
    errors,
    fetchEntityFromServer,
    commit,
    validate,
    cancel,
    patchValue,
    load,
    setEntity,
    patch,
    resetErrors,
  };
});
