<template>
  <LabelSelect
    v-model="record.person.salutation"
    :options="personSalutations"
    :errors="errorMessages['person.salutation']"
    field-name="value"
    :label-content="$t('contact_persons.salutation')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
  />
  <LabelInput
    v-model="record.person.initials"
    :errors="errorMessages['person.initials']"
    :label-content="$t('contact_persons.initials')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.person.first_name"
    :errors="errorMessages['person.first_name']"
    :label-content="$t('contact_persons.first_name')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.person.last_name"
    :errors="errorMessages['person.last_name']"
    :label-content="$t('contact_persons.last_name')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.contact_detail.phone_primair"
    :errors="errorMessages['contact_detail.phone_primair']"
    :label-content="$t('general.phone')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    text-position="start"
    @enter="handleSave"
  />
  <LabelInput
    v-model="record.contact_detail.email_address"
    :errors="errorMessages['contact_detail.email_address']"
    :label-content="$t('general.email')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
    @enter="handleSave"
  />
  <LabelSelect
    v-model="record.job_title"
    :errors="errorMessages['job_title']"
    :label-content="$t('contact_persons.job_title')"
    :options="jobTitles"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
  />
  <EnumSelect
    v-model="record.person.competence"
    :errors="errorMessages['person.competence']"
    enum-name="competences"
    :label="$t('contact_persons.competence')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
  />
  <MultiselectSearchInput
    v-model="record.contact_types"
    :errors="errorMessages['contact_types']"
    :entities="enums.data['contact_types']"
    :label-content="$t('contact_persons.contact_type')"
    :label-columns="props.labelColumns"
    :input-columns="props.inputColumns"
  />
  <BaseRow>
    <BaseColumn class="col-8">
      <FormFooter
        :pending="loading"
        class="footer-secondary justify-content-end mt-4 pt-1"
        :disabled="_.isEqual(record, originalRecord) || loading"
        @save="handleSave"
        @cancel="handleCancel"
      >
        <template #bodyStart>
          <BaseButton
            v-if="record.id"
            button-style="danger"
            class="me-auto"
            @click="deleteContactPerson"
          >
            {{ $t('ui.remove') }}
          </BaseButton>
        </template>
      </FormFooter>
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import PersonSalutationConstants from '@/configs/constants/PersonSalutations';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import JobTitles from '@/configs/constants/JobTitles';
import { useEnumerationsStore } from '@/stores/enumerations';
import { useSetOriginalProspectRecord, useSetProspectRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import BaseButton from '@/components/buttons/BaseButton.vue';
import { BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { onBeforeMount, ref } from 'vue';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  initialValidationRules: {
    type: Object,
    default: () => ({}),
  },
  companyId: {
    type: Number,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['save', 'update', 'delete', 'beforeMount']);

const { t } = useI18n();
const enums = useEnumerationsStore();
const jobTitles = JobTitles;

const record = ref(props.initialRecord);
const originalRecord = ref({});
const loading = ref(false);
const formId = nanoid();
const errorMessages = ref([]);
let validationRules = [];

const personSalutations = Object.entries(PersonSalutationConstants).map((entry) => {
  return { id: entry[0], value: entry[1] };
});

onBeforeMount(async () => {
  emit('beforeMount', formId);
  record.value = useSetProspectRecord(record.value, formId);
  originalRecord.value = useSetOriginalProspectRecord(record.value, formId);

  if (record.value.id) {
    validationRules = useMergeDefaultVuelidateValidationRules(props.initialValidationRules, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules, record.value);
  }
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules = useMergeDefaultVuelidateValidationRules(props.initialValidationRules, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;
    let recordCopy = structuredClone(record.value);

    recordCopy = {
      contact_detail: recordCopy.contact_detail,
      contact_types: recordCopy.contact_types,
      job_title: recordCopy.job_title,
      ...recordCopy.person,
    };

    let savedContactPerson;
    let response;
    if (!record.value.id) {
      response = await saveContactPerson(recordCopy);
      useSetToast('success', t('toast.success.contact_person_successfully_created'));
      response.contact_persons = mapContactPersons(response.contact_persons);
      emit('save', { customerLogs: response.customer_log, contactPersons: response.contact_persons });
      savedContactPerson = response.contact_persons[response.contact_persons.length - 1];
    } else {
      response = await updateContactPerson(recordCopy, record.value.person.id);
      useSetToast('success', t('toast.success.contact_person_successfully_updated'));
      response.contact_persons = mapContactPersons(response.contact_persons);
      emit('update', {
        customerLogs: response.customer_log,
        contactPersons: response.contact_persons,
        offerPreview: response.offer_preview,
        creditProposalPreview: response.credit_proposal_preview,
      });
      savedContactPerson = response.contact_persons.find((contactPerson) => contactPerson.id === record.value.id);
    }

    record.value = useSetProspectRecord(savedContactPerson, formId);
    originalRecord.value = useSetOriginalProspectRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    if (!record.value.id) {
      useSetToast(
        'error',
        t('toast.error.creating_the_contact_person') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while creating contact person: ', error);
    } else {
      useSetToast(
        'error',
        t('toast.error.updating_the_contact_person') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while updating contact person: ', error);
    }
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetProspectRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function saveContactPerson(data) {
  return apiClient.request('post', `/ifapi/companies/${props.companyId}/contactpersons`, null, data);
}
function updateContactPerson(data, personId) {
  return apiClient.request('put', `/ifapi/companies/${props.companyId}/contactpersons/${personId}`, null, data);
}
function deleteContactPerson() {
  emit('delete');
}

function mapContactPersons(contactPersons) {
  return contactPersons.map((contactPerson) => {
    contactPerson.person.competence = contactPerson.person.competence?.competence;
    return contactPerson;
  });
}
</script>
