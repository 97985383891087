<template>
  <ViewSpacing class="vh-100 bg-primary-50 d-flex justify-content-center align-items-center">
    <BaseSpinner type="primary" />
  </ViewSpacing>
</template>

<script setup>
import ViewSpacing from '@/components/grid/ViewSpacing.vue';
import { useAuthorizationStore } from '@/stores/authorization';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { onMounted } from 'vue';

const auth = useAuthorizationStore();

onMounted(async () => {
  await auth.logout();
  window.location = '/';
});
</script>

<style scoped>
.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
