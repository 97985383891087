import MainView from '@/views/Router/MainView';
import SalesStatus from '@/router/sales/sales-status';

export default {
  path: '/sales',
  component: MainView,
  redirect: { name: 'list sales statuses' },
  meta: {
    breadCrumbLabel: 'sales',
    icon: 'dollar-sign',
    menutype: 'main',
  },
  children: [{ ...SalesStatus }],
};
