<template>
  <input
    v-model="computedModelValue"
    class="form-check-input"
    type="checkbox"
    :checked="props.checked"
  />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Boolean, Number],
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
  get() {
    return !!props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
