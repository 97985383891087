<template>
  <div v-if="ready">
    <FormControlWrapper
      :label-content="label"
      :tooltip-content="tooltipContent"
    >
      <ConcattedList
        attribute="name"
        :list="checkboxValue"
        :caption-edit="$t('ui.edit')"
        :caption-add="$t('ui.add')"
        @add-edit="toggleModal"
      />
      <div
        v-show="errors && errors.length"
        class="form-text text-danger mt-3 error-messages"
      >
        {{ errors.join('\r\n') }}
      </div>
    </FormControlWrapper>
  </div>
  <BaseModal
    :show="modal"
    :modal-title="modalTitle"
    scrollable
    custom-footer
    @close="toggleModal"
  >
    <div
      v-for="enumItem in enums.data[props.enumName]"
      :key="enumItem.id"
      class="form-check"
    >
      <input
        :id="`enumItem-${enumItem.id}`"
        v-model="checkboxValue"
        v-bind="$attrs"
        class="form-check-input"
        type="checkbox"
        :value="enumItem"
      />
      <label
        class="form-check-label"
        :for="`enumItem-${enumItem.id}`"
      >
        {{ enumItem.name }}
      </label>
    </div>
    <template #modalFooter>
      <PrimaryButton @click.prevent="toggleModal">
        {{ $t('ui.close') }}
      </PrimaryButton>
    </template>
  </BaseModal>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '../form/FormControlWrapper.vue';
import ConcattedList from '@/components/ConcattedList.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import BaseModal from '@/components/BaseModal.vue';
import { PrimaryButton } from '@impact-factoring/impact-branding';
import { onMounted, ref } from 'vue';

const props = defineProps({
  enumName: {
    type: String,
    default: '',
  },
  optionsType: {
    type: String,
    default: 'object',
  },
  label: {
    type: String,
    default: '',
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: [Array],
    default: () => [],
  },
  modalTitle: {
    type: String,
    default: '',
  },
  enumUrl: {
    type: String,
    default: '',
  },
});

const ready = ref(false);
const modal = ref(false);
const enums = useEnumerationsStore();
const checkboxValue = ref(props.modelValue);

onMounted(async () => {
  if (!props.enumUrl) {
    await enums.fetchEnum(props.enumName, {
      withEmpty: false,
      optionsType: props.optionsType,
    });
  } else {
    await enums.fetchCustomEnums(props.enumUrl, props.enumName);
  }
  ready.value = true;
});

function toggleModal() {
  return (modal.value = !modal.value);
}
</script>
