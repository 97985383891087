<template>
  <OverviewTable class="table-intermediary-notes">
    <template #headers>
      <th @click="handleToggleColumn('employee')">
        {{ t('general.employee') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.employee }"
          icon="fa-solid fa-sort"
        />
      </th>
      <th @click="handleToggleColumn('updated_at')">
        {{ t('sales.list.updated') }}
        <BaseIcon
          :class="{ active: toggle.key === toggle.keys.updated_at }"
          icon="fa-solid fa-sort"
        />
      </th>
    </template>
    <template
      v-for="note in computedFilteredRecord"
      :key="note.id"
    >
      <tr
        class="cursor-pointer"
        @click="showNote(note.id)"
      >
        <td class="text-truncate">
          {{ note.employee }}
        </td>
        <td class="text-truncate">
          {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(note.updated_at)) }}
        </td>
      </tr>
      <tr
        v-if="computedShowNoteId === note.id"
        class="note"
      >
        <td
          colspan="2"
          class="cursor-pointer"
          @click="editNote(note)"
        >
          {{ note.description }}
        </td>
      </tr>
    </template>
  </OverviewTable>
</template>

<script setup>
import OverviewTable from '@/components/tables/OverviewTable.vue';
import BaseIcon from '@/components/general/BaseIcon.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import { toLocaleDateString } from '@/modules/formatters';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import dayjs from 'dayjs';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  initialShowNoteId: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['edit', 'show']);

const { t } = useI18n();
const enums = useEnumerationsStore();

const record = ref(props.initialRecord);
const toggle = {
  key: 'updated_at',
  previousValue: 'updated_at',
  sortBy: 'desc',
  keys: {
    employee: 'employee',
    updated_at: 'updated_at',
  },
};

const computedFilteredRecord = computed(() => {
  const prospects = mapIntermediaryProspects(record.value.notes);
  return _.orderBy(prospects, toggle.key, toggle.sortBy);
});

const computedShowNoteId = computed(() => {
  return props.initialShowNoteId;
});

onBeforeMount(() => {
  toggleColumn();
});

function mapIntermediaryProspects() {
  return record.value.notes.map((note) => {
    return {
      id: note.id,
      employee: note?.employee ? note?.employee : getEmployeeName(note.employee_id),
      employee_id: note.employee_id,
      description: note.description,
      updated_at: note.updated_at,
      // timestamp: dayjs(note.updated_at).valueOf(),
    };
  });
}

function handleToggleColumn(key) {
  toggle.key = key;

  if (toggle.sortBy === 'desc') {
    toggle.sortBy = 'asc';

    toggleColumn();
    return;
  }

  if (toggle.key === toggle.previousValue) {
    toggle.sortBy = 'desc';
  }

  toggleColumn();
}

function toggleColumn() {
  toggle.previousValue = toggle.key;
  record.value.notes = _.orderBy(record.value.notes, toggle.key, toggle.sortBy);
}

function getEmployeeName(employeeId) {
  if (!employeeId) {
    return;
  }
  return Object.entries(enums.data.employees).find((employee) => parseInt(employee[0]) === parseInt(employeeId))[1];
}

function showNote(noteId) {
  emit('show', noteId);
}

function editNote(note) {
  emit('edit', note);
}
</script>
