<template>
  <BaseRow class="vh-100 bg-primary-50">
    <BaseColumn class="align-self-center">
      <LoginForm
        v-model:email-value="auth.email"
        v-model:password-value="password"
        v-bind="formMeta"
        @on-login="login"
      />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import { useAuthorizationStore } from '@/stores/authorization';
import { BaseRow, BaseColumn, LoginForm } from '@impact-factoring/impact-branding';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const auth = useAuthorizationStore();
const password = ref();
const formMeta = ref({
  labelEmail: 'E-mailadres',
  labelPassword: 'Wachtwoord',
  labelPasswordForgotten: 'Wachtwoord vergeten?',
  labelSubmit: 'Inloggen',
  passwordForgottenUrl: 'password-forgotten',
  alert: {
    message: null,
  },
});

async function login() {
  try {
    await auth.login(auth.email, password.value);
    router.push({ name: 'dashboard' });
  } catch (error) {
    formMeta.value.alert = error;
    formMeta.value.alert.type = 'warning';
  }
}
</script>
