import i18n from '@/lang/i18n-config';

const { t } = i18n.global;

const DIRECTOR = t('constants.job_titles.director');
const DIRECTOR_SHAREHOLDER = t('constants.job_titles.director_and_shareholder');
const ADMINISTRATION = t('constants.job_titles.administration');

export { DIRECTOR, DIRECTOR_SHAREHOLDER, ADMINISTRATION };
export default { DIRECTOR, DIRECTOR_SHAREHOLDER, ADMINISTRATION };
