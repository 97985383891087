import router from '@/router';

const apiUrls = {
  login: '/login',
  logout: '/logout',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  profile: '/api/profile',
  contract: {
    entity: `/ifapi/clients/:client_id/contracts/:contract_id`,
  },
  contract_rate: {
    entity: '/ifapi/clients/:client_id/contracts/:contract_id/rate',
  },
  contract_purchase_financing: {
    entity: `/ifapi/clients/:client_id/contracts/:contract_id/financing`,
  },
  company_shareholders: {
    index: `/ifapi/clients/:client_id/company/shareholders/`,
  },
  client_companies: {
    entity: `/ifapi/clients/:client_id/companies/:company_id`,
    index: `/ifapi/clients/:client_id/companies/`,
  },
  company: {
    entity: `/ifapi/companies/:company_id`,
    new: `/ifapi/companies/new`,
  },
  client_signer_persons: {
    index: `/ifapi/clients/:client_id/signerpersons`,
    entity: `/ifapi/clients/:client_id/signerpersons/:person_id`,
  },
  user: {
    index: `/api/settings/users`,
    entity: `/api/settings/users/:user_id`,
  },
  role: {
    index: `/api/settings/authorization/roles`,
    new: `/api/settings/authorization/roles/new`,
    entity: `/api/settings/authorization/roles/:role_id`,
  },
  permissions: {
    index: `/api/settings/authorization/permissions/`,
  },
  sales_status: {
    index: '/ifapi/sales_statuses',
    entity: '/ifapi/sales_statuses/:sales_status_id',
    change_sales_status: '/ifapi/sales_statuses/:sales_status_id/change_sales_status',
    change_sales_progress: '/ifapi/sales_statuses/:sales_status_id/change_sales_progress',
    change_sales_abort: '/ifapi/sales_statuses/:sales_status_id/change_sales_abort',
    logs: '/ifapi/sales_statuses/:sales_status_id/logs',
    statuses: '/ifapi/sales_statuses/:sales_status_id/statuses',
  },
  offer: {
    entity: '/ifapi/sales_statuses/:sales_status_id/offer',
    preview: '/ifapi/sales_statuses/:sales_status_id/offer/preview',
    pdf: '/ifapi/sales_statuses/:sales_status_id/offer/pdf',
    certainties: '/ifapi/sales_statuses/:sales_status_id/offer/certaintyacts/available',
    document: '/ifapi/sales_statuses/:sales_status_id/offer/document',
  },
};

export default {
  getUrls(name, entityId = null) {
    if (apiUrls[name] === undefined) {
      throw `Api url met naam '${name}' bestaat niet.`;
    }

    let urls = {};

    for (const key in apiUrls[name]) {
      urls[key] = this.getUrl(name, key, entityId);
    }

    return urls;
  },

  getUrl(name, key, entityId) {
    if (apiUrls[name] === undefined) {
      throw `Api url met naam '${name}' bestaat niet.`;
    }

    let url;

    if (typeof apiUrls[name] === 'object' && key !== undefined) {
      url = apiUrls[name][key];
    } else {
      url = apiUrls[name];
    }

    /*
     * Get the params of the url (the ':param_name' parts)
     */
    const params = url.match(/:[a-z_]+_id/g);

    for (const p in params) {
      let param = params[p];
      let paramName = param.substring(1);
      /*
       * Read the value of the :param_name from the currentRoute
       * Replace the :param_name with its value
       */
      let id = router.currentRoute.value.params[paramName] || entityId;
      /*
       * Replace additional ids if they are not part of the route params
       */
      url = url.replace(param, id);
    }

    return url;
  },
};
