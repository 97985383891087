<template>
  <slot />
  <FormFooter
    :valid="valid"
    :pending="pending"
    :disabled="!applicationStore.isDirtyForm[formId]"
    @save="submit"
    @cancel="cancel"
  >
    <slot name="footer-extra" />
  </FormFooter>
</template>

<script setup>
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { setIsDirtyForm, resetIsDirtyForm } from '@/composables/UseDirtyFormState';
import { useApplicationStore } from '@/stores/application';
import { useEntityformStore } from '@/stores/entityform';
import { onMounted, onBeforeUnmount, ref, provide } from 'vue';
import { nanoid } from 'nanoid';

const formId = nanoid();
provide('formId', formId);

const props = defineProps({
  rules: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    required: true,
  },
  newEntity: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['submitted', 'load']);

const pending = ref(false);
const valid = ref(true);
const store = useEntityformStore();
const applicationStore = useApplicationStore();

onMounted(async () => {
  if (props.config.load) {
    await store.load(props.config, props.newEntity);
    emit('load');
  }
  if (!props.newEntity) {
    await store.validate(props.config, props.rules);
  }

  setIsDirtyForm(formId, false);
});

onBeforeUnmount(() => {
  store.resetErrors();
  resetIsDirtyForm();
});

async function submit() {
  valid.value = await store.validate(props.config, props.rules);

  if (!valid.value) {
    return;
  }

  pending.value = true;
  valid.value = await store.commit(props.config);
  pending.value = false;

  if (valid.value) {
    emit('submitted');
    setIsDirtyForm(formId, false);
  }
}

function cancel() {
  store.cancel(props.config);
  valid.value = true;
  setIsDirtyForm(formId, false);
}
</script>
