import RolesListView from '@/views/Settings/RolesAndPermissions/RolesListView';
import RoleFormView from '@/views/Settings/RolesAndPermissions/RoleFormView';

export default {
  path: 'roles',
  meta: {
    breadCrumbLabel: 'roles',
  },
  children: [
    {
      path: '',
      name: 'list roles',
      component: RolesListView,
      meta: {
        breadCrumbLabel: 'roles',
        permission: 'af9101c67a1c7ca757ca42cb361f26fddc837aabc0776c5c7a54756e09346318',
      },
    },
    {
      path: 'new',
      name: 'new role',
      component: RoleFormView,
      meta: {
        newEntity: true,
        breadCrumbLabel: 'new_role',
        permission: '14889325d421c04d3873a59a4d79900769a1c94040ef82b91881ed5dc1b40ca5',
      },
    },
    {
      path: ':role_id',
      name: 'edit role',
      component: RoleFormView,
      meta: {
        breadCrumbLabel: 'edit_role',
        permission: '14889325d421c04d3873a59a4d79900769a1c94040ef82b91881ed5dc1b40ca5',
      },
    },
  ],
};
