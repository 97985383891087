import router from '@/router';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useNavigationStore = defineStore('navigation', () => {
  const breadCrumbs = ref([]);
  const customBreadCrumbContent = ref();
  const { t } = useI18n();

  function setBreadCrumbs(customBreadCrumbLabelContent = '') {
    breadCrumbs.value = [
      {
        route: 'dashboard',
        title: t('navigation.home'),
        focus: false,
      },
    ];

    let processedRoutes = [];
    const currentRoute = router.currentRoute.value;
    const routes = currentRoute.matched;

    for (const route of routes) {
      if (processedRoutes.includes(route.path) || route.path === '/') {
        continue;
      }

      if (route.meta.hasCustomBreadCrumbLabel && customBreadCrumbLabelContent) {
        route.meta.customBreadCrumbLabel = customBreadCrumbLabelContent;
      }

      breadCrumbs.value.push({
        route: route.name ?? route.children[0].name,
        title: route.meta.customBreadCrumbLabel ?? t(`navigation.${route.meta.breadCrumbLabel}`),
        focus: !!route.meta.customBreadCrumbLabel,
      });

      processedRoutes.push(route.path);
    }

    return breadCrumbs.value;
  }

  return { breadCrumbs, customBreadCrumbContent, setBreadCrumbs };
});
