<template>
  <BaseSpinner
    v-if="loading"
    type="primary"
  />
  <CreditProposalAssessmentCommitteeOverview
    v-else-if="record"
    :initial-record="record"
    :contact-persons="computedContactPersons"
    :revision-record="revisionRecord"
    :document-files="customerDocumentFiles"
    @save="updateLogbook"
  />
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import CreditProposalAssessmentCommitteeOverview from '@/forms/sales/sales-status/credit-proposal/CreditProposalAssessmentCommitteeOverview.vue';
import { useSetToast } from '@/composables/UseToast';
import { useSetAndMergeSalesStatusObject } from '@/composables/records/UseAssessmentCommittee';
import { useAmountSuffixConvertCentsToAmount } from '@/composables/UseNumberManipulation';
import {
  useGetCompany,
  useGetCreditProposalPreview,
  useSetCreditProposalPreview,
  useSetLogbookItems,
} from '@/composables/UseProspectViewData';
import { BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const record = ref(null);
const companyRecord = ref(null);
const revisionRecord = ref([]);
const customerDocumentFiles = ref(null);
const loading = ref(false);

const computedContactPersons = computed(() => {
  return structuredClone(companyRecord.value).contact_persons.find(
    (contactPerson) => contactPerson.person.id === record.value.data.sales_status?.credit_proposal?.person?.id
  );
});

watch(
  () => router.currentRoute.value,
  (to, from) => {
    if (from.meta.preview !== 'credit_proposal') {
      getAndSetCreditProposalPreview();
    }
    if (to.meta.preview !== 'credit_proposal') {
      useSetCreditProposalPreview('');
    }
  }
);

onBeforeMount(async () => {
  try {
    loading.value = true;
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/assessment_committee`
    );

    record.value.revision = record.value.revision
      ? structuredClone(useAmountSuffixConvertCentsToAmount(record.value.revision))
      : {};
    revisionRecord.value = structuredClone(record.value.revision);
    companyRecord.value = useGetCompany();
    customerDocumentFiles.value = record.value.data.sales_status?.customer_documents?.files;

    record.value.sales_status = structuredClone(
      useAmountSuffixConvertCentsToAmount(useSetAndMergeSalesStatusObject(record.value.data))
    );
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_assessment_committee') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal assessment committee data: ', error);
  } finally {
    loading.value = false;
  }

  if (!useGetCreditProposalPreview()) {
    await getAndSetCreditProposalPreview();
  }
});

async function getAndSetCreditProposalPreview() {
  try {
    const creditProposalPreviewRecord = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/preview`
    );
    useSetCreditProposalPreview(creditProposalPreviewRecord);
  } catch (error) {
    useSetToast(
      'error',
      t('toast.error.retrieving_credit_proposal_preview') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching credit proposal preview: ', error);
  }
}

function updateLogbook(object) {
  useSetLogbookItems(object.customerLogs);
}
</script>
