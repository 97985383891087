import api from '@/services/ApiClient';
import BaseStore from '@/store/BaseStore';

const store = new BaseStore([], ['roles', 'permissions']);

const UsersStore = {
  namespaced: store.namespaced,
  state: { ...store.state },
  mutations: { ...store.mutations },
  actions: {
    ...store.actions,
    async sendWelcomeEmail({ commit }, data) {
      const result = await api.request('put', `/api/settings/users/${data.user.id}/invite`, ['roles', 'permissions']);
      commit('SET_ENTITY', result.data);

      return result.data;
    },
    async banUser({ commit }, data) {
      const result = await api.request('put', `/api/settings/users/${data.user.id}/ban`, ['roles', 'permissions'], {
        banned: data.banned,
      });
      commit('SET_ENTITY', result.data);

      return result.data;
    },
  },
  getters: {
    ...store.getters,
    getEmployeeById: (state) => (id) => {
      if (!state.list.data || !id) return console.error('data and/or id is not set');
      return state.list.data.find((employee) => employee.id === id);
    },
  },
};

export { UsersStore };
