<template>
  <ConfirmModal
    v-if="showConfirmModal"
    :show="showConfirmModal"
    @confirm="confirmModal"
    @close="toggleShowConfirmModal"
  >
    {{ t('modal.warning_text_unsaved_changes_when_leaving_page') }}
  </ConfirmModal>
  <BaseRow>
    <PageNav
      v-if="route.params?.sales_status_id"
      :prospect-status="prospectStatusRecord"
    />
    <BaseColumn class="page-content">
      <ViewSpacing
        class="pb-0"
        classes="h-100"
      >
        <div class="d-flex flex-column h-100">
          <StatusSelector
            v-if="record?.sales_status"
            :initial-record="record?.sales_status"
            :prospect-status="prospectStatusRecord"
            @save="setLogbook"
          />
          <div class="sidebar-container">
            <LogbookSidebar
              :initial-record="logbookRecord?.sales_status"
              @before-mount="setLogbookFormId"
            />
            <ContactPersonsSidebar
              :record="companyRecord"
              @save="setContactPersonsAndLogbookAndPreview"
              @before-mount="setContactPersonFormId"
            />
            <OfferPreviewSidebar
              v-if="showOfferPreview"
              :offer-preview-record="computedOfferPreview"
              @save="setLogbook"
              @download="downloadOfferPDF"
              @send-email="toggleEmailOfferModal"
            />
            <CreditProposalPreviewSidebar
              v-if="showCreditProposalPreview"
              :credit-proposal-preview-record="computedCreditProposalPreview"
              @download="downloadCreditProposalPDF"
            />
          </div>
          <SendOfferEmailModal
            v-if="showOfferPreview && showEmailOfferModal"
            :show="showEmailOfferModal"
            :contact-persons="computedContactPersonsEmailAddress"
            @save="handleSaveOfferEmailModal"
            @close="toggleEmailOfferModal"
          />
          <div
            class="d-flex flex-column h-100"
            :class="{ 'px-5 mx-4': route.meta.menutype !== 'tab' && route.name !== 'organogram structure' }"
          >
            <RouterView v-if="record?.sales_status" />
          </div>
        </div>
      </ViewSpacing>
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import MenuService from '@/services/MenuService';
import StatusSelector from '@/views/Sales/SalesStatus/StatusSelector.vue';
import apiClient from '@/services/ApiClient';
import {
  useGetCreditProposalPreview,
  useGetOfferPreview,
  useIsOwner,
  useSetCompanyRef,
  useSetCreditProposalPreview,
  useSetLogbookItems,
  useSetLogbookRef,
  useSetOfferPreview,
  useSetProspectStatusRef,
} from '@/composables/UseProspectViewData';
import LogbookSidebar from '@/components/sales/LogbookSidebar.vue';
import { useSetToast } from '@/composables/UseToast';
import ViewSpacing from '@/components/grid/ViewSpacing.vue';
import PageNav from '@/components/navigation/PageNav.vue';
import ContactPersonsSidebar from '@/components/sales/ContactPersonsSidebar.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { useGetOriginalProspectRecord, useGetProspectRecord, useRemoveProspectFormId } from '@/composables/UseIsDirty';
import OfferPreviewSidebar from '@/components/sidebar/OfferPreviewSidebar.vue';
import SendOfferEmailModal from '@/views/Sales/SalesStatus/Offer/SendOfferEmailModal.vue';
import { useDownload } from '@/composables/UseDownload';
import { generateFileName } from '@/modules/utils';
import CreditProposalPreviewSidebar from '@/forms/sales/sales-status/credit-proposal/CreditProposalPreviewSidebar.vue';
import { CREDIT_PROPOSAL, OFFER } from '@/configs/constants/Categories';
import { ref, onBeforeMount, computed } from 'vue';
import { useRoute, RouterView, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { BaseRow, BaseColumn } from '@impact-factoring/impact-branding';
import _ from 'lodash';

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const tabs = ref([]);
const parentRoute = ref(MenuService.findInRoute('tabs'));
const record = ref(null);
const logbookRecord = ref({});
const companyRecord = ref({});
const prospectStatusRecord = ref({});
const showConfirmModal = ref(false);
const contactPersonFormId = ref(null);
const logbookFormId = ref(null);
const showEmailOfferModal = ref(false);
let routerTo = null;

const computedContactPersonsEmailAddress = computed(() => {
  return getKeyValueContactPersonsEmailAddress(companyRecord.value.contact_persons);
});

const computedOfferPreview = computed(() => {
  return useGetOfferPreview();
});

const computedCreditProposalPreview = computed(() => {
  return useGetCreditProposalPreview();
});

const showOfferPreview = computed(() => {
  return route.meta?.preview === 'offer';
});

const showCreditProposalPreview = computed(() => {
  return route.meta?.preview === 'credit_proposal';
});

onBeforeMount(async () => {
  if (parentRoute.value) {
    tabs.value = MenuService.filterTabsFromParentRoute(parentRoute);
  }
  try {
    record.value = await apiClient.request(
      'get',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}`
    );

    record.value.company.contact_persons = mapContactPersons();
    record.value = { sales_status: record.value };

    companyRecord.value = structuredClone(record.value).sales_status.company;
    logbookRecord.value = structuredClone(record.value);
    prospectStatusRecord.value = {
      credit_proposal: {
        id: record.value?.credit_proposal?.id || null,
        owner: useIsOwner(record.value?.sales_status?.credit_proposal?.evaluations),
        status: structuredClone(record.value).sales_status?.credit_proposal?.status || null,
      },
    };

    useSetLogbookRef(logbookRecord);
    useSetCompanyRef(companyRecord);
    useSetProspectStatusRef(prospectStatusRecord);
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_prospect_data') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching sales status data: ', error);
  }
});

onBeforeRouteLeave((to, from, next) => {
  const isEqual =
    _.isEqual(
      useGetProspectRecord(contactPersonFormId.value),
      useGetOriginalProspectRecord(contactPersonFormId.value)
    ) && _.isEqual(useGetProspectRecord(logbookFormId.value), useGetOriginalProspectRecord(logbookFormId.value));

  if (!isEqual) {
    routerTo = to;
    toggleShowConfirmModal();
    return;
  }
  next();
});

function setLogbook(object) {
  useSetLogbookItems(object);
}

function setContactPersonsAndLogbookAndPreview(object) {
  companyRecord.value.contact_persons = object.contactPersons;
  useSetCompanyRef(companyRecord);
  useSetLogbookItems(object.customerLogs);
  if (object?.offerPreview) {
    useSetOfferPreview(object.offerPreview);
  }
  if (object?.creditProposalPreview) {
    useSetCreditProposalPreview(object.creditProposalPreview);
  }
}

function confirmModal() {
  useRemoveProspectFormId(contactPersonFormId.value);
  useRemoveProspectFormId(logbookFormId.value);
  if (routerTo) {
    router.push(routerTo);
  }
  toggleShowConfirmModal();
}

function toggleShowConfirmModal() {
  showConfirmModal.value = !showConfirmModal.value;
}

function setContactPersonFormId(formId) {
  contactPersonFormId.value = formId;
}

function setLogbookFormId(formId) {
  logbookFormId.value = formId;
}

function mapContactPersons() {
  return structuredClone(record.value.company.contact_persons).map((contactPerson) => {
    contactPerson.person.competence = contactPerson.person.competence?.competence || contactPerson.person.competence;
    return contactPerson;
  });
}

function getKeyValueContactPersonsEmailAddress(object) {
  if (!object) {
    return;
  }
  const persons = {};
  structuredClone(object).forEach((contactPerson) => {
    if (contactPerson?.person?.id && contactPerson?.contact_detail?.email_address) {
      persons[contactPerson.person.id] = contactPerson.contact_detail.email_address;
    }
  });
  return persons;
}

function handleSaveOfferEmailModal(object) {
  setLogbook(object);
  toggleEmailOfferModal();
}

function toggleEmailOfferModal() {
  showEmailOfferModal.value = !showEmailOfferModal.value;
}

async function downloadOfferPDF() {
  await useDownload(
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/pdf`,
    generateFileName(record.value.sales_status.id, OFFER.toLowerCase())
  );
}

async function downloadCreditProposalPDF() {
  await useDownload(
    `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/download/pdf`,
    generateFileName(record.value.sales_status.id, CREDIT_PROPOSAL.toLowerCase())
  );
}
</script>
