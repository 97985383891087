<template>
  <ViewSpacing>
    <UserForm :config="config" />
  </ViewSpacing>
</template>

<script setup>
import ViewSpacing from '@/components/grid/ViewSpacing.vue';
import UserForm from '@/forms/settings/users/UserForm.vue';
import { useApplicationStore } from '@/stores/application';
import { useEntityformStore } from '@/stores/entityform';
import { onMounted } from 'vue';

const form = useEntityformStore();

const config = {
  name: 'user',
  include: ['roles', 'permissions'],
};

onMounted(async () => {
  try {
    await form.load(config);
  } catch (error) {
    useApplicationStore().warning(error);
  }
});
</script>
