import MainView from '@/views/Router/MainView';
import Users from '@/router/settings/users';
import Roles from '@/router/settings/roles';

export default {
  path: '/settings',
  component: MainView,
  redirect: { name: 'list users' },
  meta: {
    breadCrumbLabel: 'settings',
    icon: 'gear',
    menutype: 'main',
  },
  children: [{ ...Users }, { ...Roles }],
};
