import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApplicationStore = defineStore('application', () => {
  const messages = ref([]);
  const isDirty = ref(false);
  const isDirtyForm = ref({});

  // messages/warnings
  function warning(message) {
    this.$patch((state) => {
      const existing = state.messages.map((e) => e.message).indexOf(message);

      if (-1 === existing) {
        state.messages.push({
          message: message,
          type: 'warning',
        });
      }
    });
  }

  // dirty state
  function setIsDirtyForm(formId, state = false) {
    if (!formId) {
      return;
    }

    isDirtyForm.value[formId] = state;
    isDirty.value = state;
  }

  function resetIsDirtyForm(formId) {
    if (formId && formId in isDirtyForm.value) {
      delete isDirtyForm.value[formId];
      return;
    }

    isDirtyForm.value = {};
  }

  function checkIsDirtyState() {
    return (isDirty.value = Object.values(isDirtyForm.value).some((state) => state === true));
  }

  return {
    messages,
    warning,
    isDirty,
    isDirtyForm,
    setIsDirtyForm,
    resetIsDirtyForm,
    checkIsDirtyState,
  };
});
