import BaseStore from '@/store/BaseStore';
import api from '@/services/ApiClient';

const store = new BaseStore(['contract_type'], ['insurance', 'debtor_collection_bank_account.bank', 'contract_type']);

const ContractsStore = {
  namespaced: store.namespaced,
  state: {
    ...store.state,
    contract_types: [],
  },
  mutations: {
    ...store.mutations,
    SET_CONTRACT_TYPES(state, payload) {
      state.contract_types = payload;
    },
  },
  actions: {
    ...store.actions,
    async fetchContractTypes({ commit }, url) {
      const data = await api.request('get', url);
      commit('SET_CONTRACT_TYPES', data);

      return data;
    },
  },
};

export { ContractsStore };
