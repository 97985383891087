<template>
  <BaseModal
    :show="showModal"
    :modal-title="$t('modal.add_intermediary')"
    min-height="530px"
    custom-footer
    modal-dialog-classes="creditsafe-debtor-modal"
    @close="closeModal"
  >
    <div class="creditsafe-modal-subtitle pt-2 mb-2">{{ $t('modal.person') }}</div>
    <LabelSelect
      v-model="record.person.salutation"
      :options="personSalutations"
      :errors="errorMessages['person.salutation']"
      field-name="value"
      :label-content="$t('contact_persons.salutation')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
    />
    <LabelInput
      v-model="record.person.initials"
      :errors="errorMessages['person.initials']"
      :label-content="$t('contact_persons.initials')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.person.first_name"
      :errors="errorMessages['person.first_name']"
      :label-content="$t('contact_persons.first_name')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.person.last_name"
      :errors="errorMessages['person.last_name']"
      :label-content="$t('contact_persons.last_name')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.person.contact_detail.phone_primair"
      :errors="errorMessages['person.contact_detail.phone_primair']"
      :label-content="$t('general.mobile')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.person.contact_detail.phone_secundair"
      :errors="errorMessages['person.contact_detail.phone_secundair']"
      :label-content="$t('general.phone')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.person.contact_detail.email_address"
      :errors="errorMessages['person.contact_detail.email_address']"
      :label-content="$t('general.email')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <div class="creditsafe-modal-subtitle mt-5 pt-2 mb-2">{{ $t('modal.creditsafe_retrieve_company') }}</div>
    <MultiselectSearchInput
      v-model="creditsafeRecord.countries"
      :entities="countriesKeyValue"
      :label-content="$t('address.country')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :tooltip-content="$t('tooltip.creditsafe_select_all_countries')"
      :errors="creditsafeErrorMessages['countries']"
    />
    <LabelInput
      v-model="creditsafeRecord.regNo"
      :label-content="$t('general.company_registration_number')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :errors="creditsafeErrorMessages['company_registration_number']"
      :disabled="!!creditsafeRecord.vatNo"
    />
    <LabelInput
      v-model="creditsafeRecord.vatNo"
      :label-content="$t('general.vat_number')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :disabled="!!creditsafeRecord.regNo"
    />
    <div
      v-if="creditsafeErrorMessages['record.all']"
      class="form-text text-danger error-messages mt-4"
    >
      {{ creditsafeErrorMessages['record.all'][0] }}
    </div>
    <BaseRow>
      <BaseColumn class="col-8">
        <div class="d-flex footer-secondary justify-content-end mt-3">
          <PrimaryButton
            :loading="loadingCompanies"
            @click="handleFetchCreditsafeCompanies"
          >
            {{ $t('general.retrieve') }}
          </PrimaryButton>
        </div>
      </BaseColumn>
    </BaseRow>
    <CreditsafeSearchCompaniesResults
      v-if="creditsafeFetchedCompanies.length > 1"
      :record="creditsafeFetchedCompanies"
      :loading="loadingGetCreditsafeCompany"
      @submit="handleFetchCreditsafeCompany"
    />
    <div class="creditsafe-modal-subtitle mt-4 pt-2 mb-2">{{ $t('modal.select_company') }}</div>
    <SearchInput
      v-model="companyId"
      field-name="id"
      :entities="computedCompaniesKeyValue"
      :label-content="$t('general.company_name')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @select-item="handleSetCompanyRecord"
    />
    <div class="creditsafe-modal-subtitle mt-5 mb-2">
      <template v-if="!companyId">
        {{ $t('modal.add_company') }}
      </template>
      <template v-else>
        {{ $t('modal.update_company') }}
      </template>
      <BaseSpinner
        v-if="loadingGetCompany"
        type="primary"
        small
        class="ms-1"
      />
    </div>
    <LabelInput
      v-model="record.companies[0].name"
      :label-content="$t('general.company_name')"
      :errors="errorMessages['companies.0.name']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.companies[0].address.address"
      :label-content="$t('address.address_information')"
      :errors="errorMessages['companies.0.address.address']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.companies[0].address.postal_code"
      :label-content="$t('address.postal_code')"
      :errors="errorMessages['companies.0.address.postal_code']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.companies[0].address.city"
      :label-content="$t('address.city')"
      :errors="errorMessages['companies.0.address.city']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <SearchInput
      v-model="record.companies[0].address.country_id"
      field-name="id"
      :entities="getCountries()"
      :errors="errorMessages['companies.0.address.country_id']"
      :label-content="$t('address.country')"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
    />
    <LabelInput
      v-model="record.companies[0].website"
      :label-content="$t('general.website')"
      :errors="errorMessages['companies.0.website']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      wrapper-class="impact-default-group-margin"
      @enter="handleSave"
    />
    <CompanyRegistrationNumberNumberInput
      v-model="record.companies[0].company_registration_number"
      :label-content="$t('general.company_registration_number')"
      :errors="errorMessages['companies.0.company_registration_number']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <VatNumberInput
      v-model="record.companies[0].vat_number"
      :label-content="$t('general.vat_number')"
      :errors="errorMessages['companies.0.vat_number']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      @enter="handleSave"
    />
    <LabelInput
      v-model="record.companies[0].pivot.iban"
      :label-content="$t('intermediary.iban')"
      :errors="errorMessages['companies.0.pivot.iban']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      wrapper-class="impact-default-group-margin"
      @enter="handleSave"
    />
    <PercentageInput
      v-model="record.companies[0].pivot.factoring_provision_percentage"
      :label="$t('intermediary.factoring_provision_percentage')"
      :errors="errorMessages['companies.0.pivot.factoring_provision_percentage']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :decimals="2"
      @enter="handleSave"
    />
    <PercentageInput
      v-model="record.companies[0].pivot.interest_provision_percentage"
      :label="$t('intermediary.interest_provision_percentage')"
      :errors="errorMessages['companies.0.pivot.interest_provision_percentage']"
      :label-columns="labelColumnClasses"
      :input-columns="inputColumnClasses"
      :decimals="2"
      @enter="handleSave"
    />
    <LabelToggleSwitch
      v-model="record.companies[0].pivot.credit_insurance"
      :errors="errorMessages['companies.0.pivot.credit_insurance']"
      :label-content="$t('intermediary.credit_insurance')"
    />
    <BaseRow>
      <BaseColumn class="col-8">
        <FormFooter
          :pending="loadingSaveIntermediary"
          class="d-flex footer-secondary justify-content-end mt-4 pt-2"
          :disabled="_.isEqual(record, originalRecord) || loadingSaveIntermediary"
          @save="handleSave"
          @cancel="handleCancel"
        />
      </BaseColumn>
    </BaseRow>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/BaseModal.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import LabelInput from '@/components/form/LabelInput.vue';
import PersonSalutationConstants from '@/configs/constants/PersonSalutations';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import VatNumberInput from '@/components/form-controls/VatNumberInput.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import PercentageInput from '@/components/form-controls/PercentageInput.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import { useSetToast } from '@/composables/UseToast';
import apiClient from '@/services/ApiClient';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import CreditsafeSearchCompaniesResults from '@/forms/sales/sales-status/creditsafe/CreditsafeSearchCompaniesResults.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { INTERMEDIARY } from '@/configs/constants/CompanyTypes';
import LabelToggleSwitch from '@/components/form/LabelToggleSwitch.vue';
import { BaseColumn, BaseRow, BaseSpinner } from '@impact-factoring/impact-branding';
import { computed, onBeforeMount, ref } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { email, helpers, required, requiredIf } from '@vuelidate/validators';

const emit = defineEmits(['close', 'save']);

const enums = useEnumerationsStore();

const computedCompaniesKeyValue = computed(() => {
  return Object.values(enums.data.companies)
    .filter((filterCompany) => filterCompany.type === INTERMEDIARY)
    .map((company) => {
      let companyObject = {
        id: company.id,
        name: company.name,
      };

      const duplicateName = Object.values(enums.data.companies).filter(
        (filteredCompany) => filteredCompany.name === company.name
      );

      if (duplicateName.length > 1) {
        companyObject = {
          ...companyObject,
          uniqueIdentifier: company.company_registration_number,
        };
      }
      return companyObject;
    });
});

const { t } = useI18n();
const record = ref(getRecordObject());
const creditsafeRecord = ref({
  countries: [],
  regNo: null,
  vatNo: null,
  creditsafe_company: {},
});
const creditsafeFetchedCompanies = ref([]);
const creditsafeErrorMessages = ref([]);
const loadingGetCreditsafeCompany = ref(false);
const companyId = ref(null);
const loadingCompanies = ref(false);
const loadingGetCompany = ref(false);
const loadingSaveIntermediary = ref(false);
const countriesKeyValue = ref({});
const showModal = ref(true);
const errorMessages = ref([]);
const originalRecord = ref({});

const labelColumnClasses = 'col-md-3 col-12';
const inputColumnClasses = 'col-md-5 col-12';
let validationRules = getValidationRules();

const personSalutations = Object.entries(PersonSalutationConstants).map((entry) => {
  return { id: entry[0], value: entry[1] };
});

onBeforeMount(async () => {
  originalRecord.value = structuredClone(record.value);
  countriesKeyValue.value = getCountries();
  creditsafeRecord.value.countries = [
    {
      id: 24,
      name: 'Nederland',
    },
  ];
});

function getRecordObject() {
  return {
    person: {
      id: null,
      salutation: null,
      initials: null,
      first_name: null,
      last_name: null,
      contact_detail: {
        id: null,
        phone_primair: null,
        phone_secundair: null,
        email_address: null,
      },
    },
    companies: [
      {
        id: null,
        type: INTERMEDIARY,
        name: null,
        company_registration_number: null,
        vat_number: null,
        website: null,
        address: {
          id: null,
          country_id: null,
          address: null,
          postal_code: null,
          city: null,
        },
        pivot: {
          iban: null,
          credit_insurance: false,
          factoring_provision_percentage: null,
          interest_provision_percentage: null,
        },
      },
    ],
  };
}

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loadingSaveIntermediary.value) {
    return;
  }

  await setErrorMessages();

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loadingSaveIntermediary.value = true;

    const recordCopy = structuredClone(record.value);

    if (_.isEqual(recordCopy.companies[0], getRecordObject().companies[0])) {
      recordCopy['companies'] = [];
    }

    let response = await saveIntermediary(recordCopy);
    useSetToast('success', t('toast.success.company_successfully_created'));
    emit('save', response);

    response.companies.forEach((company) => findAndAddCompanyEnum(company));
    findAndAddIntermediaryEnum(response);
    companyId.value = record.value.companies[0].id;
    record.value = response.data;
    originalRecord.value = structuredClone(record.value);

    closeModal();
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.creating_intermediary') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating intermediary: ', error);
  } finally {
    loadingSaveIntermediary.value = false;
  }
}

function handleCancel() {
  record.value = structuredClone(originalRecord.value);
  errorMessages.value = [];
}

function getValidationRules() {
  return {
    person: {
      last_name: { required },
      contact_detail: {
        email_address: {
          email,
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.phone_primair && !record.value.person.contact_detail.phone_secundair
              );
            })
          ),
        },
        phone_primair: {
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.email_address && !record.value.person.contact_detail.phone_secundair
              );
            })
          ),
        },
        phone_secundair: {
          requiredIf: helpers.withMessage(
            t('vuelidate.error.phone_or_mobile_or_email_is_required'),
            requiredIf(() => {
              return (
                !record.value.person.contact_detail.phone_primair && !record.value.person.contact_detail.email_address
              );
            })
          ),
        },
      },
    },
    companies: [
      {
        name: {
          requiredIf: requiredIf(!_.isEqual(record.value.companies[0], getRecordObject().companies[0])),
        },
      },
    ],
  };
}

async function setErrorMessages() {
  validationRules = useMergeDefaultVuelidateValidationRules(getValidationRules(), record.value);
  errorMessages.value = await useVuelidateValidation(validationRules, record.value);
}

async function handleSetCompanyRecord(companyId) {
  if (!companyId) {
    record.value.companies[0].id = null;
    originalRecord.value.companies[0].id = null;
    return;
  }

  try {
    loadingGetCompany.value = true;
    const response = await getCompany(companyId);

    if (response) {
      record.value.companies[0] = mapCompany(response);
      // originalRecord.value.companies[0] = structuredClone(record.value.companies[0]);
    }
  } catch (error) {
    useSetToast('error', t('toast.error.retrieving_company') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while fetching company: ', error);
  } finally {
    loadingGetCompany.value = false;
  }
}

async function getCompany(companyId) {
  return await apiClient.request('get', `/ifapi/company/${companyId}`);
}

function saveIntermediary(data) {
  return apiClient.request('post', `/ifapi/intermediaries`, null, data);
}

function findAndAddCompanyEnum(company) {
  const companyEnum = {
    id: company.id,
    type: company.type,
    name: company.name,
    company_registration_number: company.company_registration_number,
  };

  enums.addOrUpdateEnumObject('companies', companyEnum);
}

function findAndAddIntermediaryEnum(response) {
  const intermediaryEnum = {
    id: response.id,
    salutation: response.person.salutation,
    initials: response.person.initials,
    first_name: response.person.first_name,
    last_name: response.person.last_name,
    phone_primair: response.person.contact_detail.phone_primair,
    phone_secundair: response.person.contact_detail.phone_secundair,
    email_address: response.person.contact_detail.email_address,
    companies: {
      id: response.companies?.[0]?.id,
      name: response.companies?.[0]?.name_plus_legal_form,
    },
  };

  enums.addOrUpdateEnumObject('intermediaries', intermediaryEnum);
}

function mapCompany(response) {
  return {
    id: response.id || null,
    type: response.type,
    name: response.name,
    company_registration_number: response.company_registration_number,
    vat_number: response.vat_number,
    website: response.website,
    address: {
      id: response.address.id,
      country_id: response.address.country_id,
      address: response.address.address,
      postal_code: response.address.postal_code,
      city: response.address.city,
    },
    pivot: record.value.companies[0]?.pivot,
  };
}

function closeModal() {
  showModal.value = false;
  emit('close', showModal.value);
}

function mapCountries() {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
}

function getCountries() {
  if (!enums.data.countries) {
    return [];
  }
  let countries = mapCountries();
  countries = {
    PLC: t('general.all_countries'),
    ...countries,
  };
  return countries;
}

async function handleFetchCreditsafeCompanies() {
  if (!validation()) {
    return;
  }

  try {
    loadingCompanies.value = true;

    const urlParameters = generateUrlParameters();
    const creditSafeCompanies = await getFoundCreditsafeCompanies(urlParameters);

    if (!creditSafeCompanies[0]) {
      loadingCompanies.value = false;
      return;
    }

    if (creditSafeCompanies.length > 1) {
      creditsafeFetchedCompanies.value = creditSafeCompanies;
      loadingCompanies.value = false;
      return;
    }

    const company = mapSalesStatusObject(creditSafeCompanies[0]);
    record.value.companies[0] = _.merge(record.value.companies[0], company);
  } catch (error) {
    creditsafeErrorMessages.value = { ...creditsafeErrorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_companies') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe companies: ', error);
  } finally {
    loadingCompanies.value = false;
  }
}

async function handleFetchCreditsafeCompany(creditsafeCompany) {
  if (!validation()) {
    return;
  }

  try {
    loadingGetCreditsafeCompany.value = true;

    let company = await getCreditsafeCompany(creditsafeCompany);
    company = mapSalesStatusObject(company);

    record.value.companies[0] = _.merge(record.value.companies[0], company);
  } catch (error) {
    creditsafeErrorMessages.value = { ...creditsafeErrorMessages.value, ...error?.response?.data?.errors };
    useSetToast(
      'error',
      t('toast.error.retrieving_creditsafe_company') + ':' + '<br>' + error?.response?.data?.message
    );
    console.error('Error while fetching creditsafe company: ', error);
  } finally {
    loadingGetCreditsafeCompany.value = false;
  }
}

function getFoundCreditsafeCompanies(urlParameters) {
  return apiClient.request('get', '/ifapi/integrations/creditsafe/companies', {
    requestSource: urlParameters,
  });
}

function getCreditsafeCompany(company) {
  return apiClient.request('get', `/ifapi/integrations/creditsafe/companies/${company.id}`, {
    requestSource: getUrlParameters(company),
  });
}

function getUrlParameters(company) {
  const language = company.country === 'NL' ? 'NL' : 'EN';
  return `?language=${language}`;
}

function validation() {
  if (!creditsafeRecord.value.countries.length) {
    creditsafeErrorMessages.value['record.countries'] = [t('validation.value_is_required')];
    return false;
  } else {
    delete creditsafeErrorMessages.value['record.countries'];
  }

  if (!(creditsafeRecord.value.regNo || creditsafeRecord.value.vatNo)) {
    creditsafeErrorMessages.value['record.all'] = [t('validation.kvk_or_btw_is_required')];
    return false;
  } else {
    delete creditsafeErrorMessages.value['record.all'];
  }
  return true;
}

function generateUrlParameters() {
  const parameters = filterFilledFields();
  return createUrlParameters(parameters);
}

function filterFilledFields() {
  const filteredParameters = Object.entries(record.value).filter((field) => {
    if (!field[1]?.length) {
      return false;
    }
    return true;
  });
  return filteredParameters;
}

function createUrlParameters(parameters) {
  let urlParameters = '';
  parameters.forEach((parameter, index) => {
    if (index !== 0) {
      urlParameters += '&';
    }
    if (parameter[0] === 'countries') {
      urlParameters += createCountriesUrl(parameter);
      return;
    }
    if (parameter[0] === 'vatNo' && parameter[1].startsWith('NL')) {
      urlParameters += parameter[0] + '=' + stripNLPrefixFromVatNo(parameter[1]);
      return;
    }

    urlParameters += parameter[0] + '=' + parameter[1];
  });
  return '?' + urlParameters;
}

function createCountriesUrl(countries) {
  let enumCountry;
  let parameterCountryUrl = countries[0] + '=';

  countries[1].forEach((country, index) => {
    enumCountry = enums.data.countries.find((enumCountry) => {
      return enumCountry.id === country.id;
    });

    if (country.id === 'PLC') {
      enumCountry = { iso: country.id };
    }

    parameterCountryUrl += enumCountry.iso;

    if (index !== countries[1].length - 1) {
      parameterCountryUrl += ',';
    }
  });
  return parameterCountryUrl;
}

function stripNLPrefixFromVatNo(string) {
  return string.replace('NL', '');
}

function getCountry(creditsafeCompany) {
  return enums.data.countries.find((country) => country.iso === creditsafeCompany.country);
}

function mapSalesStatusObject(creditsafeCompany) {
  let address = creditsafeCompany?.street;

  if (creditsafeCompany?.houseNumber) {
    address = creditsafeCompany?.street + ' ' + creditsafeCompany?.houseNumber;
  }

  const country = getCountry(creditsafeCompany);

  return {
    name: creditsafeCompany.name,
    registered_office: creditsafeCompany.registeredOffice,
    phone: creditsafeCompany.telephone,
    company_registration_number: creditsafeCompany.regNo,
    vat_number: creditsafeCompany.vatNo,
    website: creditsafeCompany.website,
    address: {
      address: address,
      postal_code: creditsafeCompany.postalCode,
      city: creditsafeCompany.city,
      country_id: country?.id ? parseInt(country.id) : null,
    },
  };
}
</script>
