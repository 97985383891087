<template>
  <LogbookForm
    :initial-record="props.initialRecord"
    @save="updateRecord"
    @before-mount="handleEmitBeforeMount"
  />
  <div
    id="salesLogTable"
    class="table-sticky-head table-logbook"
  >
    <ResponsiveTable
      v-if="record"
      :headers="headers"
      hoverable
      table-fixed
    >
      <template
        v-for="(log, index) in _.orderBy(record, 'id', 'desc')"
        :key="index"
      >
        <tr
          class="cursor-pointer fw-normal"
          @click="handleShowLogDetails(index)"
        >
          <td class="text-truncate log-date">
            {{ toLocaleDateString({ dateStyle: 'medium' }).format(dayjs(log.created_at)) }}
          </td>
          <td class="text-truncate">
            <template v-if="log.customer_status_title.name">
              {{ log.customer_status_title.name }}
            </template>
            <template v-else>
              {{ log.title }}
            </template>
          </td>
          <td class="text-truncate log-what">
            {{ log.action ? t(`sales.progress_and_actions.${log.action.toLowerCase()}`) : '-' }}
          </td>
          <td class="log-who">
            <span class="text text-truncate">
              {{ getEmployeeName(log.employee_id) }}
            </span>
            <span class="icon-arrow">
              <BaseIcon icon="fa-solid fa-angle-down" />
            </span>
          </td>
        </tr>
        <tr
          v-if="selectedLogIndex === index"
          class="sales-status-table-details"
        >
          <td colspan="4">
            <div class="fw-medium">
              <template v-if="log.customer_status_title.name">
                {{ log.customer_status_title.name }}
              </template>
              <template v-else>
                {{ log.title }}
              </template>
            </div>
            <div class="mb-3">
              {{ getEmployeeName(log.employee_id) }} {{ t('ui.time.on') }}
              {{ toLocaleDateString({ dateStyle: 'long', timeStyle: 'short' }).format(dayjs(log.created_at)) }}
            </div>
            <div
              v-if="log.action"
              class="d-flex"
            >
              <div class="fw-medium">{{ t('sales.log.action') }}:</div>
              <div>
                <span class="ps-2">{{ t(`sales.progress_and_actions.${log.action.toLowerCase()}`) || '-' }}</span>
              </div>
            </div>
            <div class="fw-medium">{{ t('sales.progress_and_actions.message') }}:</div>
            <div
              v-if="log.message"
              class="white-space-pre-wrap"
            >
              {{ log.message }}
            </div>
            <div
              v-else-if="log.title"
              class="white-space-pre-wrap"
            >
              {{ log.title }}
            </div>
            <div
              v-if="anyTaskFieldIsSet(log)"
              class="mt-4"
            >
              <div class="d-flex">
                <div class="w-25 fw-medium">{{ t('sales.progress_and_actions.execute_alert_on') }}:</div>
                <div v-if="log.task?.execution_date">
                  {{
                    toLocaleDateString({ dateStyle: 'long', timeStyle: 'short' }).format(dayjs(log.task.execution_date))
                  }}
                </div>
              </div>
              <div class="d-flex">
                <div class="w-25 fw-medium">{{ t('sales.progress_and_actions.execute_by') }}:</div>
                <div>{{ getEmployeeName(log.task.employee_id) || '-' }}</div>
              </div>
              <div
                :class="{ 'd-flex': !log.task.description }"
                class="mb-1 mt-2"
              >
                <div
                  :class="{ 'w-25': !log.task.description }"
                  class="fw-medium"
                >
                  {{ t('sales.progress_and_actions.message') }}:
                </div>
                <div class="white-space-pre-wrap">{{ log.task.description || '-' }}</div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </ResponsiveTable>
  </div>
</template>

<script setup>
import { toLocaleDateString } from '@/modules/formatters';
import { useEnumerationsStore } from '@/stores/enumerations';
import LogbookForm from '@/forms/sales/sales-status/LogbookForm.vue';
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import { useSetLogbookItems } from '@/composables/UseProspectViewData';
import { BaseIcon } from '@impact-factoring/impact-branding';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import dayjs from 'dayjs';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['beforeMount']);

const { t } = useI18n();
const enumerationsStore = useEnumerationsStore();

const headers = [t('sales.log.date'), t('sales.list.progress'), t('sales.log.action'), t('sales.log.who')];
const selectedLogIndex = ref(null);

const record = computed(() => {
  return props.initialRecord.customer_log;
});

function handleShowLogDetails(index) {
  if (selectedLogIndex.value !== index) {
    selectedLogIndex.value = index;
    return;
  }
  selectedLogIndex.value = null;
}

function getEmployeeName(employeeId) {
  if (!employeeId) {
    return;
  }
  return Object.entries(enumerationsStore.data.employees).find(
    (employee) => parseInt(employee[0]) === parseInt(employeeId)
  )[1];
}

function updateRecord(object) {
  useSetLogbookItems(object.response);
  selectedLogIndex.value = null;
  handleShowLogDetails(0);
}

function anyTaskFieldIsSet(log) {
  return log.task.employee_id || log.task.description || log.task.execution_date;
}

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
}
</script>
