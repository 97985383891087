<template>
  <BaseModal
    v-if="props.show"
    :show="props.show"
    :modal-title="$t('modal.send_offer_email')"
    custom-footer
    size="md"
    @close="toggleModal"
  >
    <div class="fw-normal">
      {{ $t('offer.preview.send_to') }}
    </div>
    <MultiselectSearchInput
      v-model="record.to"
      :entities="computedContactPersons"
      :array-of-key-value-objects="true"
      :initial-placeholder="$t('ui.placeholders.search_contact_person')"
      :errors="errorMessages['to']"
    />
    <div class="fw-normal mt-2">
      {{ $t('offer.preview.cc') }}
    </div>
    <MultiselectSearchInput
      v-model="record.cc"
      :entities="computedContactPersons"
      :array-of-key-value-objects="true"
      :initial-placeholder="$t('ui.placeholders.search_contact_person')"
    />
    <div class="fw-normal mt-2">
      {{ $t('offer.preview.bcc') }}
    </div>
    <MultiselectSearchInput
      v-model="record.bcc"
      :entities="computedContactPersons"
      :array-of-key-value-objects="true"
      :initial-placeholder="$t('ui.placeholders.search_contact_person')"
    />
    <div class="fw-normal mt-2">
      {{ $t('general.message') }}
    </div>
    <BaseTextarea
      v-model="record.message"
      rows="5"
    />
    <div class="fw-normal mt-2">
      {{ $t('sales.credit_proposal.attachment') }}
    </div>
    <FileTag
      :file-name="computedFileName"
      @click="handleOpenFileInNewWindow"
    />
    <div class="d-inline-flex align-items-center mt-3">
      <BaseCheckbox
        v-model="record.bcc_current_employee"
        class="mt-0"
        @click="record.bcc_current_employee = !record.bcc_current_employee"
      />
      <div
        class="fw-normal ms-2"
        style="margin-top: 1px"
      >
        {{ $t('offer.preview.send_mail_to_self_as_bcc') }}
      </div>
    </div>
    <template #modalFooter>
      <SecondaryButton
        :disabled="_.isEqual(record, originalRecord) || loading"
        @click.prevent="handleCancel"
      >
        {{ t('ui.cancel') }}
      </SecondaryButton>
      <PrimaryButton
        :loading="loading"
        :disabled="_.isEqual(record, originalRecord) || loading"
        class="me-0"
        @click.prevent="handleSendEmail"
      >
        {{ $t('ui.send') }}
      </PrimaryButton>
    </template>
  </BaseModal>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import BaseModal from '@/components/BaseModal.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import FileTag from '@/components/form-controls/FileTag.vue';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { useOpenBlobInNewWindow } from '@/composables/UseDownload';
import { useAuthorizationStore } from '@/stores/authorization';
import { useGetCompany } from '@/composables/UseProspectViewData';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { required } from '@vuelidate/validators';

const props = defineProps({
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    default: true,
    required: true,
  },
});

const emit = defineEmits(['save', 'close']);

const router = useRouter();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();

const originalRecord = ref({});
const companyRecord = ref(null);
const validationRules = ref({ to: { required } });
const errorMessages = ref([]);
const loading = ref(false);
const record = ref({
  to: [],
  cc: [],
  bcc: [],
  message: null,
  bcc_current_employee: true,
});

const computedContactPersons = computed(() => {
  return props.contactPersons;
});

const computedFileName = computed(() => {
  return 'offerte-' + companyRecord.value.name_plus_legal_form.toLowerCase().replaceAll(' ', '-') + '.pdf';
});

onBeforeMount(async () => {
  companyRecord.value = useGetCompany();

  record.value.message = t('email.offer_message', {
    company: companyRecord.value.name_plus_legal_form,
    employee: authorizationStore.employee.name,
  });

  originalRecord.value = structuredClone(record.value);
});

function toggleModal() {
  if (loading.value) {
    return;
  }
  emit('close');
}

async function handleSendEmail() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  let response;
  try {
    loading.value = true;
    response = await apiClient.request(
      'post',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/document/email`,
      null,
      {
        to: record.value.to,
        cc: record.value.cc,
        bcc: record.value.bcc,
        message: record.value.message,
      }
    );
    useSetToast('success', t('toast.success.offer_email_successfully_send'));
    emit('save', response.sales_status.customer_log);

    record.value = structuredClone(originalRecord.value);
  } catch (error) {
    useSetToast('error', t('toast.error.sending_offer_email') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while sending offer email: ', error);
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = structuredClone(originalRecord.value);
  errorMessages.value = [];
}

function handleOpenFileInNewWindow() {
  useOpenBlobInNewWindow(`/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/pdf`);
}
</script>
