<template>
  <div class="sidebar-wrapper">
    <a
      href="#offcanvasPreview"
      type="button"
      data-bs-toggle="offcanvas"
      class="btn btn-sidebar btn-sidebar-right offer-preview"
    >
      {{ $t('ui.preview') }}
    </a>
    <BaseSidebar id="offcanvasPreview">
      <BaseCard class="preview">
        <template #body>
          <div v-safe-html="props.creditProposalPreviewRecord" />
        </template>
      </BaseCard>
      <PrimaryButton
        class="ms-2 my-3"
        @click="download"
      >
        {{ t('ui.download_pdf') }}
      </PrimaryButton>
    </BaseSidebar>
  </div>
</template>

<script setup>
import BaseSidebar from '@/components/sidebar/BaseSidebar.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { BaseCard } from '@impact-factoring/impact-branding';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  creditProposalPreviewRecord: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['download']);

const { t } = useI18n();

async function download() {
  emit('download');
}
</script>
