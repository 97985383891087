import { useMergeDefaultVuelidateValidationRules } from '@/composables/UseVuelidateValidation';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

export async function validateFields(rules, state) {
  const clonedState = structuredClone(state);
  rules = useMergeDefaultVuelidateValidationRules(rules, clonedState);
  const $v = useVuelidate(rules, clonedState);
  const { withAsync } = helpers;
  let valid = true;
  let inputErrors = {};

  await withAsync($v.value.$validate()).$validator.then((response) => {
    valid = response;
  });

  if (valid) {
    return { valid: true, inputErrors: null };
  }

  for (const validationError of $v.value.$errors) {
    /*
     * First one comes from default Vuelidate validators
     * Second one comes from custom Vuelidate validators
     * Message must be wrapped in an array because there can be multiple errors per input
     */
    const errorMessage = [validationError.$message || validationError.$response.$message];
    inputErrors[validationError.$propertyPath] = errorMessage;
  }

  return { valid: false, inputErrors: inputErrors };
}
