import i18n from '@/lang/i18n-config';
import router from '@/router';
import { useApplicationStore } from '@/stores/application';
import axios from 'axios';

const { t } = i18n.global;

axios.defaults.withCredentials = true;

/**
 * Throw and show errors.
 *
 * @param error
 */
const errorHandler = function (error) {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 422: // Unprocessable Entity
        // Throw form validation errors to show in the form
        throw error.response.data;
      case 403: // Forbidden
      case 500: // Internal Server Error
        if ('__password_request_expired__' === error.response.data.message) {
          throw {
            message: t('ui.password_forgotten.password_request_expired'),
          };
        } else {
          useApplicationStore().warning(error.response.data.message);
        }
        break;
      case 400:
      case 503: // Service Unavailable
        // Show errors on top of the view
        useApplicationStore().warning(error.response.data.message);
        break;
      case 401: // Unauthenticated
        router.push({ name: 'login' });
        break;
      default:
        switch (error.code) {
          case 'ERR_NETWORK':
            useApplicationStore().warning(t('ui.errors.no_connection'));
            break;
        }
        break;
    }
  }
};

const api = {
  baseUrl: process.env.VUE_APP_EMPLOYEESAPI_BASEURL,
  alert: {},

  async request(method, url, params, payload, headers, responseType) {
    try {
      let ifapiUrl;
      if (url.startsWith('/ifapi')) {
        ifapiUrl = url.replace('/ifapi', '');
        url = '/api/ifapi';
      }
      let request = {
        url: this.baseUrl + url,
        method: method,
        params: {},
        headers: headers,
        responseType: responseType,
      };

      if (ifapiUrl) {
        request.params.p = ifapiUrl;
      }
      if (params) {
        request.params = { ...request.params, ...params };
      }
      if (payload) {
        request.data = payload;
      }
      let response = await axios.request(request);

      return response.data;
    } catch (error) {
      throw error;
      // errorHandler(error);
    }
  },

  async get(url) {
    try {
      return await this.request('get', url);
    } catch (error) {
      errorHandler(error);
    }
  },

  async post(url, payload) {
    try {
      return await this.request('post', url, null, payload);
    } catch (error) {
      errorHandler(error);
    }
  },

  async patch(url, payload) {
    try {
      return this.request('patch', url, null, payload);
    } catch (error) {
      errorHandler(error);
    }
  },

  async postWithCsrf(url, payload) {
    try {
      await axios.get(this.baseUrl + '/sanctum/csrf-cookie');
      return await axios.post(this.baseUrl + url, payload);
    } catch (error) {
      errorHandler(error);
    }
  },

  async download(url) {
    try {
      return this.request('GET', url, null, null, null, 'blob');
    } catch (error) {
      errorHandler(error);
    }
  },
};

export default api;
